import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { TextAtom, TextWeights } from "../../atoms";
import classnames from "classnames";

import "./Detail.scss";
const MODE = {
  display: "display",
  edit: "edit",
};
function Detail(props) {
  const { label, value, EditComponent, className, ...rest } = props;
  const [mode, setMode] = useState(MODE.display);
  const classes = classnames(
    "detail__molecule",
    mode === MODE.edit ? "edit-mode" : null,
    className
  );
  const handleOnEditClick = () => {
    setMode(MODE.edit);
  };

  const handleExitEditClick = () => {
    setMode(MODE.display);
  };

  const renderBasedOnMode = () => {
    if (mode === MODE.display) {
      return <TextAtom className="value">{value}</TextAtom>;
    }
    return <EditComponent exitEditMode={handleExitEditClick} />;
  };

  const renderEditIcon = () => {
    if (mode === MODE.display && EditComponent)
      return (
        <div className="icon">
          <FontAwesomeIcon onClick={handleOnEditClick} icon={faEdit} />
        </div>
      );
  };

  return (
    <div className={classes} {...rest}>
      <TextAtom className="name" weight={TextWeights.semiBold}>
        {label}
      </TextAtom>
      {renderBasedOnMode()}
      {renderEditIcon()}
    </div>
  );
}

export default Detail;
