import React from "react";
import MapWithMarker from "./components/map-with-marker/MapWithMarker";
import MapWithSearch from "./components/map-with-search/MapWithSearch";
import "./Map.scss";
export const MapVariants = {
  withMaker: "map-with-marker",
  withSearch: "map-with-search",
};

function Map(props) {
  const { variant, ...rest } = props;
  const renderMap = () => {
    switch (variant) {
      case MapVariants.withSearch:
        return <MapWithSearch {...rest} />;
      default:
      case MapVariants.withMaker:
        return <MapWithMarker {...rest} />;
    }
  };
  return renderMap();
}

export default Map;
