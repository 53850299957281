import React from "react";
import { TextAtom, TextColors, TextWeights } from "../../../../atoms";
import "./SelectedUser.scss";

function SelectedUser(props) {
  const { username, firstName, lastName, email, profilePicture } = props;

  console.log("SelectedUser  :", props)
  return (
    <div className="selected-user__component">
      {profilePicture && (
        <div className="image-area">
          <img src={profilePicture} alt={username} />
        </div>
      )}
      <div className="text-area">
        <TextAtom weight={TextWeights.semiBold}>
          {firstName} {lastName}
        </TextAtom>
        <TextAtom color={TextColors.primaryGrey}>@{username}</TextAtom>
        <TextAtom color={TextColors.primaryGrey}>{email}</TextAtom>
      </div>
    </div>
  );
}

export default SelectedUser;
