import React from "react";
import EditSiteComponent from "./component";
import { useDispatch, useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";
import {
  makeRequest,
  NOTIFICATION_MESSAGES,
  notify,
} from "../../../../../../utils";
import { API_ENDPOINTS, ROUTES } from "../../../../../../utils/constants";
import { getSites } from "../../../../../../redux/sites/actions";
import { useHistory } from "react-router";

function EditSite(props) {
  const { siteId } = props;
  const width = useWindowWidth();
  const dispatch = useDispatch();
  const history = useHistory();

  const sites = useSelector((state) => state.sites);

  const siteData = sites.data?.sites;
  const siteDetail = siteData?.find((site) => site.id === siteId);
  const handleDeleteSite = async () => {
    try {
      const res = await makeRequest.delete(API_ENDPOINTS.deleteSite(siteId));
      if (res) {
        notify.success(NOTIFICATION_MESSAGES.successDeleteSite);
        dispatch(getSites());
        history.push(ROUTES.sites);
      }
    } catch (error) {
      console.error(error);
      notify.error(NOTIFICATION_MESSAGES.internalServerError);
    }
  };
  return (
    <EditSiteComponent
      siteDetail={siteDetail}
      sites={sites}
      width={width}
      handleDeleteSite={handleDeleteSite}
    />
  );
}

export default EditSite;
