import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMembers } from "../../../redux/myGroup/actions";
import { Page } from "../../atoms";
import { Interior } from "../../templates";
import { MyGroupComponent } from "./component";
import { getMsgProfile } from "../../../redux/msgProfile/actions";
import { getMsgConvo } from "../../../redux/msgConvo/actions";
import { Route, Switch, useRouteMatch } from "react-router";
import UserDetails from "../users/components/user-details/container";
import { ROUTES } from "../../../utils/constants";
import { useHistory } from "react-router";

function MyGroup() {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getMembers());
    dispatch(getMsgProfile());
    dispatch(getMsgConvo());
  }, [dispatch]);
  const members = useSelector((state) => state.members);
  const msgProfile = useSelector((state) => state.msgProfile);
  const msgConvo = useSelector((state) => state.msgConvo);

  // Move this inside :)


  const viewMember = (member) => {
    console.log("Member:  ", member)
    history.push(ROUTES.userDetails(member.user.username))
  }


  const renderChildRoutes = (members) => (
    <Switch>
      <Route exact path={`${path}`}>
        <MyGroupComponent members={members} viewMember={viewMember}/>
      </Route>
      <Route path={`${path}/:userId`}>
        <UserDetails />
      </Route>
    </Switch>
  );


  return (
    <Page title="My Group" description="My Group Page">
      <Interior msgProfile={msgProfile} msgConvo={msgConvo} >
        {/* <MyGroupComponent members={members}/> */}
        {renderChildRoutes(members)}
      </Interior>
    </Page>
  );
}

export default MyGroup;
