import React, {useEffect, useState} from "react";
import { useHistory} from "react-router";
import { useDispatch, useSelector} from "react-redux";
import { getNodes } from "../../../redux/nodes/actions";
import { getSites } from "../../../redux/sites/actions";
import { getMsgProfile } from "../../../redux/msgProfile/actions";
import { getMsgConvo } from "../../../redux/msgConvo/actions";
import MapWithCurrentLocationComponent from "./component";

import {
  StateActions,
} from "../../../utils";
import { ADD_DEVICES } from "../../../redux/devices/types";
import { getLocations } from "../../../redux/locations/actions";


function MapWithCurrentLocation() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
       dispatch(getMsgProfile());
       dispatch(getMsgConvo());
       //dispatch(getNodes());
       dispatch(getSites());
       dispatch(getLocations());
  }, [dispatch]);

  const msgProfile = useSelector((state) => state.msgProfile);
  const msgConvo = useSelector((state) => state.msgConvo);
  const devices = useSelector((state) => state.devices);
  // const sites = useSelector((state) => state.sites);

  if(devices.state === "loaded"){
    localStorage.setItem('devices', JSON.stringify(devices));
  }else{
    const actiond = new StateActions(ADD_DEVICES);
    dispatch(actiond.loading);
    dispatch(actiond.loaded(JSON.parse(localStorage.getItem('devices'))));
  }

  // var dev = JSON.parse(localStorage.getItem('devices'))
  // const devr = Array.from(dev);

  // console.log("user locations: ", localStorage.getItem('id'))

  return <MapWithCurrentLocationComponent msgConvo={msgConvo} msgProfile={msgProfile}/>;
}

export default MapWithCurrentLocation;

