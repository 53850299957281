import React from "react";
import { Button, ButtonVariants, Input, InputVariants } from "../../../../../../atoms";
import Joi from "@hapi/joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers";
import {
  makeRequestForm,
  NOTIFICATION_MESSAGES,
  notify,
  STATUS_CODE,
} from "../../../../../../../utils";
//import { API_ENDPOINTS } from "../../../../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getSites } from "../../../../../../../redux/sites/actions";
import { useState } from "react";
import { v3ApiUrl } from "../../../../../../../utils/url";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required(),
});

function SiteEditAvatar(props) {
  const { exitEditMode } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });
  const { siteId } = useParams();

  const updateAvatar = async (avatar) => {
    avatar.preventDefault();
   const data = new FormData
   data.append('image', avatar.target.files[0])
   

const url = `${v3ApiUrl}/site/${siteId}/image`
    try {
      const res = await makeRequestForm.post(url, data);
      if (res) {
        if (res.status === "success") {
          dispatch(getSites());
          notify.success(res.data);
          notify.success(NOTIFICATION_MESSAGES.profilePictureUpdate);
        }
        if (res.status === "error") notify.error(res.error.message);
      }
    } catch (err) {
      console.error(err);
      notify.error(NOTIFICATION_MESSAGES.internalServerError);
    }
  };

  const onSubmit = (data) => {
    updateAvatar(data);
  };

  return (
    <>
      <form>
        <input
          type="file"
          id="myFile"
          label="Profile pic"
          name="profilePic"
          onChange={onSubmit}
          ref={register}
        />
        {/* <Button
          onClick={onSubmit}
          variant={ButtonVariants.success}
          type="submit"
        >
          Upload
        </Button> */}
        <Button
          variant={ButtonVariants.dangerOutlined}
          type="button"
          onClick={exitEditMode}
        >
          Cancel
        </Button>
      </form>
    </>
  );
}

export default SiteEditAvatar;
