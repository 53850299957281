import React, { useState } from "react";
import {
  Page,
  TextAtom,
  TextVariants,
  Link,
  Input,
  InputVariants,
  TextSizes,
  Button,
  ButtonVariants,
} from "../../atoms";
import logo from "../../../assets/images/icon.png";
import { joiResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import Joi from "@hapi/joi";

import "./SignIn.scss";
import { ROUTES } from "../../../utils/constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import metadata from "../../../../package.json";

const schema = Joi.object({
  username: Joi.string().required(),
  password: Joi.string().required(),
});

function SignInComponent(props) {
  const { handleSignIn } = props;

  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });

  const [isPasswordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(isPasswordShown ? false : true);
  };

  const renderTogglePasswordIcon = () => {
    return (
      <FontAwesomeIcon
        onClick={togglePassword}
        icon={isPasswordShown ? faEye : faEyeSlash}
      />
    );
  };

  return (
    <Page title="Sign In" description="Sign in to Inlo">
      <div className="sign-in__page">
        <TextAtom className="app-version_left-area">
          V{metadata.version}
        </TextAtom>
        <div className="left-area">
          <div className="sign-up-option">
            <TextAtom variant={TextVariants.regular}>
              Create an account.<Link to={ROUTES.signUp}> Register. </Link>
            </TextAtom>
          </div>
          <div className="logo">
            <img src={logo} alt="Inlo Logo" />
          </div>
          <div className="sign-in-form">
            <TextAtom variant={TextVariants.pageHeading} className="title">
              Sign In
            </TextAtom>
            <form onSubmit={handleSubmit(handleSignIn)}>
              <Input
                variant={InputVariants.text}
                label="Username"
                name="username"
                ref={register}
                error={errors.username?.message}
              />
              <Input
                variant={InputVariants.text}
                type={isPasswordShown ? "text" : "password"}
                name="password"
                label="Password"
                error={errors.password?.message}
                ref={register}
              />
              <i>{renderTogglePasswordIcon()}</i>

              <TextAtom
                variant={TextVariants.regular}
                size={TextSizes.s}
                className="forgot-password"
              >
                <Link to={ROUTES.forgotPassword}>Forgot Password?</Link>
              </TextAtom>
              <Button variant={ButtonVariants.primary}>Enter Inlo</Button>
            </form>
          </div>
        </div>
        <div className="right-area">
          <TextAtom variant={TextVariants.pageHeading}>
            {/* Effortless organization and automation for your life. */}
          </TextAtom>
        </div>
      </div>
    </Page>
  );
}

export default SignInComponent;
