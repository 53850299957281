import React from "react";
import { notify } from "../../../utils";
import { API_ENDPOINTS } from "../../../utils/constants";
import { useFetch } from "../../../utils/hooks";
import { Loading } from "../../atoms";
import ResetPasswordComponent from "./component";

function ResetPassword() {
  // const allCookie = document.cookie.split("=");
  // const tokenFromCookie = allCookie[2].split(";")[0];

  const {
    loading: postResetPassword_loading,
    post: postResetPassword,
  } = useFetch();

  const handleOnSubmit = async (data) => {
    postResetPassword(API_ENDPOINTS.editPassword, {
      password: data.password,
    }, {
      credentials: 'include'
    })
      .then((res) => {
        if (res) {
          if (res.status === "success")
            notify.success("Password Reset successfully", {
              toastId: "PasswordResetSuccess",
            });
        } else if (res.status === "error") {
          notify.error(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };

  if (postResetPassword_loading) {
    return <Loading />;
  }

  return <ResetPasswordComponent handleOnSubmit={handleOnSubmit} />;
}

export default ResetPassword;
