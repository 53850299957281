import React from "react";
import { Page } from "../../atoms";
import { Interior } from "../../templates";
import "./Devices.scss"
import { Route, Switch, useRouteMatch } from "react-router";
import DisplayDevices from "./components/display-devices/container";
import DeviceDetails from "./components/device-details/container";

function DevicesComponent(props) {
  const { msgProfile } = props;
  const { path } = useRouteMatch();

  const renderChildRoutes = () => (
    <Switch>
      <Route exact path={path}>
        <DisplayDevices />
      </Route>
      <Route path={`${path}/:siteId/:nodeId`}>
        <DeviceDetails />
      </Route>
    </Switch>
  );

  return (
    <Page title="Devices" description="Devices">
      <Interior msgProfile={msgProfile}>{renderChildRoutes()}</Interior>
    </Page>
  );
}

export default DevicesComponent;
