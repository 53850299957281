import React from "react";
import { useSelector } from "react-redux";
import DisplayOutdoorComponent from "./component";

function DisplayOutdoor(props) {
  let { updateCheck, getFingerprint, updateSiteID, getSiteID } = props;
  const sites = useSelector((state) => state.sites);
  // const nodes = useSelector((state) => state.nodes);
  const devices = useSelector((state) => state.devices);
  const locations = useSelector((state) => state.locations);
  const msgProfile = useSelector((state) => state.msgProfile);

  // return <DisplayOutdoorComponent getFingerprint={getFingerprint} nodes={nodes} sites={sites} getSiteID={getSiteID} updateSiteID={updateSiteID}/>;
  return <DisplayOutdoorComponent members={msgProfile} updateCheck={updateCheck} nodes={locations} devices={devices} sites={sites} updateSiteID={updateSiteID} getFingerprint={getFingerprint}/>;
}

export default DisplayOutdoor;