import React from "react";
import AddFloorPlanName from "./components/AddFloorPlanName";
import DisplayFloorPlan from "./components/DisplayFloorPlan";
import FloorPlanEditor from "./components/FloorPlanEditor";

function FloorPlanComponent(props) {
  const {
    siteDetail,
    handleCreateFloorplan,
    getAllFloorPlansOfTheSite,
    handleUpdateFloorPlan,
    handleDeleteFloorPlan,
    deleteFloorplan_loading,
    handleEditFloorplanButtonClicked,
    handleCancelEditFloorplanButtonClicked,
    handleAddFloorplanButtonClicked,
    renderEditFloorPlanFlag,
    allFloorPlansData,
    renderDisplayFloorPlanFlag,
    roomsOfSelectedFloor,
    devices,
    flag
  } = props;

  return (
    <>
      {!renderEditFloorPlanFlag && !renderDisplayFloorPlanFlag && (
        <AddFloorPlanName
          handleCreateFloorplan={handleCreateFloorplan}
          getAllFloorPlansOfTheSite={getAllFloorPlansOfTheSite}
          siteDetail={siteDetail}
        />
      )}
      {renderEditFloorPlanFlag && (
        <FloorPlanEditor
          handleUpdateFloorPlan={handleUpdateFloorPlan}
          roomsOfSelectedFloor={roomsOfSelectedFloor}
          devices={devices}
          handleCancelEditFloorplanButtonClicked={
            handleCancelEditFloorplanButtonClicked
          }
        />
      )}
      {renderDisplayFloorPlanFlag && (
        <DisplayFloorPlan
          allFloorPlansData={allFloorPlansData}
          handleDeleteFloorPlan={handleDeleteFloorPlan}
          deleteFloorplan_loading={deleteFloorplan_loading}
          handleEditFloorplanButtonClicked={handleEditFloorplanButtonClicked}
          handleAddFloorplanButtonClicked={handleAddFloorplanButtonClicked}
          siteDetail={siteDetail}
          devices={devices}
          flag={flag}
        />
      )}
    </>
  );
}

export default FloorPlanComponent;
