import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import { userDetails } from "../../../redux/user/actions";
import { REQ_STATE } from "../../../utils";
import { ROUTES } from "../../../utils/constants";

/**
 * The ProtectedRoute route component works as a wrapper on top of the Route method on react.
 * The functions it performs are:
 *  1. Checks if a token exists in the local storage.
 *     - If yes,
 *        a. it lets the user access the protected page.
 *        b. check's if the user details are available and makes an API request if they do not exist.
 *     - If no, it redirects the user to the Sign In page.s
 */

function ProtectedRoute(props) {
  const { children, ...rest } = props;
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  if (!token || token === "undefined") {
    history.push(ROUTES.signIn);
  }

  if (token && user.state === REQ_STATE.initialized) {
    dispatch(userDetails());
  }

  return <Route {...rest}>{children}</Route>;
}

export default ProtectedRoute;
