import { makeRequest, StateActions } from "../../utils";
import { ADD_MSGPROFILE } from "./types";
import { API_ENDPOINTS } from "../../utils/constants";

export const getMsgProfile = () => async (dispatch) => {
  const body = { claimed: true };
  const action = new StateActions(ADD_MSGPROFILE);
  // console.log("getMsgProfile")
  dispatch(action.loading);
  // console.log("actionn:   ", action)
  try {
    const response = await makeRequest.get(API_ENDPOINTS.memberData());
    if (response.success === false) {
      // console.log("erorrorr: ", response)
      dispatch(action.error(response))
    };
    if (response.success === true){ 
      // console.log("response", response)
      dispatch(action.loaded(response))
    };
  } catch (error) {
    console.error(error);
    // console.log("erorrorr: ", error)
    dispatch(action.error(error));
  }
};

