import { v3ApiUrl, v4ApiUrl } from "../url";

var VERSION = "V4"

export const ROUTES = {
  signIn: "/sign-in",
  signUp: "/sign-up",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  notifications: "/notifications",
  map: "/map",
  users: "/users",
  mapWithCurrentLocation: "/map-with-current-location",
  displayOutdoor: "/map-with-current-location/display-outdoor",
  displayIndoor: "/map-with-current-location/display-indoor",
  account: "/account",
  editAccount: "/account/edit",
  settings: "/settings",
  devices: "/devices",
  profile: "/profile",
  feed: "/profile/profile-details",
  userAccess: "/profile/user-management",
  userAccount: "/profile/account",
  preferences: "/preferences",
  sites: "/sites",
  nodes: "/node",
  msgProfile: "/msgProfile",
  createSite: "/sites/create",
  myGroup: "/my-group",
  userDetails(userId) {
    return `${this.users}/${userId}`;
  },
  indoorSite(flag, siteId) {
    return `${this.displayIndoor}/${flag}/${siteId}`;
  },
  siteDetails(siteId) {
    return `${this.sites}/${siteId}`;
  },
  siteSummary(siteId) {
    return `${this.siteDetails(siteId)}`;
  },
  editSite(siteId) {
    return `${this.siteDetails(siteId)}/edit`;
  },
  manageAccess(siteId) {
    return `${this.siteDetails(siteId)}/manage-access`;
  },
  siteDevices(siteId) {
    return `${this.siteDetails(siteId)}/devices`;
  },
  floorPlan(siteId) {
    return `${this.siteDetails(siteId)}/floorplan`;
  },
  editNode(nodeId) {
    return `${this.nodeDetails(nodeId)}/edit`;
  },
  nodeDetails(siteId, nodeId) {
    return `${this.devices}/${siteId}/${nodeId}/edit`;
  },
  deviceSummary(nodeId) {
    return `${this.nodeDetails(nodeId)}/edit`;
  },
};

var API_ENDPOINTS = {
  login: `${v4ApiUrl}/auth/login`,
  signUp: `${v4ApiUrl}/auth/register`,   
  members: `${v4ApiUrl}/member`,
  inviteMember: `${v4ApiUrl}/member/invite`,
  updatebyMemberID(memberId) {
    return `${v4ApiUrl}/member/${memberId}`
  },
  deletebyMemberID(memberId) {
    return `${v4ApiUrl}/member/${memberId}`
  },
  acceptInviteToProfile(groupID){
    return `${v4ApiUrl}/user-group/${groupID}/join`
  },
  deviceCreate() {
    return `${v4ApiUrl}/device`;
  },
  locationCreate() {
    return `${v4ApiUrl}/location`;
  },
  userGroupsData() {
    return `${v4ApiUrl}/user/all`;
  },
  memberData() {
    return `${v4ApiUrl}/member`;
  },
  userGroup(groupID) {
    return `${v4ApiUrl}/user-group/${groupID}`;
  },
  userGroupUser(groupID) {
    return `${v4ApiUrl}/user-group/${groupID}/users`;
  },
  chatCreate(userID) {
    return `${v4ApiUrl}/chat-conversation/${userID}`;
  },
  chatMsgCreate(ConvoID) {
    return `${v4ApiUrl}/chat-message/${ConvoID}`;
  },
  chatGet(convoID) {
    return `${v4ApiUrl}/chat-message/${convoID}`;
  },
  chatActive() {
    return `${v4ApiUrl}/chat-conversation`;
  },
  notifications: `${v4ApiUrl}/notification`,
  notification(notificationID) {
    return `${v4ApiUrl}/notification/${notificationID}`;
  },
  geoFences: `${v4ApiUrl}/geo-fence`,
  sites: `${v4ApiUrl}/site`,
  siteDetails(siteId) {
    return `${this.sites}/${siteId}`;
  },
  userDetails: `${v4ApiUrl}/user`,
  userAll: `${v4ApiUrl}/user/all`,
  user(userId) {
    return`${v4ApiUrl}/user/search?emailOrUsername=${userId}`
  },
  userPicture: `${v4ApiUrl}/asset/profile-picture`,
  editSite(siteId) {
    return `${this.sites}/${siteId}`;
  },
  devices: `${v4ApiUrl}/devices`,
  locations: `${v4ApiUrl}/location`,
  deviceLocations: `${v4ApiUrl}/location/device/`,
  nodes: `${v3ApiUrl}/node`,
  nodeList(nodeId) {
    return `${this.nodes}/${nodeId}`;
  },
  editNode(siteId, nodeId) {
    return `${this.sites}/${siteId}/node/${nodeId}/name`;
  },
  deleteSite(siteId) {
    return `${this.sites}/${siteId}`;
  },
  editEmail: `${v4ApiUrl}/user/email`,
  editName: `${v4ApiUrl}/user`,
  editPassword: `${v4ApiUrl}/user/password`,
  sendPasswordResetLink: `${v4ApiUrl}/auth/forgot-password`,
  uploadProfilePicture: `${v4ApiUrl}/asset/profile-picture`,
  acceptInviteToSite: `${v4ApiUrl}/join-site`,
  accessGroupsData(siteId) {
    return `${v3ApiUrl}/site/${siteId}/access-group`;
  },
  accessGroup(siteId, groupID) {
    return `${v3ApiUrl}/site/${siteId}/access-group/${groupID}`;
  },
  accessGroupUser(siteId, groupID) {
    return `${v3ApiUrl}/site/${siteId}/access-group/${groupID}/user`;
  },
  searchUser(searchInput) {
    return `${v4ApiUrl}/user/search?emailOrUsername=${searchInput}`;
  },
  userInfo(userID) {
    return `${v4ApiUrl}/user-info?userIDs[]=${userID}`;
  },
  siteDevices(siteId) {
    return `${v3ApiUrl}/site/${siteId}/node`;
  },
  siteDevice(siteId, nodeID) {
    return `${v3ApiUrl}/site/${siteId}/node/${nodeID}`;
   },
  siteDeviceName(siteId, nodeID) {
    return `${v3ApiUrl}/site/${siteId}/node/${nodeID}/name`;
  },
  floorplansOfSite(siteId) {
    return `${v3ApiUrl}/site/${siteId}/floorplan`;
  },
  floorplanUpdate(siteId, floorID) {
    return `${v3ApiUrl}/batch/site/${siteId}/floorplan/${floorID}`;
  },
  floorplan(siteId, floorID) {
    return `${v3ApiUrl}/site/${siteId}/floorplan/${floorID}`;
  },
};

export const UTILITY_STRINGS = {
  incorrectLocationData: "Location Data is not plottable",
  incorrectLocationDataAllSites: "No site's location data is plottable.",
};

export { API_ENDPOINTS, VERSION }
