import {useEffect, useRef, useState} from "react";
//import socketIOClient from "socket.io-client";
import { w3cwebsocket as W3CWebSocket } from "websocket";
//import SockJS from "sockjs-client";
//import webstomp from "webstomp-client";

const ENDPOINT = "wss://api-staging.theinlo.com/v3/chat"

//?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiJhZWMzZTg0NTZjY2M0NDFmYjY3OTlhZmVkNGUzMDM4NiIsImZpcnN0TmFtZSI6Ikh1IiwibGFzdE5hbWUiOiJtYW4iLCJlbWFpbCI6ImpvbmxtYXJ0aW4yMDA3KzJAZ21haWwuY29tIiwidG9rZW5JRCI6IjNkZGE1MTE3NDU2MDQzOTNiZmViZTQwNTZmYWZmOGVhIiwiaWF0IjoxNjc1MjA3MzU1LCJleHAiOjE2NzUyOTM3NTV9.bRTEk1z4CQo5r5TVgaiZM5uXcIa9bo3j5Mr3pvCilD8

// transportOptions: {
//     polling: {
//       extraHeaders: {  "Authorization": `Bearer ${localStorage.getItem("token")}` }
//     },
//   }

const options = {
    headers : {
        //access_token: `${localStorage.getItem("token")}`
        //Authorization: `Bearer ${localStorage.getItem("token")}`
        Cookie: `${localStorage.getItem("token")}`
    }
  }

//   const options = {
//     headers : {
//       access_token: `${localStorage.getItem("token")}`
//     }
//   }


// import * as sock from 'websocket'
// const headers = {
//     Authorization: "bearer " + token
// };
// console.log(headers);

// const wsclient = new sock.w3cwebsocket(
//     'wss://' + 'myserver.com' + '/api/ws',
//     '',
//     '',
//     headers,
//     null
// );

const payload = {
    "headers": {
      "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiJhZWMzZTg0NTZjY2M0NDFmYjY3OTlhZmVkNGUzMDM4NiIsImZpcnN0TmFtZSI6Ikh1IiwibGFzdE5hbWUiOiJtYW4iLCJlbWFpbCI6ImpvbmxtYXJ0aW4yMDA3KzJAZ21haWwuY29tIiwidG9rZW5JRCI6IjNkZGE1MTE3NDU2MDQzOTNiZmViZTQwNTZmYWZmOGVhIiwiaWF0IjoxNjc1MjA3MzU1LCJleHAiOjE2NzUyOTM3NTV9.bRTEk1z4CQo5r5TVgaiZM5uXcIa9bo3j5Mr3pvCilD8",
  }}

export const useEventChat = () => {
    const [response, setResponse] = useState("");
    const [isPaused, setPause] = useState(false);
    const ws = useRef(null);



    useEffect(() => {

        //ws.current = new WebSocket(ENDPOINT, `${localStorage.getItem("token")}`);
        //console.log("out :  ",  ws.current = new W3CWebSocket(ENDPOINT,null, [options.headers]) );
        
        //console.log("out :  ",   ws.current = new WebSocket("wss://api-staging.theinlo.com/v3/event?access_token=" + localStorage.getItem("token"), null) );
         ws.current = new WebSocket(ENDPOINT,`${localStorage.getItem("token")}`, payload );
        // { headers: {access_token: `${localStorage.getItem("token")}` }});

       // ws.current = new WebSocket(ENDPOINT,null,options.headers);
        // ws.current = new SockJS(
        //     ENDPOINT, null,
        //     { headers: {Authorization: `Bearer ${localStorage.getItem("token")}` }},
        // );
        ws.current.onopen = () => {
                                    ws.current.send(payload)
                                    console.log("ws opened");
                                }
        ws.current.onclose = () => console.log("ws closed");
        ws.current.onMessage = (data) => console.log(`Recieved Info ${JSON.stringify(data)}`);
        const wsCurrent = ws.current;

        return () => {
            wsCurrent.close();
        };
    }, []);

    useEffect(() => {
        if (!ws.current) return;
    
        ws.current.onmessage = e => {
            if (isPaused) return;
            const message = JSON.parse(e.data);
            console.log("e:    ", message);
        };
    }, [isPaused]);

    
}
    