import React, { useState } from "react";
import { makeRequest, notify } from "../../../utils";
import SignUpComponent from "./component";
import { API_ENDPOINTS } from "../../../utils/constants";
import OnRegisterSuccessMessageBlock from "./OnRegisterSuccessMessageBlock";

function SignUp() {
  const [successState, setSuccessState] = useState(false);
  const handleOnSubmit = async (data) => {
    console.log(data);
    try {
      const res = await makeRequest.post(API_ENDPOINTS.signUp, data);
      //console.log(res);
      if (res.status === "success") {
        setSuccessState(true);
        notify.success(
          "The account has been created successfully. Please verify the email"
        );
      } else notify.error(res.error?.message);

      if (res.success === true) {
        setSuccessState(true);
        notify.success(
          "The account has been created successfully. Please verify the email"
        );
      }else notify.error(res.error?.message);
    } catch (err) {
      notify.error(err.error?.message);
      //console.log(err);
    }
  };

  const renderSignupBlock = () => {
    if (!successState)
      return <SignUpComponent handleOnSubmit={handleOnSubmit} />;
    else if (successState) return <OnRegisterSuccessMessageBlock />;
  };
  return renderSignupBlock();
}

export default SignUp;
