export const REQ_STATE = {
  initialized: "initialized",
  loading: "loading",
  loaded: "loaded",
  error: "error",
};
const defaultData = {
  code: null,
  data: null,
  error: null,
};

export const INITIAL_STORE_STATE = {
  state: REQ_STATE.initialized,
  ...defaultData,
};

/**
 * Initialize the actions an action type.
 */
export class StateActions {
  type;
  constructor(type) {
    this.type = type;
  }

  actionTemplate(state, data = defaultData) {
    return {
      type: this.type,
      payload: {
        state,
        ...data,
      },
    };
  }

  get loading() {
    return this.actionTemplate(REQ_STATE.loading);
  }

  loaded(data) {
    return this.actionTemplate(REQ_STATE.loaded, data);
  }

  error(data) {
    return this.actionTemplate(REQ_STATE.error, data);
  }
}
