import React, { useEffect } from "react";
import ForgotPasswordComponent from "./component";
import { makeRequest, NOTIFICATION_MESSAGES, notify } from "../../../utils";
import { API_ENDPOINTS } from "../../../utils/constants";
import { toast } from "react-toastify";

function ForgotPassword() {
  const sendPasswordResetLink = async (data) => {
    const { email } = data;
    try {
      const res = await makeRequest.post(API_ENDPOINTS.sendPasswordResetLink, {
        email: email,
      });
      if (res) {
        if (res.status === "success") {
          notify.success(NOTIFICATION_MESSAGES.passwordUpdateRequestSent, {
            autoClose: false,
            toastId: "passwordReset",
          });
        }
        if (res.status === "error") notify.error(res.error.message);
      }
    } catch (err) {
      console.error(err);
      notify.error(NOTIFICATION_MESSAGES.internalServerError);
    }
  };

  useEffect(() => {
    return () => {
      toast.dismiss("passwordReset");
    };
  }, []);

  return (
    <ForgotPasswordComponent sendPasswordResetLink={sendPasswordResetLink} />
  );
}

export default ForgotPassword;
