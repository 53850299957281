import { INITIAL_STORE_STATE } from "../../utils";
import { ADD_MEMBERS } from "./types";

export const membersReducer = (state = INITIAL_STORE_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_MEMBERS: {
            return { ...payload };
        }

        default:
            return state;
    }
};
