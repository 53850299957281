import React, { useContext, useEffect, useState } from "react";
import {
  DATETIME_FORMAT,
  composeDateUsingTimestamp,
  notify,
} from "../../../../../utils";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import { useFetch } from "../../../../../utils/hooks";
import { Button, ButtonVariants, Loading } from "../../../../atoms";
import { Detail } from "../../../../molecules";
import Drawer from "../../../../templates/interior/components/Drawer";
import { InteriorContext } from "../../../../templates/interior/components/InteriorContext";

function UserInfo(props) {
  const { user, siteId, groupID, getUserGroup } = props;
  const {
    inviteeEmail,
    owner,
    invitedBy,
    invitationDate,
    invitationStatus,
  } = user;

  const { del, loading, error, response } = useFetch();
  const { get } = useFetch();
  const interiorContext = useContext(InteriorContext);
  const [invitedByuser, setInvitedByuser] = useState({});

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfo = () => {
    get(API_ENDPOINTS.userInfo(invitedBy))
      .then((response) => {
        if (response.status === "success") setInvitedByuser(response.data[0]);
        //console.log(response?.data);
      })
      .catch((error) => console.log(error));
  };

  const handleDeleteUser = () => {
    del(API_ENDPOINTS.userGroupUser( groupID), {
      inviteeEmail: user.inviteeEmail,
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    notify.error(error.error.message);
  }

  if (response) {
    notify.success(response.data);
    getUserGroup(API_ENDPOINTS.userGroupsData());
    interiorContext.closeDrawer();
  }

  return (
    <>
      <Drawer.Title heading={inviteeEmail ? inviteeEmail : "Owner"} />
      <Drawer.Description>
        Information about {inviteeEmail} and their access.
      </Drawer.Description>
      {owner && <Detail label="Owner" value={owner ? "Yes" : "No"} />}
      {invitedBy && (
        <Detail
          label="Invited By"
          value={`${invitedByuser.firstName} ${invitedByuser.lastName} (@${invitedByuser.username})`}
        />
      )}
      {invitationDate && (
        <Detail
          label="Invitation Date"
          value={composeDateUsingTimestamp(
            invitationDate,
            DATETIME_FORMAT.dateAndTime
          )}
        />
      )}
      {invitationStatus && (
        <Detail label="Invitation Status" value={invitationStatus} />
      )}
      {inviteeEmail && <Detail label="Email" value={inviteeEmail} />}
      {!owner && (
        <Detail
          label="Delete"
          value={
            <Button
              variant={ButtonVariants.dangerOutlined}
              onClick={handleDeleteUser}
            >
              Delete
            </Button>
          }
        />
      )}
    </>
  );
}

export default UserInfo;
