import { useWindowWidth } from "@react-hook/window-size";
import { REQ_STATE } from "../../../../../utils";
import { ROUTES } from "../../../../../utils/constants";
import { useHistory } from "react-router";
import { SCREEN_SIZE } from "../../../../../utils/layout";
import {
  Error,
  Link,
  Loading,
  Map,
  TextAtom,
  TextVariants,
  MapVariants,
  Input,
  InputVariants,
} from "../../../../atoms";
import {
  faLaptopHouse,
  faBell,
  faUserCircle,
  faSignOutAlt,
  faMapMarkerAlt,
  faUser,
  faTablet,
} from "@fortawesome/free-solid-svg-icons";
import { ObjectSlide, SiteList, MemberSlide } from "../../../../molecules";
import { SaveDeviceModal } from "../../../../organisms";
import "./DisplayOutdoor.scss";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useGeoLocation } from "../../../../../utils/hooks";
import L, { Browser } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEvents } from "../../../../../utils/hooks/useEvents";
import { useEventChat } from "../../../../../utils/hooks/useEventChat";
import UserInfo from "../../../profile/user-management/components/UserInfo";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import {useDebouncer, useFetch } from "../../../../../utils/hooks";
import { notify } from "../../../../../utils";
import { useDispatch } from "react-redux";
import { DropDownUserResults } from "../../../../molecules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import mem  from "../../../../../assets/images/default-profile-icon.png"
import img  from "../../../../../assets/images/marker.png"
import obj  from "../../../../../assets/images/object-icon.jpg"
// import House from "../../../assets/images/default-profile-icon.png";

const zoom = 18

const markerIcon = new L.Icon({
  iconUrl: require("../../../../../assets/images/marker.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

function RenderOutdoorData({ map, nodes }) {
  const nodeLocations = nodes.data;

  const NodeLocationCards = () => {
      if (nodeLocations?.length !== 0){
        return nodeLocations.map((node) => (
          <ObjectSlide key={node._id} node={node} map={map} className="card" />
        ));
      }else return;
  };
  return (
    <>
      {NodeLocationCards()}
    </>
  );
};

function RenderMemberData({ map, mems }) {
  const memLocations = mems.data.results;

  const MemLocationCards = () => {
      if (memLocations?.length !== 0){
        return memLocations.map((mem) => (
          <MemberSlide key={mem._id} member={mem} map={map} className="card" />
        ));
      }else return;
  };
  return (
    <>
      {MemLocationCards()}
    </>
  );
};

const noSites = () => {
  return (
    <TextAtom variant={TextVariants.regular}>
      Looks like you havent added sites yet! Feel free to{" "}
      <Link to={ROUTES.createSite}> add a site</Link>
    </TextAtom>
  );
};

function RenderLocationCards(props) {
  const { map, sites, userid, updateSiteID} = props

  if (sites.data.count === 0 && sites.data.count === 0) return noSites();
  const siteLocations = sites.data.sites;

  const ownerSiteLocationCardsData = siteLocations.filter(
    (site) => site.owner === userid
  );
  const notOwnedSiteLocationCardsData = siteLocations.filter(
    (site) => site.owner !== userid
  );
    
  const ownerSiteLocationCards = () => {
    if (ownerSiteLocationCardsData?.length !== 0)
      return ownerSiteLocationCardsData.map((site) => (
        <SiteList key={site.siteID} site={site} image={site.picture} map={map} updateSiteID={updateSiteID} className="card" />
      ));
    else return;
  };
  const notOwnedSiteLocationCards = () => {
    if (notOwnedSiteLocationCardsData?.length !== 0)
      return notOwnedSiteLocationCardsData.map((site) => (
        <SiteList key={site.siteID} site={site} image={site.picture} map={map} updateSiteID={updateSiteID} className="card" />
      ));
    else return;
  };


  return (
    <>
      {ownerSiteLocationCards()}
      {notOwnedSiteLocationCards()}
    </>
  );
}

function DisplayOutdoorComponent(props) {
  const { updateCheck, devices, getFingerprint, sites, members, nodes, updateSiteID, getSiteID } = props;
  const width = useWindowWidth();
  const { post } = useFetch();
  const userIDLoggedIn = useSelector((state) => state.user?.data?.userID);
  const location = useGeoLocation();
  const [position, setPosition] = useState([85, 85]);
  const [map, setMap] = useState(null);
  const locations = [];
  const [showModal, setShowModal] = useState(false);
  const [showSiteList, setShowSiteList ] = useState(true)
  const [showMemberList, setShowMemberList ] = useState(false)
  const [showDeviceList, setShowDeviceList ] = useState(false)

  const [usernameOrEmail, setUsernameOrEmail] = useState("");
 // const [selectedUser, setSelectedUser] = useState();
  const { get: getMatchingUsers } = useFetch();
  const [matchingUsers, setMatchingUsers] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  // Websocket Stuff!!
  //useEventChat();

  const findMatchingUsernames = (usernameOrEmail) => {
    if (usernameOrEmail !== "") {
      getMatchingUsers(API_ENDPOINTS.searchUser(usernameOrEmail))
        .then((res) => {
          if (res.success === true) setMatchingUsers(res.data);
          if (res.success === false) setMatchingUsers([]);
        })
        .catch((error) => notify.error(error.message));
    }
  };

  useDebouncer(() => findMatchingUsernames(usernameOrEmail), 500, [
    usernameOrEmail,
  ]);

  const handleSelectUser = (user) => {
    setUsernameOrEmail(user.username);
   // setSelectedUser(user);

    viewUser(user);
  };

  const viewUser = (user) => {
    history.push(ROUTES.userDetails(user.username))
  }

  const renderMatchingUsers = () => {
    if (matchingUsers.length === 0) return;

    console.log("render matching user")
    return matchingUsers.map((user) => (
      <DropDownUserResults
        key={user.username}
        user={user}
        profilePicture={user.profilePicture}
        username={user.username}
        name={`${user.firstName} ${user.lastName}`}
        onClick={() => handleSelectUser(user)}
      />
    ));
  };

  const handleOutdoorData = () => {
    // eslint-disable-next-line default-case
    // switch (sites.state) {
    //   case REQ_STATE.initialized:
    //   case REQ_STATE.loading:
    //     return <Loading />;
    //   case REQ_STATE.error:
    //     return <Error />;
    //   case REQ_STATE.loaded:
        return renderData();
    // }
  };

  function getModalState(){
    return showModal;
  }

  const extractSiteData = (results) => {
    results.forEach((location) => {
      const { name, long, lat, id } = location;
      const locationData = {
        name,
        longLat: [lat, long],
      };
      locations.push(locationData);
      // if(getSiteID() === "")
      //   updateSiteID(siteID)
    });
    return locations;
  };

   // instance of websocket connection as a class property
  //  const ws = new WebSocket('ws://localhost:3000/ws')

  //  const componentDidMount = () => {
  //      this.ws.onopen = () => {
  //      // on connecting, do nothing but log it to the console
  //      console.log('connected')
  //      }
  //      this.ws.onmessage = evt => {
  //      // listen to data sent from the websocket server
  //      const message = JSON.parse(evt.data)
  //      this.setState({dataFromServer: message})
  //      console.log(message)
  //      }
  //      this.ws.onclose = () => {
  //      console.log('disconnected')
  //      // automatically try to reconnect on connection loss
  //      }
  //  //}
  // //  render(
  // //      <ChildComponent websocket={this.ws} />
  // //  );
  // }
  
const deviceChecker = () => {
  updateCheck(true)
  if(localStorage.getItem("deviceID") === null){//not device id generated yet
      localStorage.setItem("deviceID", Math.round(Math.random()*1000000000000));
      openModal()
  }else {//already has deviceID generated
    console.log("checking devices", devices[0])
    for(var i = 0; i<Object.keys(devices).length-1;i++){
      if(devices[i].fingerprint === localStorage.getItem("deviceID")){
        localStorage.setItem("id", devices[i].id)
        updateCheck("true")
        return
      }
    }
    openModal()
  }
}

const openModal = () => {
  setShowModal(true);
};

// const handleSaveDevice = () => {
//   console.log("handle saved device")
//   return (
//       <SaveDeviceModal  setShowModal={setShowModal}/>
//   );
// };

const handleDeviceBtn = () => {
  console.log("handleDeviceBtn")
  setShowSiteList(false)
  setShowMemberList(false)
  setShowDeviceList(true)
  // return (
  //     <SaveDeviceModal  setShowModal={setShowModal}/>
  // );
};

const handleSiteBtn = () => {
  console.log("handleSiteBtn")

  setShowSiteList(true)
  setShowMemberList(false)
  setShowDeviceList(false)
  // return (
  //     <SaveDeviceModal  setShowModal={setShowModal}/>
  // );
};

const handleMemberBtn = () => {
  console.log("handleMemberBtn")

  setShowSiteList(false)
  setShowMemberList(true)
  setShowDeviceList(false)
  // return (
  //     <SaveDeviceModal  setShowModal={setShowModal}/>
  // );
};

const handleOnChange = function (e) {
  const { value } = e.target;
  setUsernameOrEmail(value);
};

  const renderData = () => {

    // if (sites.data.count === 0 && sites.data.count === 0) return noSites();
    
    const viewPosition = {
      name: "You",
      longLat: [location.coordinates.lat, location.coordinates.lng],
    };
      locations.push(viewPosition);

    if(location.coordinates.lat === "")
      return null

      // console.log("devices:   ", devices)
      if(localStorage.getItem(localStorage.getItem("userID")) === null && !getModalState()){
    // console.log("testerrrrr:   ", localStorage.getItem(localStorage.getItem("userID")))
    // if( !getModalState()){
      deviceChecker()
    } else if (""+localStorage.getItem(localStorage.getItem("userID") === true)){
      // console.log("update check true  ")
     // console.log("checking devices", devices[0])
      for(var i = 0; i<Object.keys(devices).length-1;i++){
        if(devices[i].fingerprint === localStorage.getItem("deviceID")){
          localStorage.setItem("id", devices[i].id)
          updateCheck("true")
        }
      }
    } else if (localStorage.getItem(localStorage.getItem("userID") === false)){
      // console.log("update check false  ")
      updateCheck("false")
    }

    if(sites.state === "loaded")
      extractSiteData(sites.data.sites)
    else
      return null

      // console.log("nodes:   ", nodes)
    if(nodes.state !== "loaded")
      return null

    return (
      <>
        { showDeviceList
        ? <div className="object-slide-container">
          <div className="object-slide">
            {map ? <RenderOutdoorData 
                    map={map} 
                    nodes = {nodes}/> : null}
          </div>
        </div> : null }
        { showSiteList 
        ? <div className="site-list-container">
          <div className="site-list" >
            {map ? <RenderLocationCards 
                    map={map} 
                    sites = {sites} 
                    userid = {userIDLoggedIn}
                    updateSiteID={updateSiteID}/> : null}
            </div>
        </div> : null }
        { showMemberList 
        ? <div className="site-list-container">
          <div className="site-list" >
            {map ? <RenderMemberData 
                    map={map} 
                    mems = {members} 
                    userid = {userIDLoggedIn}
                    updateSiteID={updateSiteID}/> : null}
            </div>
        </div> : null }

        <div className="list-nav-container">
          <button className="list-nav-button"
            onClick={handleDeviceBtn} >
              <FontAwesomeIcon icon={faTablet} className="list-nav-button-img" />
            {/* <img src={obj} className="list-nav-button-img" alt={"obj"}  /> */}
          </button>
          <button className="list-nav-button"
            onClick={handleSiteBtn} >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="list-nav-button-img" />
            {/* <img src={img} className="list-nav-button-img" alt={"img"} /> */}
          </button>
          <button className="list-nav-button" 
          onClick={handleMemberBtn} >
            <FontAwesomeIcon icon={faUser} className="list-nav-button-img" />
            {/* <img src={mem} className="list-nav-button-img" alt={"mem"} /> */}
          </button>
          {/* <button className="list-nav-button"
          // onClick={}
            img src={"../../../../../assets/images/icon.png"} alt={img} >
          </button> */}
        </div>
        <div className="top_spacer"></div>
        <div className="searchBarandResults_wrapperr">
          <Input
            label="Search"
            value={usernameOrEmail}
            onChange={(e) => handleOnChange(e)}
            variant={InputVariants.text}
          />
          {usernameOrEmail.trim() && (
            <div className="search-results-dropdown">
              {renderMatchingUsers()}
            </div>
          )}
        </div>
        <Map
          zoom={zoom}
          whenCreated={setMap}
          variant={MapVariants.withMaker}
          className="map"
          locations
          locationData={locations}
          nodes={nodes}
          // sites={sites}
          scrollWheelZoom={SCREEN_SIZE.desktop(width)}>
        </Map>
         {showModal ? <SaveDeviceModal setShowModal={setShowModal} updateCheck={updateCheck}/> : null}

      </>
    );
  }; 
  
  useEffect(() => {
    if (location.coordinates === undefined) 
      return null;
    else if (location.coordinates.lat !== "" && location.coordinates.lng !== "" ) 
      setPosition([location.coordinates.lat, location.coordinates.lng]);
    else 
      return null;
  }, [location]);

  return handleOutdoorData();
}

export default DisplayOutdoorComponent;
