import React, { useContext, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { ROUTES } from "../../../../../utils/constants";
import { Button, ButtonVariants } from "../../../../atoms";
import { InteriorPageHeadingWithCTA, InteriorPageHeadingWithMenu } from "../../../../molecules";
import { SubMenu } from "../../../../organisms";
import ManageAccess from "./access-management/container";
import EditSite from "./edit/container";
import SiteDevices from "./site-devices/container";
import UserSummary from "./summary/container";
import FloorPlan from "./floor-plan/container";
import {
  isSiteEditAllowed,
  isFloorPlanViewAllowed,
  isAccessManagementAllowed,
  isDeviceViewAllowed,
} from "./utils";
import { InteriorContext } from "../../../../templates/interior/components/InteriorContext";
// import AddMember from "./user-details/AddMember/AddMember";
import AddMember from "./AddMember/AddMember"
// import AddMember from "../../../my-group/components/AddMember/AddMember";

function UserDetailComponent(props) {
  const { userId, userDetail, memberDetail, members } = props;
  let { user, permissions} = props;
  const { path } = useRouteMatch();
  const history = useHistory();
  const childRoutesMenu = [
    // { name: "Home", path: ROUTES.siteSummary(userId) },
  ];
  const interiorContext = useContext(InteriorContext);

  // const handleAllSitesClicked = () => {
  //   history.push(ROUTES.sites);
  // };


  if(members.state !== "loaded")
    return null

  if(user === undefined)
    user = memberDetail.user

  if(memberDetail !== undefined){
    permissions = memberDetail.permissions
  }else{
    permissions = {
      message: false,
      location: false,
      devices: false,
      sites: false,
    }
  }

  // if(memberDetail !== undefined)
    // user = memberDetail.user

  const updateChildRoutesMenuAccordingToPermissions = () => {
    // if (isSiteEditAllowed(siteDetail?.permissions))
      // childRoutesMenu.push({ name: "Edit", path: ROUTES.editSite(userId) });
    if (isAccessManagementAllowed(userDetail?.permissions))
      childRoutesMenu.push({
        name: "People",
        path: ROUTES.manageAccess(userId),
      });
    if (isFloorPlanViewAllowed(userDetail?.permissions))
      childRoutesMenu.push({
        name: "Floorplan",
        path: ROUTES.floorPlan(userId),
      });
    if (isDeviceViewAllowed(userDetail?.permissions))
      childRoutesMenu.push({
        name: "Devices",
        path: ROUTES.siteDevices(userId),
      });

    return childRoutesMenu;
  };

  const renderChildRoutes = () => (
    <Switch>
      <Route exact path={path}>
        <UserSummary userDetail={user}/>
      </Route>
      {/* <Route path={`${path}/edit`}>
        { <EditSite siteId={siteId} /> }
      </Route> */}
      <Route path={`${path}/:userId`}></Route>
    </Switch>
  );

  const handleInviteMememberClick = () => {
    console.log("handleInviteMememberClick : ", memberDetail)
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(<AddMember user={user} permission={permissions}/>);
  };


  if(memberDetail === undefined){
      return (
        <div>
          <InteriorPageHeadingWithMenu heading="">
              { <SubMenu
              className="container"
              routeDetails={updateChildRoutesMenuAccordingToPermissions()}/> }
              <Button
              variant={ButtonVariants.primaryRight}
              withIcon
              onClick={handleInviteMememberClick}
            >
              Invite Member
            </Button>
            </InteriorPageHeadingWithMenu>
          {renderChildRoutes()}
        </div>
      );
  } else {
    return (
      <div>
        <InteriorPageHeadingWithMenu heading="">
            { <SubMenu
            className="container"
            routeDetails={updateChildRoutesMenuAccordingToPermissions()}/> }
            <Button
            variant={ButtonVariants.primaryRight}
            withIcon
            // onClick={handleInviteMememberClick}
          >
            Already Member
          </Button>
          </InteriorPageHeadingWithMenu>
        {renderChildRoutes()}
      </div>
    );
  }
}

export default UserDetailComponent;
