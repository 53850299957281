import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { userReducer } from "./redux/user/reducer";
import { notificationsReducer } from "./redux/notifications/reducer";
import { geoFencesReducer } from "./redux/geoFences/reducer";
import { sitesReducer } from "./redux/sites/reducer";
import { nodesReducer } from "./redux/nodes/reducer";
import { msgProfileReducer } from "./redux/msgProfile/reducer";
import { msgConvoReducer } from "./redux/msgConvo/reducer";
import { membersReducer } from "./redux/myGroup/reducer";
import { devicesReducer } from "./redux/devices/reducer";
import { locationsReducer } from "./redux/locations/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  notifications: notificationsReducer,
  sites: sitesReducer,
  geoFences: geoFencesReducer,
  nodes: nodesReducer,
  msgProfile: msgProfileReducer,
  msgConvo: msgConvoReducer,
  members: membersReducer,
  devices: devicesReducer,
  locations: locationsReducer
});

const middlewares = [thunk];

export const store = createStore(
  rootReducer,
  process.env.NODE_ENV !== "production"
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares)
);
