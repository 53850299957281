import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextAtom, TextVariants } from "../../atoms";
import ProfileIcon from "../../../assets/images/default-profile-icon.png";
import { faEllipsisH, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import "./ProfileHeaderSection.scss";
import { composeDateUsingTimestamp, DATETIME_FORMAT } from '../../../utils';
function ProfileHeaderSection(props) {

  const { user } = props

   //console.log("Profile header", user.data)
  // console.log("User data", user.data.username)
  /*   const renderLocationAndJoiningInfo = () => {
      return (
        <>
          <div className="location_profile-header-section">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className="icon-locationMarker_profile-header-section"
            />
            <TextAtom>Hyderabad</TextAtom>
          </div>
          <div className="joiningdate_profile-header-section">
            <TextAtom>Joined 20 Nov 2020</TextAtom>
          </div>
        </>
      );
    };
  
    return (
      <div className="profile-header-section_container">
        <div className="pic-info-and-buttons_container_profile-header-section">
          <div className="pic-and-info">{renderPicAndInfoForProfileHeader()}</div>
          <div className="buttons-container_profile-header-section">
            {renderButtonsForProfileHeader()}
          </div>
        </div>
        <div className="location-and-joining-date">
          {renderLocationAndJoiningInfo()}
        </div>
      </div>
    );
  } */
  const renderPicAndInfoForProfileHeader = () => {
    return (
      <>
        <div className="profile-header-section-block"></div>
        <img
          src={user.profilePicture}
          alt="Profile Icon"
          className="profileIcon_profile-header-section"
        />
        <div className="text_profile-header-section-about">
          <TextAtom
            variant={TextVariants.extraLarge}
            className="text_profile-header-section"
          >
            {user.firstName} {user.lastName}
          </TextAtom>
          <TextAtom
            className="text_profile-header-section"
          >
            @{user.username}
          </TextAtom>
          <hr />
          <TextAtom
            variant={TextVariants.small}
            className="text_profile-header-section"
          >
            Joined - {composeDateUsingTimestamp(user.joined,
              DATETIME_FORMAT.dateAndTime
            )}
          </TextAtom>
          {/* <TextAtom
            variant={TextVariants.small}
            className="text_profile-header-section"
          >
            Last seen - {composeDateUsingTimestamp(
              user.data.lastSeen,
              DATETIME_FORMAT.dateAndTime
            )}
          </TextAtom> */}
          {/* <TextAtom
            className="text_profile-header-section"
          >ICON - Location
          </TextAtom>
          <TextAtom
            className="text_profile-header-section"
          >ICON - Business
          </TextAtom> */}
        </div>
        <div className="text_profile-header-section-buttons">
          {/* <div className="button-container">
            <Button className="button_profile-header-section message">
              Message
            </Button>
            <Button className="button_profile-header-section add">
              <FontAwesomeIcon
                icon={faUserPlus}
                className="add-user-icon"
              />
            </Button><Button className="button_profile-header-section invite">
              Invite to site
            </Button>
          </div> */}
          {/* <FontAwesomeIcon
            icon={faEllipsisH}
            className="profile-header-section-edit"
          />       */}
        </div>
      </>
    );
  };



  return (
    <div className="profile-header-section-container">

      <div className="profile-header-background">
      </div>
      <div className="profile-header-user-info">
        {renderPicAndInfoForProfileHeader()}
      </div>
    </div>
  )

}
export default ProfileHeaderSection;
