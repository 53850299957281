import React from "react";
import { Button, ButtonVariants, Link } from "../../atoms";
import "./Sub-menu.scss";
import classnames from "classnames";
import PropTypes from "prop-types";

/**
 * This  can be used as sub menu in interior pages
 */
function SubMenu(props) {
  const { routeDetails, className, ...rest } = props;
  const classes = classnames(className, "sub-menu");

  const renderMenu = () => {
    return routeDetails.map((route) => (
      <Link key={route.name} className="sub-menu-item" to={route.path} exact>
        <Button variant={ButtonVariants.primaryOutlined}> {route.name} </Button>
      </Link>
    ));
  };

  return (
    <div className={classes} {...rest}>
      {renderMenu()}
    </div>
  );
}

export default SubMenu;

SubMenu.propTypes = {
  /**
   * This is an array of objects with two properties:
   * eg.
   * ```
   *  [{name:"name", path:"path"}]
   * ```
   */
  routeDetails: PropTypes.array.isRequired,
};
