import React from "react";
import { InteriorPageHeadingWithCTA } from "../../../../molecules";
import FloorPlan from "../../../sites/components/site-details/floor-plan/container";
import { useParams } from "react-router";

function DisplayIndoorComponent(props) {
  const { sites } = props;
  const { siteId } = useParams();
  const siteData = sites.data?.results;
  const siteDetail = siteData?.find((site) => site.siteID === siteId);

  if(sites.state !== "loaded")
    return null

  return (
    <>
      <InteriorPageHeadingWithCTA heading={siteDetail.name}>
      </InteriorPageHeadingWithCTA>
      <FloorPlan/>
    </>
  );
}

export default DisplayIndoorComponent;