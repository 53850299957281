import React from "react";
import { useSelector } from "react-redux";
import AccountComponent from "./component";
function Account() {
  const user = useSelector((state) => state.user);

  return <AccountComponent user={user} />;
}

export default Account;
