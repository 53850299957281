import React, { useEffect, useState, useCallback } from "react";
import "./MemberSlide.scss";
import House from "../../../assets/images/default-profile-icon.png";
import { TextAtom, TextVariants } from "../../atoms";
import PropTypes from "prop-types";

function MemberSlideRender({ map, rest, name, avatar/*, lat, lon */}) {
  // const [position, setPosition] = useState(map.getCenter())
  // const zoom = 18
  // const centerr = [lat, lon]

  // const redirectToLocationDetails = useCallback(() => {
  //   map.setView(centerr, zoom);
  // }, [map]);

  // const onMove = useCallback(() => {
  //   setPosition(map.getCenter());
  // }, [map]);

  // useEffect(() => {
  //   map.on("move", onMove);
  //   return () => {
  //     map.off("move", onMove);
  //   };
  // }, [map, onMove]);

  return (
    <div
      {...rest}
      className="object-slide__molecule"
      // onClick={redirectToLocationDetails}
    >
      <img
        src={avatar}
        className="object-slide__molecule-house-image"
        alt={House}
      />
      <div className="object-slide__molecule-text">
        <TextAtom
          className="object-slide__molecule-name"
          variant={TextVariants.regular}
        >
          {name}
        </TextAtom>
      </div>
    </div>
  );
}

function MemberSlide(props) {
  const { member, className, map, ...rest } = props;
  // const { name, latitude, longitude, latestLocation } = member.user;

  return (
    <div>
      {map ? (
        <MemberSlideRender
          map={map}
          rest={rest}
          name={member.user.username}
          avatar={member.user.profilePicture}
          // lat={latestLocation.lat}
          // lon={latestLocation.long}
        />
      ) : null}
    </div>
  );
}

export default MemberSlide;

MemberSlide.propTypes = {
  /**
   * The information that will be used to display
   */
  member: PropTypes.object.isRequired,
};