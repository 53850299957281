import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

/**
 * Adds Page metadata
 */
function Page({ children, title, description }) {
  return (
    <>
      <Helmet>
        <title>Inlo - {title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </>
  );
}

export default Page;

Page.propTypes = {
  /**
   * Child components which will be rendered on the page.
   */
  children: PropTypes.node.isRequired,
  /**
   * Page title which will be display on the tab
   */
  title: PropTypes.string.isRequired,
  /**
   * Description which will be added to the page metadata
   */
  description: PropTypes.string.isRequired,
};
