import { useEffect } from "react";
import {
  makeRequest,
  NOTIFICATION_MESSAGES,
  notify,
  StateActions,
} from "../../utils";
import { API_ENDPOINTS, VERSION } from "../../utils/constants";
import { ADD_USER, GET_USERS, GET_USER } from "./types";
import { ADD_DEVICES } from "../devices/types";

// useEffect{


// },{};

var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

export const signIn = (email, password, alexaParams, callback) => async (
  dispatch
) => {
  const { client_id, redirect_uri, response_type, scope, state } = alexaParams;
  const body = () => {
    if (alexaParams.client_id === null)
      if (VERSION === "V3")
        return { emailOrUsername: email, password, grantType: "password" };
      else if (VERSION === "V4") {
        if (email.indexOf("@") === -1) {
          //console.log("not an email")
          return { emailOrUsername: email, password };
        }
        //console.log("an email: ",  email.indexOf("@") )
        return { emailOrUsername: email, password };
      } else
        return null
    else
      return {
        emailOrUsername: email,
        password,
        grantType: "alexa",
        client_id,
        redirect_uri,
        response_type,
        scope,
        state,
      };
  };
  const action = new StateActions(ADD_USER);
  const actiond = new StateActions(ADD_DEVICES);
  dispatch(actiond.loading);
  dispatch(action.loading);


  try {
    //console.log("try: ", API_ENDPOINTS.login, "  ",body())
    const response = await makeRequest.post(API_ENDPOINTS.login, body());

    if (VERSION === "V3") {
      //console.log("here: ", response)
      if (response.status === "error") {
        // console.log("error: ", response)
        dispatch(action.error(response));
        notify.error(NOTIFICATION_MESSAGES.failedSignIn);
      }

      if (response.status === "success") {
        //console.log("success: ", response)
        dispatch(action.loaded(response));
        // Persist response in local storage
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        if (callback) callback(response.data);
      }
    }
    if (VERSION === "V4") {
      //console.log("here: ", response)
      if (response.success === false) {
        // console.log("error: ", response)
        dispatch(action.error(response));
        notify.error(NOTIFICATION_MESSAGES.failedSignIn);
      }

      if (response.success === true) {
        //console.log("***********success: ", response)
        dispatch(action.loaded(response.data.user));
        dispatch(actiond.loaded(response.data.devices));
        // Persist response in local storage
        localStorage.setItem("userID", response.data.user.id);
        localStorage.setItem("token", response.data.tokens.access.token);
        localStorage.setItem("refreshToken", response.data.tokens.refresh.token);
        if (callback) callback(response.data);
      }
     }
  } catch (error) {
    console.log("error: ", error)
    console.error(error);
    dispatch(action.error(error));
  }
};

export const userDetails = () => async (dispatch) => {
  const action = new StateActions(ADD_USER);

  dispatch(action.loading);
  try {
    const response = await makeRequest.get(API_ENDPOINTS.userDetails);
    if (response.status === "error") dispatch(action.error(response));
    if (response.status === "success") dispatch(action.loaded(response));
    if (response.success === false) dispatch(action.error(response));
    if (response.success === true) dispatch(action.loaded(response.data));
  } catch (error) {
    console.error(error);
    dispatch(action.error(error));
  }
};


export const getUser = (userId) => async (dispatch) => {
  const action = new StateActions(GET_USER);
  dispatch(action.loading);
  console.log("getUser userId:  ", userId)
  try {
      const response = await makeRequest.get(API_ENDPOINTS.user(userId));
      console.log("getUser response:  ", response);
      if (response.success === false) dispatch(action.error(response));
      if (response.success === true) dispatch(action.loaded(response));
  } catch (error) {
      console.error(error);
      dispatch(action.error(error));
  }
};


export const getUsers = () => async (dispatch) => {
  const action = new StateActions(GET_USERS);
  dispatch(action.loading);
  try {
      const response = await makeRequest.get(API_ENDPOINTS.userAll);
      console.log("getUsers response:  ", response);
      if (response.success === false) dispatch(action.error(response));
      if (response.success === true) dispatch(action.loaded(response));
  } catch (error) {
      console.error(error);
      dispatch(action.error(error));
  }
};
