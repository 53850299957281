import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import React from "react";
import { UTILITY_STRINGS } from "../../../../../utils/constants";
import icon from "../../../../../assets/images/site-icon.svg";
import nodeIcon from "../../../../../assets/images/object-icon.svg";
import uIcon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";

let DefaultIcon = L.icon({
  iconUrl: icon,
  //shadowUrl: iconShadow,
});

let NodeIcon = L.icon({
  iconUrl: nodeIcon,
  //shadowUrl: iconShadow,
});
let UIcon = L.icon({
  iconUrl: uIcon,
  //shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;
function MapWithMarker(props) {
  let { geoFenceData, locationData, nodes, sites, ...rest } = props;

  // console.log("locationdata:  ", locationData)

  const sanitizedLocationData = () => {
    if (Array.isArray(locationData)) {
      const filteredLocations = locationData.filter(
        (location) =>
          location.longLat[0] !== null || location.longLat[1] !== null
      );
      return filteredLocations.length
        ? filteredLocations
        : [{ name: UTILITY_STRINGS.incorrectLocationData, longLat: [0, 0] }];
    }

    // check for ditry location which is not an array
    if (locationData.longLat[0] === null || locationData.longLat[1] === null) {
      locationData.longLat = [0, 0];
      locationData.name = UTILITY_STRINGS.incorrectLocationData;
    }
    return locationData;
  };

  const sanitizedNodesData = () => {
    if (Array.isArray(nodes.data)) {
      const filteredLocations = nodes.data.filter(
        (node) =>
          node._id !== localStorage.getItem("id") && (node.latestLocation.lat !== null || node.latestLocation.long !== null)
      );
      return filteredLocations.length
        ? filteredLocations
        : [{ name: UTILITY_STRINGS.incorrectLocationData, longLat: [0, 0] }];
    }
    return nodes.data;
  };

  const renderMarkers = () => {
    // When multiple markers are provided.
    if (Array.isArray(locationData)) {
      return sanitizedLocationData().map((data, idx) => {
        if(data.name === "You"){
          return (
            <Marker icon={UIcon} key={`marker-${idx}`} position={data.longLat}>
              <Popup>{data.name}</Popup>
            </Marker>
          )
        } else {
           return (
              <Marker icon={DefaultIcon} key={`marker-${idx}`} position={data.longLat}>
                <Popup>{data.name}</Popup>
              </Marker>
            );
        }
      });
    }

    // Single Marker
    return (
      <Marker position={sanitizedLocationData().longLat}>
        <Popup>{sanitizedLocationData().name}</Popup>
      </Marker>
    );
  };

  const renderGeoFence = () => {

    console.log("renderGeoFences:  ", geoFenceData)

    if(geoFenceData == undefined)
      return

    // When multiple fences are provided.
    if (Array.isArray(geoFenceData)) {
      return sanitizedLocationData().map((data, idx) => {
        if(data.name === "You"){
          return (
            <Circle icon={UIcon} key={`marker-${idx}`} position={data.longLat}>
              <Popup>{data.name}</Popup>
            </Circle>
          )
        } else {
           return (
              <Circle icon={DefaultIcon} key={`marker-${idx}`} position={data.longLat}>
                <Popup>{data.name}</Popup>
              </Circle>
            );
        }
      });
    }
    console.log("single geoFence")
    // Single fence
    return (
      <Circle center={sanitizedLocationData().longLat} radius={geoFenceData.radius}>
        <Popup>{sanitizedLocationData().name}</Popup>
      </Circle>
    );
  };

  const renderNodeMarkers = () => {
    // When multiple markers are provided.
    if(nodes === undefined || nodes.data.length < 2)
      return null;

    if (Array.isArray(nodes.data)) {
      return sanitizedNodesData().map((data, idx) => {
        return (
          <Marker icon={NodeIcon} key={`marker-${idx}`} position={[data.latestLocation.lat,data.latestLocation.long]}>
            <Popup>{data.device.name}</Popup>
          </Marker>
        );
      });
    }

    // Single Marker
    return (
      <>
      </>
    );
  };

  const composeCenterLocation = () => {
    // When multiple markers are provided.
    if (Array.isArray(locationData)) return sanitizedLocationData()[0].longLat;
    // Single Marker
    return sanitizedLocationData().longLat;
  };

  if (Array.isArray(locationData))
    if(sanitizedLocationData()[0].longLat[0] === "" || sanitizedLocationData()[0].longLat[1] === "") return null;

    // console.log("map rest:  ", rest)
  return (
    <MapContainer center={composeCenterLocation()} minZoom={3} zoom={5} {...rest}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {renderNodeMarkers()}
      {renderMarkers() }
      {renderGeoFence() }
    </MapContainer>
  );
}

export default MapWithMarker;

MapWithMarker.defaultProps = {
  locationData: { name: "White House", longLat: [38.8977, -77.0365] },
};
