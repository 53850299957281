import React from "react";
import { InteriorPageHeadingWithCTA } from "../../../../../../../molecules";
import SiteFloorPlan from "../../../../../../../atoms/site-floor-plan/container";
import { useParams } from "react-router";

function DisplaySiteIndoorComponent(props) {
  const { sites } = props;
  const { siteId } = useParams();
  const siteData = sites.data?.results;
  const siteDetail = siteData?.find((site) => site.siteID === siteId);

  if(sites.state !== "loaded")
    return null

  return (
    <>
      {/* <InteriorPageHeadingWithCTA heading={siteDetail.name}>
      </InteriorPageHeadingWithCTA> */}
      <SiteFloorPlan/>
    </>
  );
}

export default DisplaySiteIndoorComponent;