import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import L from "leaflet";
import { useGeoLocation } from "../../../../../utils/hooks";

const markerIcon = new L.Icon({
  iconUrl: require("../../../../../assets/images/marker.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const Search = (props) => {
  const map = useMap();
  const { provider } = props;
  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider,
      position: "topleft",
      style: "bar",
      notFoundMessage: "nahi mila",
      animateZoom: true,
      showMarker: false,
    });

    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return null;
};
function MyComponent(props) {
  const { handleLocationClick } = props;
  useMapEvents({
    click: (e) => {
      handleLocationClick([e.latlng.lat, e.latlng.lng]);
    },
  });
  return null;
}
function ChangeView({ center, zoom }) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}
function MapWithSearch(props) {
  const { locationData, onLocationClick, ...rest } = props;
  const [markerLocation, setMarkerLocation] = useState([38.8977, -77.0365]);
  const currentlocation = useGeoLocation();

  useEffect(() => {
    if (currentlocation.coordinates === undefined) setMarkerLocation([0, 0]);
    else
      setMarkerLocation([
        currentlocation.coordinates.lat,
        currentlocation.coordinates.lng,
      ]);
  }, [currentlocation]);

 // console.log(markerLocation);
  const handleLocationClick = (location) => {
    setMarkerLocation(location);
  };

  const composeCenterLocation = () => {
    return locationData.longLat;
  };
  onLocationClick(markerLocation);
  return (
    <MapContainer center={composeCenterLocation()} zoom={5} {...rest}>
      <MyComponent handleLocationClick={handleLocationClick} />
      <ChangeView center={markerLocation} zoom={7} />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={markerLocation} icon={markerIcon}>
        <Popup>{locationData.name}</Popup>
      </Marker>
      <Search provider={new OpenStreetMapProvider()} />
    </MapContainer>
  );
}

export default MapWithSearch;

MapWithSearch.defaultProps = {
  locationData: { name: "White House", longLat: [38.8977, -77.0365] },
};
