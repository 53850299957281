import React, { useState } from "react";
import Drawer from "../../../../templates/interior/components/Drawer";
import { notify } from "../../../../../utils";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import { useFetch } from "../../../../../utils/hooks";
import {
  Button,
  ButtonVariants,
  Input,
  InputVariants,
  TextAtom,
  TextSizes,
} from "../../../../atoms";

const TITLES = {
  devices: "Devices",
  location: "location",
  message: "Message",
  user: "User"
};

const permissions = {
  user:{
    devices: false,
    location: false,
    message: false
  }
};

function AddNewUserGroup(props) {
  const { siteId, getUserGroup } = props;
  const { post, loading } = useFetch();

  const [permissionState, setpermissionState] = useState(permissions);
  const [userGroupName, setuserGroupName] = useState("");

  const handleSaveButtonClicked = () => {
    //console.log("permission State:", permissionState["user"])
    post(API_ENDPOINTS.userGroupsData(), {
      name: userGroupName,
      permissions: permissionState["user"],
    })
      .then((res) => {
        if (res.status === "success") {
          notify.success("User Group created successfully", {
            toastId: "UserGroupCreationSuccess",
          });
          getUserGroup(API_ENDPOINTS.userGroupsData());
        } else if (res.status === "error") {
          notify.error(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };

  // const handlePermissionChange = (e, permissionOf, permissionLevel) => {
  //   console.log("permission of:", permissionOf)
  //   console.log("permission level:", permissionLevel)
  //   console.log("permission State:", permissionState)
  //    setpermissionState({
  //      ...permissionState,
  //      [permissionLevel]: {
  //        ...permissionState[permissionLevel],
  //        [permissionLevel]: e.target.checked,
  //      },
  //    });
  // };

  const handlePermissionChange = (e, permissionOf, permissionLevel) => {
    setpermissionState({
      ...permissionState,
      [permissionOf]: {
        ...permissionState[permissionOf],
        [permissionLevel]: e.target.checked,
      },
    });
  };


     // setpermissionState({
    //   ...permissionState,
    //   [permissionOf]: {
    //     ...permissionState[permissionOf],
    //     [permissionLevel]: e.target.checked,
    //   },
    // });

  const permissionLevels = (permissionOf, permissionOfName) => {
    const keyValue = [];
    //console.log("permission of:", permissionOf)
    for (let permissionLevel in permissionOf) {
     // console.log("permission level:", permissionLevel)
      keyValue.push(
        <div
          key={`${TITLES[permissionLevel]}${permissionOf}`}
          className="permission-levels"
        >
          <Input
            variant={InputVariants.checkBox}
            label={TITLES[permissionLevel]}
            onChange={(e) =>
              handlePermissionChange(e, permissionOfName, permissionLevel)
            }
            checked={permissionState[permissionLevel]}
          />
        </div>
      );
    }
    return keyValue;
  };

  // const permissionLevels = (permissionOf, permissionOfName) => {
  //   const keyValue = [];
  //   console.log("permission of:", permissionOf)
  //   for (let permissionLevel in permissionOf) {
  //     console.log("permission level:", permissionLevel)
  //     keyValue.push(
  //       <div
  //         key={`${TITLES[permissionLevel]}${permissionOf}`}
  //         className="permission-levels"
  //       >
  //         <Input
  //           variant={InputVariants.checkBox}
  //           label={TITLES[permissionLevel]}
  //           onChange={(e) =>
  //             handlePermissionChange(e, permissionOfName, permissionLevel)
  //           }
  //           checked={permissionState[permissionLevel]}
  //         />
  //       </div>
  //     );
  //   }
  //   return keyValue;
  // };

  // const permissionsPrint = () => {
  //   const permissionsCollection = [];
  //  // for (let permissionOf in permissionState) {
  //     permissionsCollection.push(
  //       <div key={"User"} className="permission-of">
  //         <TextAtom size={TextSizes.l} className="permission-of-name">
  //           {TITLES["User"]}
  //         </TextAtom>
  //         <div className="permission-levels-group">
  //           {permissionLevels(permissionState, "user")}
  //         </div>
  //       </div>
  //     );
  //  // }
  //   return permissionsCollection;
  // };

  const permissionsPrint = () => {
    const permissionsCollection = [];
    for (let permissionOf in permissionState) {
      permissionsCollection.push(
        <div key={permissionOf} className="permission-of">
          <TextAtom size={TextSizes.l} className="permission-of-name">
            {TITLES[permissionOf]}
          </TextAtom>
          <div className="permission-levels-group">
            {permissionLevels(permissionState[permissionOf], permissionOf)}
          </div>
        </div>
      );
    }
    return permissionsCollection;
  };



  const handleOnChangeUserGroupName = (e) => {
    setuserGroupName(e.target.value);
  };
  return (
    <>
      <Drawer.Title heading="Create User Group"></Drawer.Title>

      <Input
        className="create-access-group__input-name"
        name="group name"
        variant={InputVariants.text}
        value={userGroupName}
        label={"Group Name"}
        onChange={(e) => handleOnChangeUserGroupName(e)}
      />
      {permissionsPrint()}
      <Button
        variant={
          userGroupName.trim()
            ? ButtonVariants.success
            : ButtonVariants.disabled
        }
        onClick={handleSaveButtonClicked}
        loading={loading}
      >
        Save Changes
      </Button>
    </>
  );
}

export default AddNewUserGroup;
