import React, {useEffect} from "react";
import { useDispatch, useSelector} from "react-redux";
import { getSites } from "../../../redux/sites/actions";
import { getMembers } from "../../../redux/myGroup/actions";
import { getUser } from "../../../redux/user/actions";
import { useParams } from "react-router";

import UsersComponent from "./component";

function Users() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMembers());
    dispatch(getSites());
  }, [dispatch]);

  const members = useSelector((state) => state.members);

  console.log("Users container 1 UsersComponent members:", members)
  return <UsersComponent members={members}/>;
}

export default Users;
