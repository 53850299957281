import React from "react";
import { Page, TextAtom, TextVariants } from "../../atoms/";
import { NotificationsBar } from "../../molecules";
import { Interior } from "../../templates";
import "./Notification.scss";
import {
  Error,
  Link,
  Loading,
  Map,
  MapVariants,
} from "../../atoms";
import { REQ_STATE } from "../../../utils";

function NotificationsComponent(props) {
  const { /*msgProfile,*/ notifications } = props;
  console.log("notifications:  ",notifications);

  if(notifications.state !== "loaded")
      return null

          // if(nodes.state !== "loaded")
    //   return null
    // const handleOutdoorData = () => {
      // eslint-disable-next-line default-case
  switch (notifications.state) {
    case REQ_STATE.initialized:
    case REQ_STATE.loading:
      return <Loading />;
    case REQ_STATE.error:
      return <Error />;
    case REQ_STATE.loaded:
      return (
        <Page title="Notifications" description="Account notifications">
          <Interior /*msgProfile={msgProfile}*/ className="notifications__page">
            <TextAtom
              className="notifications__page-title"
              variant={TextVariants.interiorPageHeading}
            >
              Notifications
            </TextAtom>
            <div className="notification-container">
              {notifications.data?.results.map((notificationData) => {
                return <NotificationsBar notificationData={notificationData} />;
              })}
            </div>
          </Interior>
        </Page>
      );
    }
}

export default NotificationsComponent;
