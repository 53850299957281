import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonVariants,
  Input,
  InputVariants,
  Page,
  TextAtom,
  TextVariants,
} from "../../atoms";
import logo from "../../../assets/icons/logo.svg";
import { useForm } from "react-hook-form";
import Joi from "@hapi/joi";
import "./ResetPassword.scss";
import { joiResolver } from "@hookform/resolvers";
import { ROUTES } from "../../../utils/constants";
import metadata from "../../../../package.json";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * @todo Need to validate confirm password
 */
const schema = Joi.object({
  password: Joi.string().min(3).max(15).required(),
  passwordConfirmation: Joi.string().required(),
});

function ResetPasswordComponent(props) {
  const { handleOnSubmit } = props;
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });

  const [password, setPassword] = useState("");
  const [confirmPasswordFlag, setConfirmPasswordFlag] = useState(false);
  const handlePasswordInput = (e) => {
    setPassword(e.target.value);
  };

  const confirmPassword = (e) => {
    if (password === e.target.value) setConfirmPasswordFlag(true);
    else if (password !== e.target.value) setConfirmPasswordFlag(false);
  };

  const onSubmit = (data) => {
    delete data.passwordConfirmation;
    handleOnSubmit(data);
  };

  const [isPasswordShown, setPasswordShown] = useState({
    password: false,
    passwordConfirmation: false,
  });

  const togglePassword = (data) => {
    if (data === "password") {
      setPasswordShown(
        isPasswordShown.password
          ? { ...isPasswordShown, password: false }
          : { ...isPasswordShown, password: true }
      );
    } else if (data === "passwordConfirmation") {
      setPasswordShown(
        isPasswordShown.passwordConfirmation
          ? { ...isPasswordShown, passwordConfirmation: false }
          : { ...isPasswordShown, passwordConfirmation: true }
      );
    }
  };

  const renderTogglePasswordIcon = (data) => {
    if (data === "password") {
      return (
        <FontAwesomeIcon
          onClick={togglePassword}
          icon={isPasswordShown.password ? faEye : faEyeSlash}
        />
      );
    } else if (data === "passwordConfirmation") {
      return (
        <FontAwesomeIcon
          onClick={togglePassword}
          icon={isPasswordShown.passwordConfirmation ? faEye : faEyeSlash}
        />
      );
    }
  };

  return (
    <Page title="Reset Password" description="Reset Inlo Password">
      <div className="reset-password__page">
        <TextAtom className="app-version_left-area">
          V{metadata.version}
        </TextAtom>
        <div className="left-area">
          <div className="sign-in-option">
            <TextAtom variant={TextVariants.regular}>
              Recalled your old password?.
              <Link to={ROUTES.signIn}> Sign In. </Link>
            </TextAtom>
          </div>
          <div className="logo">
            <img src={logo} alt="Inlo Logo" />
          </div>
          <div className="reset-password-form">
            <TextAtom variant={TextVariants.pageHeading} className="title">
              Create a new password.
            </TextAtom>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                variant={InputVariants.text}
                type={isPasswordShown.password ? "text" : "password"}
                name="password"
                label="Password"
                error={errors.password?.message}
                onChange={(e) => handlePasswordInput(e)}
                ref={register}
              />
              <i onClick={() => togglePassword("password")}>
                {renderTogglePasswordIcon("password")}
              </i>
              <Input
                variant={InputVariants.text}
                type={
                  isPasswordShown.passwordConfirmation ? "text" : "password"
                }
                name="passwordConfirmation"
                label="Confirm Password"
                error={errors.passwordConfirmation?.message}
                ref={register}
                onChange={(e) => confirmPassword(e)}
              />
              <i onClick={() => togglePassword("passwordConfirmation")}>
                {renderTogglePasswordIcon("passwordConfirmation")}
              </i>
              <Button
                variant={
                  password.trim() && confirmPasswordFlag
                    ? ButtonVariants.success
                    : ButtonVariants.disabled
                }
                type="submit"
              >
                Reset Password
              </Button>
            </form>
          </div>
        </div>
        <div className="right-area">
          <TextAtom variant={TextVariants.pageHeading}>
            Effortless organization and automation for your life.
          </TextAtom>
        </div>
      </div>
    </Page>
  );
}

export default ResetPasswordComponent;
