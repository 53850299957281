import React, { useContext,useEffect, useState, useCallback } from "react";
import "./MsgSlide.scss";
import House from "../../../assets/images/default-profile-icon.png";
import Plus from "../../../assets/images/Plus.png";
import { Button, TextAtom, TextVariants } from "../../atoms";
import PropTypes from "prop-types";
import SideMsg from "../../organisms/side-msg/SideMsg"//"./components/SideMsgDesktop";
import { InteriorContext } from "../../templates/interior/components/InteriorContext";
import { MapWithCurrentLocation } from "../../pages/";
import { useHistory } from "react-router";
import { ROUTES, API_ENDPOINTS } from "../../../utils/constants";
import { notify } from "../../../utils";
import { useFetch } from "../../../utils/hooks";

function Msgcreate({  }) {


  return (
    <div
     // {...rest}
      className="msg-slide__molecule"
      //onClick={redirectToLocationDetails}
    >
      <img
        src={House}
        className="msg-slide__molecule-house-image"
        alt={"House"}
      />
      <div className="msg-slide__molecule-text">
        <TextAtom
          className="msg-slide__molecule-name"
          variant={TextVariants.regular}
        >
         {/* {name} */}
        </TextAtom>
      </div>
    </div>
  );
}



function MsgSlide(props) {
   const { msgName, msgProfile, avatar, msg, userID, getUserGroup, ...rest } = props;
   const history = useHistory();
   const interiorContext = useContext(InteriorContext);
   const { post, loading } = useFetch();

function makeChatActive({  }) {
  //const msgProfile = null;
    //console.log("makeChatActive:  ", props)

    post(API_ENDPOINTS.chatCreate(userID), {})
      .then((res) => {
        if (res.success === true) {
         // getUserGroup();
          getUserGroup(API_ENDPOINTS.chatActive());
          history.push(ROUTES.mapWithCurrentLocation);
        //   notify.success("User Group created successfully", {
        //     toastId: "UserGroupCreationSuccess",
        //   });
        //   getUserGroup(API_ENDPOINTS.userGroupsData());
        } else if (res.success === false) {
          notify.error(res.error.message);
         }
      })
      .catch((error) => notify.error(error));

    // interiorContext.openDrawer();
    // interiorContext.setDrawerComponent(
    //   <MapWithCurrentLocation />
    // );
    //return <SideMsg msgProfile={msgProfile} />;
    //chatCreate
    //history.push(ROUTES.mapWithCurrentLocation);
  }

  // return (
  //   <>
  //   <div className="msg-slide-active">
  //   <button  className="msg-slide-active-btn"></button>
  //   <text className="msg-slide-btn-text">
  //        {msgName}
  //       </text>
  //   </div>
  //   </>
  // );

  return (
    <div
     // {...rest}
      className="msg-slide__molecule"
      onClick={makeChatActive}
    >
      <img
        src={avatar}
        className="msg-slide__molecule-house-image"
        alt={"House"}
      />
      <div className="msg-slide-btn-text" id={userID}>
         {/* {msgName} */}
      </div>
    </div>
  );
}


//<div className="msg-slide">
//<div className="msg-slide-active">
//  <button className="msg-slide-active-btn"/>
//</div>
//{/*<div className="msg-slide-border">
//</div>*/
//<MsgSlideRender/>}
//  {/* <button className="msg-slide-btn"/> */}
//  {/*<input className="msg-slide-btn" type="image" src={Plus} />*/}
//  {/* <img
//  src={Plus}
//  className="msg-slide-btn" */}
//</div>


export default MsgSlide;

MsgSlide.propTypes = {
  /**
   * The information that will be used to display
   */
  //node: PropTypes.object.isRequired,
};