import { INITIAL_STORE_STATE } from "../../utils";
import { GET_FENCES } from "./types";

export const geoFencesReducer = (state = INITIAL_STORE_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FENCES: {
      return { ...payload };
    }

    default:
      return state;
  }
};
