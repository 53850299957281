import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { updateDeviceLocation } from "../../../redux/devices/actions";
import { useDispatch } from "react-redux";
import { Page } from "../../atoms";
import { Interior } from "../../templates";
import DisplayOutdoor from "./components/display-outdoor/container";
import DisplayIndoor from "./components/display-indoor/container";
import UserDetails from "../users/components/user-details/container";
import { useGeoLocation } from "../../../utils/hooks";
import { MapNav } from "../../organisms";
import { ROUTES } from "../../../utils/constants";
import "./MapLocation.scss";
import { API_ENDPOINTS } from "../../../utils/constants/index";
import { useFetch } from "../../../utils/hooks";
import { notify } from "../../../utils";

function MapWithCurrentLocationComponent(props) {
  const [state,setState] = useState("");
  const dispatch = useDispatch();
  const [fingerprint,setFingerprint] = useState("666");
  const { path } = useRouteMatch();
  const { devices, msgProfile, msgConvo } = props
  const [check, setCheck] = useState("");
  const { post } = useFetch();
  const childRoutesMenu = [
    { name: "Out", path: ROUTES.mapWithCurrentLocation },
  ]; 
  let timer_delay = 2000000

  function updateCheck(props){
    setCheck(props);
  }

  function getCheck(){
    return check;
  }

  useEffect(() => {
    // console.log("location check changed ", check)
      const interval = setInterval(() => {
        if(getCheck() === "true"){
          // console.log("location check changed ", check)
          updateLocation();
        } else if(getCheck() === false){
          // console.log("clear interval ", check)
          clearInterval(interval);
        }
      }, timer_delay);

      return () => {
        clearInterval(interval);
      };
  }, [check]); 

  let updateChildRoutesMenu = () => {
    childRoutesMenu.push({ name: "In", path: ROUTES.indoorSite(2,getSiteID()) });

    return childRoutesMenu;
  };

  function updateSiteID(props){
    setState(props);
  }

  function getSiteID(){
    return state;
  }

  function getFingerprint(){
    return fingerprint;
  }

  function updateLocation(){
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        if (location){
          const viewPosition = {
            name: "You",
            longLat: [location.coords.latitude, location.coords.longitude],
          };
          handleSaveLocation(viewPosition)
        }
      });
    }
  }

  const handleSaveLocation = (viewPosition) => {
    //  console.log("location:  ", localStorage.getItem("id"))
    post(API_ENDPOINTS.locationCreate(), {
      device:'' + localStorage.getItem("id"),
      lat: viewPosition.longLat[0],
      long: viewPosition.longLat[1]
    })
    .then((res) => {
      if (res.success === true) {
        // notify.success("Location Saved successfully", {
        //   toastId: "LocationSavedSuccess",
        // });
        // console.log("respoince creeate location:  ", res)
        } else if (res.success === false) {
          // notify.error(res);
          console.log("error creeate Location:  ", res.error.message)
        }
      })
    .catch((error) => notify.error(error));
  };

  const renderChildRoutes = (timer_delay, updateSiteID, getSiteID) => (
    <Switch>
      <Route exact path={`${path}`}>
        <DisplayOutdoor updateCheck={updateCheck} getFingerprint={getFingerprint} getSiteID={getSiteID} updateSiteID={updateSiteID}/>
      </Route>
      <Route path={`${path}/display-indoor/:flag/:siteId`}>
        <DisplayIndoor getFingerprint={getFingerprint} getSiteID={getSiteID} updateSiteID={updateSiteID}/>
      </Route>
      <Route path={`${path}/:userId`}>
        <UserDetails />
      </Route>
    </Switch>
  );


  // console.log("msgProfile:  ", msgProfile)

  return (  
    <Page title="Map" description="Map with location marker">
      <Interior msgConvo={msgConvo} msgProfile={msgProfile} className="map__page" > 
        {/* <MapNav
          className="container"
          routeDetails={updateChildRoutesMenu()}
        /> */}
        {renderChildRoutes(timer_delay, updateSiteID, getSiteID)}       
      </Interior >
    </Page>     
  );
}

export default MapWithCurrentLocationComponent;
