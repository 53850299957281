import { makeRequest, StateActions } from "../../utils";
import { ADD_MSGCONVO } from "./types";
import { API_ENDPOINTS } from "../../utils/constants";

export const getMsgConvo = () => async (dispatch) => {
  const body = { claimed: true };
  const action = new StateActions(ADD_MSGCONVO);
  // console.log("getMsgConvo")
  dispatch(action.loading);
  // console.log("actionn:   ", action)
  try {
    const response = await makeRequest.get(API_ENDPOINTS.chatActive());
    if (response.success === false) {
      // console.log("erorrorr")
      dispatch(action.error(response))
    };
    if (response.success === true){ 
      // console.log("response", response)
      dispatch(action.loaded(response))
    };
  } catch (error) {
    // console.error(error);
    console.log("erorrorr")
    dispatch(action.error(error));
  }
};

