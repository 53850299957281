import React from "react";
import { REQ_STATE, SCREEN_SIZE } from "../../../../../../utils";
import {
  Button,
  ButtonVariants,
  Error,
  Loading,
  Map,
  MapVariants,
} from "../../../../../atoms";
import { Detail } from "../../../../../molecules";
// import SiteAddressEditComponent from "./components/SiteAddressEditComponent";
// import SiteCityEditComponent from "./components/SiteCityEditComponent";
// import SiteCountryEditComponent from "./components/SiteCountryEditComponent";
 import DeviceNameEditComponent from "./components/DeviceNameEditComponent";
// import SiteZipCodeEditComponent from "./components/SiteZipCodeEditComponent";
// import SiteLongLatEditComponent from "./components/SiteLongLatEditComponent";
import DeviceEditAvatar from "./components/DeviceEditAvatar";
import "./EditDevice.scss";
// import { composeLocationData } from "../../../utils";
// import { isDeleteSiteAllowed } from "../utils";

function EditDeviceComponent(props) {
  const { siteDetail, sites, deviceDetail, devices, width, handleDeleteSite } = props;

  const handleDeviceData = () => {
    // eslint-disable-next-line default-case
    switch (devices.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return renderDeviceDetails();
    }
  };

  const renderDetails = () => {
    const details = [];
    const {
      name,
      longitude,
      latitude,
      permissions,
      image,
      nodeId,
      siteId
    } = deviceDetail;

    //console.log("device details:  ", deviceDetail)


    details.push({
      label: "name",
      value: `${name}`,
      editComponent: DeviceNameEditComponent,
    });
    // details.push({
    //   label: "Address",
    //   value: address,
    //   editComponent: SiteAddressEditComponent,
    // });
    // details.push({
    //   label: "City",
    //   value: city,
    //   editComponent: SiteCityEditComponent,
    // });
    // details.push({
    //   label: "Country",
    //   value: country,
    //   editComponent: SiteCountryEditComponent,
    // });

    // details.push({
    //   label: "Lat/Long",
    //   value: `${latitude}/ ${longitude}`,
    //   editComponent: SiteLongLatEditComponent,
    // });
    // details.push({
    //   label: "zipcode",
    //   value: zipcode,
    //   editComponent: SiteZipCodeEditComponent,
    // });

    // if (isDeleteSiteAllowed(permissions)) {
    //   details.push({
    //     label: "",
    //     value: (
    //       <Button variant={ButtonVariants.danger} onClick={handleDeleteSite}>
    //         Delete Site
    //       </Button>
    //     ),
    //   });
    // }

    details.push({
      label: "img",
      value: (
        <img
          className="account_page-profile_pic"
          src={image}
          alt="object profile pic"
        />
      ),
      editComponent: DeviceEditAvatar,
       //editComponent: EditProfilePic,
    });

    return details.map((detail) => (
      <Detail
        key={detail.label}
        label={detail.label}
        value={detail.value}
        EditComponent={detail?.editComponent}
      />
    ));
  };

  const renderDeviceDetails = () => {
    return (
      <div className="site_edit-container">
        <div className="site_edit">{renderDetails()}</div>
        {/* <div className="map-container">
          <Map
            variant={MapVariants.withMaker}
            className="map"
            locationData={composeLocationData(siteDetail)}
            scrollWheelZoom={SCREEN_SIZE.desktop(width)}
          />
        </div> */}
      </div>
    );
  };
  return <div>{handleDeviceData()}</div>;
}

export default EditDeviceComponent;
