import React from "react";
import { Button, ButtonVariants, Input, InputVariants } from "../../../../atoms";
import Joi from "@hapi/joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers";
import {
  makeRequestForm,
  NOTIFICATION_MESSAGES,
  notify,
  STATUS_CODE,
} from "../../../../../utils";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../../../../../redux/user/actions";
import { useState } from "react";
import { useFetch } from "../../../../../utils/hooks";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required(),
});

function EditAvatar(props) {
  const { exitEditMode } = props;
  const dispatch = useDispatch();
  const { post, loading } = useFetch();
  const [picture, setPicture] = useState("");
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });

  // const updateAvatar = async (avatar) => {
  //   avatar.preventDefault();
  //   const data = new FormData
  //   data.append('profilePicture', avatar.target.files[0])
  //   console.log("data:   ", avatar.target.files[0]);
  //   post(API_ENDPOINTS.uploadProfilePicture, {
  //     profilePicture: avatar.target.files[0],
  //   })
  //     .then((res) => {
  //       if (res.success === true) {
  //           dispatch(userDetails());
  //           notify.success(res.data);
  //           notify.success(NOTIFICATION_MESSAGES.profilePictureUpdate);
  //         }
  //         if (res.success === false){ notify.error(res);
          
  //         }



  //     })
  //     .catch((error) => notify.error(error));
  // };
  const updateAvatar = async (avatar) => {
    avatar.preventDefault();
   const data = new FormData
   data.append('profilePicture', avatar)
   console.log("data:   ", data.profilePicture);
  //  if(data.profilePicture === undefined)
  //   return
    try {
      const res = await makeRequestForm.post(API_ENDPOINTS.uploadProfilePicture, data);
      if (res) {
        if (res.success === true) {
          console.log("update avatar success: ", res);
          // dispatch(userDetails());
          // notify.success(res.data);
          // notify.success(NOTIFICATION_MESSAGES.profilePictureUpdate);
        }
        if (res.success === false){
          console.log("update avatar fail: ", res);
         //  notify.error(res);
        }
      }
    } catch (err) {
      console.error(err);
      // notify.error(NOTIFICATION_MESSAGES.internalServerError);
    }
  };

  const onSubmit = (data) => {
    updateAvatar(picture);
  };
  const onChange = (data) => {
    setPicture(data);
  };

  return (
    <>
      {/* <form> */}
        <input
          type="file"
          id="myFile"
          label="Profile pic"
          name="profilePic"
          onChange={onChange}
          ref={register}
        />
        <Button
          onClick={onSubmit}
          ref={register}
           variant={ButtonVariants.success}
           type="submit"
        >
          Upload
        </Button>
        <Button
          variant={ButtonVariants.dangerOutlined}
          type="button"
          onClick={exitEditMode}
        >
          Cancel
        </Button>
      {/* </form> */}
    </>
  );
}

export default EditAvatar;
