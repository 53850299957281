import React, { useEffect } from "react";
import "./SideMsg.scss";
import SideMsgDesktop from "./components/SideMsgDesktop";
import { useFetch } from "../../../utils/hooks/useFetch";
import { API_ENDPOINTS } from "../../../utils/constants";
import {
  Error,
  Loading,
} from "../../atoms";

function SideMsg(props) {
  const { msgProfile, msgConvo, ...rest } = props;
  const { loading, error, response, get: getUserChat } = useFetch();

  // const {
  //   loading: deleteUserGroup_loading,
  //   del: deleteUserGroup,
  // } = useFetch();

  useEffect(() => {
    getUserChat(API_ENDPOINTS.chatActive());
  }, []);


  const renderBasedOnScreenWidth = () => {
    return <SideMsgDesktop 
                            msgConvo={response} 
                            loading={loading}
                            error={error} 
                            userGroup={response} 
                            getUserGroup={getUserChat}
                            msgProfile={msgProfile} 
                             />;
  };


  const handleMsgData = () => {
    if (error) return <Error />;
    if (response) return renderBasedOnScreenWidth();
    return <Loading />;
  };

  return renderBasedOnScreenWidth(); //return handleMsgData();
}

export default SideMsg;
