import React from "react";
import { useSelector } from "react-redux";
import DisplaySitesComponent from "./component";

function DisplaySites() {
  const sites = useSelector((state) => state.sites);

  return <DisplaySitesComponent sites={sites} />;
}

export default DisplaySites;
