import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { notify } from "../../../../../../utils";
import { API_ENDPOINTS } from "../../../../../../utils/constants";
import { useFetch } from "../../../../../../utils/hooks";
import { Loading } from "../../../../../atoms";
import FloorPlanComponent from "./component";

function FloorPlan() {
  const { flag, siteId } = useParams();
  const sites = useSelector((state) => state.sites);
  const siteData = sites.data?.results;
  const siteDetail = siteData?.find((site) => site.siteID === siteId);
  let flagger = true;

  if(flag == 2)
    flagger = false;

  useEffect(() => {
    getAllFloorPlansOfTheSite();
    handleGetDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const { loading: editFloorplan_loading, patch } = useFetch();
  const { loading: deleteFloorplan_loading, del: deleteFloorplan } = useFetch();
  const { loading: getAllFloorPlans_loading, get: getAllFloorPlans,} = useFetch();
  const { loading: createFloorplan_loading, post } = useFetch();
  const { get: getDevices, loading: getDevices_loading } = useFetch();
  const [renderEditFloorPlanFlag, setRenderEditFloorPlanFlag] = useState(false);
  const [allFloorPlansData, setAllFloorPlansData] = useState({});
  const [renderDisplayFloorPlanFlag, setRenderDisplayFloorPlanFlag] = useState(
    false
  );
  const [floorID, setFloorID] = useState("");
  const [roomsOfSelectedFloor, setRoomsOfSelectedFloor] = useState([]);
  const [devices, setDevices] = useState();

  const handleCreateFloorplan = async (data) => {
    setRoomsOfSelectedFloor([]);
    post(API_ENDPOINTS.floorplansOfSite(siteId), {
      floorName: data,
    })
      .then((res) => {
        if (res.status === "success") {
          notify.success("Floorplan created successfully", {
            toastId: "FloorplanCreationSuccess",
          });
          setFloorID(res.data.floorID);
          setRenderEditFloorPlanFlag(true);
        } else if (res.status === "error") {
          notify.error(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };
  if (createFloorplan_loading) {
    return <Loading />;
  }

  const getAllFloorPlansOfTheSite = async () => {
    getAllFloorPlans(API_ENDPOINTS.floorplansOfSite(siteId))
      .then((res) => {
        if (res.status === "success") {
          if (res.data.length > 0) {
            setAllFloorPlansData(res.data);
            setRenderEditFloorPlanFlag(false);
            setRenderDisplayFloorPlanFlag(true);
          } else if (res.data.length === 0) {
            setRenderEditFloorPlanFlag(false);
            setRenderDisplayFloorPlanFlag(false);
          }
        } else if (res.status === "error") {
          notify.error(res.error.message);
        }
      })

      .catch((error) => {
        notify.error(error.error.message);
        //console.log(error);
      });
  };
  if (getAllFloorPlans_loading) {
    return <Loading />;
  }

  const handleUpdateFloorPlan = async (rooms) => {
    patch(API_ENDPOINTS.floorplanUpdate(siteId, floorID), {
      rooms: rooms,
    })
      .then((res) => {
        if (res.status === "success") {
          notify.success("Floorplan updated successfully", {
            toastId: "FloorplanUpdationSuccess",
          });
          getAllFloorPlansOfTheSite();
        } else if (res.status === "error") {
          notify.error(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };
  if (editFloorplan_loading) {
    return <Loading />;
  }

  const handleDeleteFloorPlan = async (floorid) => {
    deleteFloorplan(API_ENDPOINTS.floorplan(siteId, floorid))
      .then((res) => {
        if (res.status === "success") {
          notify.success("Floorplan deleted successfully", {
            toastId: "FloorplanDeletionSuccess",
          });
          getAllFloorPlansOfTheSite();
        } else if (res.status === "error") {
          notify.error(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };

  const handleGetDevices = async () => {
    getDevices(API_ENDPOINTS.siteDevices(siteId))
      .then((res) => {
        if (res.status === "success") {
          setDevices(res.data.results);
        } else if (res.status === "error") {
          notify.error(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };
  if (getDevices_loading) {
    return <Loading />;
  }

  const handleEditFloorplanButtonClicked = (
    floor_id,
    roomsOfSelectedFloor_data
  ) => {
    setFloorID(floor_id);
    setRoomsOfSelectedFloor(roomsOfSelectedFloor_data);
    setRenderEditFloorPlanFlag(true);
    setRenderDisplayFloorPlanFlag(false);
  };
  const handleCancelEditFloorplanButtonClicked = () => {
    setRenderDisplayFloorPlanFlag(true);
    setRenderEditFloorPlanFlag(false);
  };
  const handleAddFloorplanButtonClicked = () => {
    setRenderDisplayFloorPlanFlag(false);
    setRenderEditFloorPlanFlag(false);
  };

  return (
    <FloorPlanComponent
      siteDetail={siteDetail}
      handleCreateFloorplan={handleCreateFloorplan}
      getAllFloorPlansOfTheSite={getAllFloorPlansOfTheSite} 
      handleUpdateFloorPlan={handleUpdateFloorPlan} 
      handleDeleteFloorPlan={handleDeleteFloorPlan} 
      deleteFloorplan_loading={deleteFloorplan_loading} 
      handleEditFloorplanButtonClicked={handleEditFloorplanButtonClicked} 
      handleCancelEditFloorplanButtonClicked={handleCancelEditFloorplanButtonClicked} 
      handleAddFloorplanButtonClicked={handleAddFloorplanButtonClicked} 
      renderEditFloorPlanFlag={renderEditFloorPlanFlag} 
      renderDisplayFloorPlanFlag={renderDisplayFloorPlanFlag} 
      allFloorPlansData={allFloorPlansData} 
      roomsOfSelectedFloor={roomsOfSelectedFloor}
      devices={devices}
      flag={flagger} 
      />
  );
}

export default FloorPlan;
