import React, { useEffect } from "react";
import { useParams } from "react-router";
import { notify } from "../../../..//utils";
import { API_ENDPOINTS } from "../../../../utils/constants";
import { useFetch } from "../../../../utils/hooks/useFetch";
import ManageUserComponent from "./component";

function ManageUser() {
 // const { siteId } = useParams();
  const { loading, error, response, get: getUserGroup } = useFetch();
  const {
    loading: deleteUserGroup_loading,
    del: deleteUserGroup,
  } = useFetch();

  useEffect(() => {
    getUserGroup(API_ENDPOINTS.userGroupsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // getUserGroup(API_ENDPOINTS.userGroupsData());
  //   console.log("response:   ", response)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [response]);

  const handleDeleteUserGroup = async (groupId) => {
    try {
      const res = await deleteUserGroup(
        API_ENDPOINTS.userGroup( groupId)
      );

      if (res.status === "success") {
        notify.success("Group deleted successfully", {
          toastId: "groupDeleted",
        });
        getUserGroup(API_ENDPOINTS.userGroupsData());
      }
      if (res.status === "error")
        notify.error(res.error.message, {
          toastId: "groupDeletedFail",
        });
    } catch (error) {
      notify.error(error.error.message, {
        toastId: "groupDeletedFail",
      });
    }
  };

  return (
    <ManageUserComponent
      userGroups={response}
      loading={loading}
      error={error}
      deleteUserGroup_loading={deleteUserGroup_loading}
      handleDeleteUserGroup={handleDeleteUserGroup}
      getUserGroup={getUserGroup}
    />
  );
}

export default ManageUser;
