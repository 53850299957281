import React from "react";
import { SideNav, SideMsg } from "../../organisms";
import classnames from "classnames";
import "./Interior.scss";
import PropTypes from "prop-types";
import { ErrorBoundary } from "../../atoms";
import Drawer from "./components/Drawer";
import InteriorContextProvider from "./components/InteriorContext";
import { useState } from "react";
import { useSelector } from "react-redux";

function Interior(props) {
  const { children, msgProfile, msgConvo, className, ...rest } = props;

  // This prop is passed down to the context provider to identity what state the drawer is in.
  // If you know a better way, please implement.

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const classes = classnames(
    "interior-template__page-content",
    isDrawerOpen ? "hide-main-page-content-area" : "",
    className
  );

  return (
    <InteriorContextProvider setIsDrawerOpen={setIsDrawerOpen}>
      <div className="interior-template__page">
        <div className="interior-template__side-nav">
          <SideNav />
        </div>
        <ErrorBoundary>
          <div className="interior-template__page-main-area">
            <div className={classes} {...rest}>
              {children}
            </div>
            <Drawer />
          </div>
        </ErrorBoundary>
        { <div className="interior-template__side-msg">
          <SideMsg msgConvo={msgConvo} msgProfile={msgProfile}/>
        </div> }
      </div>
    </InteriorContextProvider>
  );
}

export default Interior;

Interior.propTypes = {
  children: PropTypes.node,
};
