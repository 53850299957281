import React from "react";
import "./MsgSlideMsgs.scss";
import House from "../../../assets/images/default-profile-icon.png";


function MsgSlideMsgs(props) {
   const { msgName, msgProfile, msg, userID, part, getUserGroup, ...rest } = props;
 // console.log("part:  ", part, "   sender: ", msg)
  if(part.id === msg.sender){
    return (
      <div className="msg-slide-card-left__window-msgs " >
        <img
          src={part.profilePicture}
          className="msg-slide-msgs__molecule-house-image"
          alt={"House"}
        />
        <div className="texter" >
          {msg?.message}
        </div>
      </div>
    );
  }else{
    return (
      <div className="msg-slide-card-right__window-msgs " >
        <img
          src={part.profilePicture}
          className="msg-slide-msgs__molecule-house-image"
          alt={"House"}
        />
        <div className="texter" >
          {msg?.message}
        </div>
      </div>
    );
  }

}


export default MsgSlideMsgs;

MsgSlideMsgs.propTypes = {
  /**
   * The information that will be used to display
   */
  //node: PropTypes.object.isRequired,
};