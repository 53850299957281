import React, { useEffect } from "react";
import SiteDevicesComponent from "./component";
import { useFetch } from "../../../../../../utils/hooks/useFetch";
import { API_ENDPOINTS } from "../../../../../../utils/constants";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Loading } from "../../../../../atoms";

function SiteDevices() {
  const { siteId } = useParams();
  const sites = useSelector((state) => state.sites);
  const siteData = sites.data?.results;
  const siteDetail = siteData?.find((site) => site.siteID === siteId);

  const { error, response, get, loading } = useFetch();

  useEffect(() => {
    getSiteDevicesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSiteDevicesData = () => {
    get(API_ENDPOINTS.siteDevices(siteId));
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <SiteDevicesComponent
      siteDevicesData={response?.data.results}
      error={error}
      get={get}
      siteDetail={siteDetail}
      loading={loading}
    />
  );
}

export default SiteDevices;
