import Joi from "@hapi/joi";
import { joiResolver } from "@hookform/resolvers";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  makeRequest,
  NOTIFICATION_MESSAGES,
  notify,
} from "../../../../../../../utils";
import { API_ENDPOINTS } from "../../../../../../../utils/constants";
import {
  Button,
  ButtonVariants,
  Input,
  InputVariants,
} from "../../../../../../atoms";
import { getSites } from "../../../../../../../redux/sites/actions";

const schema = Joi.object({
  siteName: Joi.string().allow("").optional(),
});

function SiteNameEditComponent(props) {
  const { exitEditMode } = props;
  const { register, handleSubmit } = useForm({
    resolver: joiResolver(schema),
  });
  const { siteId } = useParams();
  const dispatch = useDispatch();

  const siteDetailsUpdate = async (data) => {
    //console.log(data);
    try {
      const response = await makeRequest.patch(API_ENDPOINTS.editSite(siteId), {
        name: data,
      });
      if (response) {
        dispatch(getSites());
        if (response.status === "success")
          notify.success("Site Name updated successfully");
        if (response.status === "error") notify.error(response.error.message);
      }
    } catch (error) {
      console.error(error);
      notify.error(NOTIFICATION_MESSAGES.internalServerError);
    }
  };

  const onSubmit = (data) => {
    siteDetailsUpdate(data.siteName);
  };

  return (
    <div className="edit-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="siteName"
          variant={InputVariants.text}
          label="Site name"
          ref={register}
        />
        <Button
          className="edit-update-button"
          variant={ButtonVariants.success}
          type="submit"
        >
          Update
        </Button>
        <Button
          variant={ButtonVariants.dangerOutlined}
          type="button"
          onClick={exitEditMode}
        >
          Cancel
        </Button>
      </form>
    </div>
  );
}

export default SiteNameEditComponent;
