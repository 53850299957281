import React from "react";
import SiteMapWithMarker from "./components/site-map-with-marker/SiteMapWithMarker";
import SiteMapWithSearch from "./components/site-map-with-search/SiteMapWithSearch";
import "./SiteMap.scss";
export const SiteMapVariants = {
  withMaker: "site-map-with-marker",
  withSearch: "site-map-with-search",
};

function SiteMap(props) {
  const { variant, ...rest } = props;
  const renderSiteMap = () => {
    switch (variant) {
      case SiteMapVariants.withSearch:
        return <SiteMapWithSearch {...rest} />;
      default:
      case SiteMapVariants.withMaker:
        return <SiteMapWithMarker {...rest} />;
    }
  };
  return renderSiteMap();
}

export default SiteMap;
