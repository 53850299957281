import React, { useState } from "react";
import Annotation from "./Annotation";
import uuid from "uuid/v1";
import { Layer, Rect, Stage, Text } from "react-konva";
import "./FloorPlanEditor.scss";
import { Button, ButtonVariants } from "../../../atoms";

const WIDTH = 100;
const HEIGHT = 100;

const grid = [
  ["white", "white"],
  ["white", "white"],
];
function FloorPlanEditor(props) {
  const {
    handleUpdateFloorPlan,
    roomsOfSelectedFloor,
    handleCancelEditFloorplanButtonClicked,
    devices,
  } = props;
  const [annotations, setAnnotations] = useState(roomsOfSelectedFloor);
  const [newAnnotation, setNewAnnotation] = useState([]);
  const [selectedId, selectAnnotation] = useState(null);
  const [addRoomFlag, setAddRoomFlag] = useState(false);
  const [resizeRoomFlag, setResizeRoomFlag] = useState(false);
  const [placednodesList, setplacedNodesList] = useState([]);
  const [placednodesIDList, setPlacedNodesIDList] = useState([]);
  const [addNodeFlag, setAddNodeFlag] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({});
  const [placeNodeButtonVariantFlag, setPlaceNodeButtonVariantFlag] = useState(
    false
  );
  const [
    deleteNodeButtonVariantFlag,
    setDeleteNodeButtonVariantFlag,
  ] = useState(false);

  const checkDeselect = (event) => {
    const clickedOnEmpty = event.target.attrs.type === "grid";
    if (clickedOnEmpty) {
      selectAnnotation(null);
    }
  };
  const handleMouseDown = (event) => {
    checkDeselect(event);
    if (selectedId === null && newAnnotation.length === 0 && addRoomFlag) {
      const { x, y } = event.target.getStage().getPointerPosition();
      const id = uuid();
      setNewAnnotation([{ x, y, width: 0, height: 0, id, roomName: "" }]);
    }
  };

  const handleMouseMove = (event) => {
    if (selectedId === null && newAnnotation.length === 1 && addRoomFlag) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();
      const id = uuid();
      setNewAnnotation([
        {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          id,
          roomName: "",
        },
      ]);
    }
  };

  const handleMouseUp = () => {
    if (selectedId === null && newAnnotation.length === 1 && addRoomFlag) {
      annotations.push(...newAnnotation);
      setAnnotations(annotations);
      setNewAnnotation([]);
      setAddRoomFlag(false);
    }
  };

  const handleMouseEnter = (event) => {
    event.target.getStage().container().style.cursor = "crosshair";
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      if (selectedId !== null) {
        const array = annotations.filter(
          (annotation) => (annotation._id || annotation.id) !== selectedId
        );
        setAnnotations(array);
      }
    }
  };

  const handleDeleteRoomClicked = () => {
    if (selectedId !== null) {
      const array = annotations.filter(
        (annotation) => (annotation._id || annotation.id) !== selectedId
      );
      setAnnotations(array);
    }
  };

  const handleAddNodeClicked = () => {
    setAddNodeFlag(true);
    setResizeRoomFlag(false);
  };
  const handleResizeRoomClicked = () => {
    setResizeRoomFlag(true);
    setAddNodeFlag(false);
  };
  const renderDevicesDropdown = () => {
    // const filterDevicesBasedOnType = (type) => {
    //   return devices.filter(
    //     (device) => device.type === `virtual_${type}` || device.type === type
    //   );
    // };
    // const satellites = filterDevicesBasedOnType("satellite");
    // const objects = filterDevicesBasedOnType("object");
    // const baseStations = filterDevicesBasedOnType("base_station");

    const handleDeviceSelection = (e) => {
      const index = e.target.selectedIndex;
      const optionElement = e.target.childNodes[index];
      const optionElementId = optionElement.getAttribute("id");

      const selectedDeviceDetails = devices.filter(
        (device) => device.nodeID === optionElementId
      );

      if (optionElementId !== null) {
        setSelectedDevice({
          name: selectedDeviceDetails[0].name,
          nodeID: optionElementId,
          type: selectedDeviceDetails[0].type,
        });
      }
      // enable/disable "Place node" button
      if (
        optionElementId !== null &&
        (placednodesIDList.length !== 0
          ? !placednodesIDList.includes(optionElementId)
          : true)
      ) {
        setPlaceNodeButtonVariantFlag(true);
      } else setPlaceNodeButtonVariantFlag(false);
      // enable/disable "Delete node" button
      if (
        optionElementId !== null &&
        (placednodesIDList.length !== 0
          ? placednodesIDList.includes(optionElementId)
          : false)
      ) {
        setDeleteNodeButtonVariantFlag(true);
      } else setDeleteNodeButtonVariantFlag(false);
    };

    const renderDevicesBasedOnTypes = (type) => {
      if (type.length !== 0) {
        return type.map((device) => (
          <option value={device.name} id={device.nodeID}>
            {device.name}
          </option>
        ));
      } else return <option>None</option>;
    };

    return (
      <div className="select_device-floorplan_editor">
        <label for="nodes">Select a Node:</label>
        <select
          name="nodes"
          id="nodes"
          // value={selectedDeviceId}
          onChange={(e) => handleDeviceSelection(e)}
        >
          <option value="Select">Select</option>
          {/* <optgroup label="Satellites" id="satellites">
            {renderDevicesBasedOnTypes(satellites)}
          </optgroup>
          <optgroup label="Base-Stations" id="base-stations">
            {renderDevicesBasedOnTypes(baseStations)}
          </optgroup>
          <optgroup label="Others" id="others">
            {renderDevicesBasedOnTypes(objects)}
          </optgroup> */}
          {renderDevicesBasedOnTypes(devices)}
        </select>
      </div>
    );
  };

  const handleRenderingNodeBasedOnPointerPosition = (e, roomX, roomY) => {
    if (addNodeFlag && (selectedId !== undefined || null)) {
      const { x, y } = e.target.getStage().getPointerPosition();
      const diffX = x - roomX;
      const diffY = roomY - y;
      const nodeRoom = selectedId;
      let newNode = [
        {
          x,
          y,
          name: selectedDevice.name,
          id: selectedDevice.nodeID,
          type: selectedDevice.type,
          diffX,
          diffY,
          nodeRoom,
        },
      ];
      placednodesList.push(...newNode);
      setplacedNodesList(placednodesList);
      setAddNodeFlag(false);
      setPlaceNodeButtonVariantFlag(false);
      setDeleteNodeButtonVariantFlag(true);
      const idList = placednodesList.map((node) => node.id);
      setPlacedNodesIDList(idList);
    } else return;
  };

  const handleDeleteNodeClicked = (id) => {
    const array = placednodesList.filter(
      (node) => (node._id || node.id) !== id
    );
    setplacedNodesList(array);
    const idList = array.map((node) => node.id);
    setPlacedNodesIDList(idList);
    setDeleteNodeButtonVariantFlag(false);
    setPlaceNodeButtonVariantFlag(true);
  };

  const renderNodes = () => {
    const allNodes = placednodesList.map((node) => {
      const getcurrentXYofRooms = annotations.filter(
        (annotation) => (annotation._id || annotation.id) === node.nodeRoom
      );
      const x = getcurrentXYofRooms[0]?.x + node.diffX;
      const y = getcurrentXYofRooms[0]?.y - node.diffY;
      return (
        <>
          <Rect
            x={x}
            y={y}
            width={15}
            height={15}
            fill="rgb(48, 211, 186)"
            stroke="rgb(48, 211, 186)"
            type={node.type}
          />
          <Text key={i} fill="black" x={x} y={y - 20} text={node.name} />
        </>
      );
    });
    return allNodes;
  };

  const annotationsToDraw = [...annotations, ...newAnnotation];

  const editRoomName = (id) => {
    let roomname = prompt("Please enter Room name");
    const updateRoomName = (x) => {
      const grabRoom = annotations.filter(
        (annotation) => annotation.roomID === id
      );
      const otherRooms = annotations.filter(
        (annotation) => annotation.roomID !== id
      );
      grabRoom[0]["roomName"] = x;

      const annotationWithUpdatedName = [...grabRoom, ...otherRooms];
      setAnnotations(annotationWithUpdatedName);
    };
    //console.log(annotations);
    if (roomname !== null) {
      let roomName = roomname.trim();
      if (roomName !== "") {
        updateRoomName(roomName);
      }
    }
  };
  // Code for background Grid **start
  const stagePos = { x: 0, y: 0 };
  const startX = Math.floor((-stagePos.x - window.innerWidth) / WIDTH) * WIDTH;
  const endX =
    Math.floor((-stagePos.x + window.innerWidth * 2) / WIDTH) * WIDTH;

  const startY =
    Math.floor((-stagePos.y - window.innerHeight) / HEIGHT) * HEIGHT;
  const endY =
    Math.floor((-stagePos.y + window.innerHeight * 2) / HEIGHT) * HEIGHT;

  const gridComponents = [];
  var i = 0;

  for (var x = startX; x < endX; x += WIDTH) {
    for (var y = startY; y < endY; y += HEIGHT) {
      if (i === 4) {
        i = 0;
      }
      const indexX = Math.abs(x / WIDTH) % grid.length;
      const indexY = Math.abs(y / HEIGHT) % grid[0].length;
      gridComponents.push(
        <Rect
          key={uuid()}
          x={x}
          y={y}
          width={WIDTH}
          height={HEIGHT}
          fill={grid[indexX][indexY]}
          stroke="#77e6d5"
          type="grid"
        />
      );
    }
  }
  // Code for background Grid **end

  // Code for ZoomIn/ZoomOut **start
  const [stage, setStage] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStage({
      scale: newScale,
      x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
    });
  };
  // Code for ZoomIn/ZoomOut **start
  return (
    <div tabIndex={1} onKeyDown={handleKeyDown} className="editor-plane">
      <div className="side-plane">
        <div className="save_cancel_buttons-floorplan_editor">
          <Button
            className="side-plane_buttons"
            onClick={() => handleUpdateFloorPlan(annotations)}
          >
            Save Floor Plan
          </Button>
          <Button
            className="side-plane_buttons"
            onClick={() => handleCancelEditFloorplanButtonClicked()}
            variant={ButtonVariants.dangerOutlined}
          >
            Cancel
          </Button>
        </div>
        <div className="add_delete_buttons-floorplan_editor">
          <Button
            className="side-plane_buttons"
            onClick={() => setAddRoomFlag(true)}
          >
            Add Room
          </Button>
          <Button
            className="side-plane_buttons"
            onClick={() => handleResizeRoomClicked()}
          >
            Resize Room
          </Button>
          <Button
            className="side-plane_buttons"
            onClick={() => handleDeleteRoomClicked()}
            variant={ButtonVariants.dangerOutlined}
          >
            Delete Room
          </Button>
        </div>
        <div className="node_buttons-floorplan_editor">
          {renderDevicesDropdown()}
          <Button
            className="side-plane_buttons"
            onClick={() => handleAddNodeClicked()}
            id="Add-node"
            variant={
              placeNodeButtonVariantFlag
                ? ButtonVariants.primary
                : ButtonVariants.disabled
            }
          >
            Place Node
          </Button>
          <Button
            className="side-plane_buttons"
            onClick={() => handleDeleteNodeClicked(selectedDevice.nodeID)}
            variant={
              deleteNodeButtonVariantFlag
                ? ButtonVariants.dangerOutlined
                : ButtonVariants.disabled
            }
          >
            Delete Node
          </Button>
        </div>
      </div>
      <div className="stage">
        <Stage
          width={window.innerWidth - 300}
          height={window.innerHeight - 180}
          onMouseEnter={handleMouseEnter}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={checkDeselect}
          style={
            ({ border: "1px solid red" },
            { background: "lightGray" },
            { width: "100%" })
          }
          onWheel={(event) => handleWheel(event)}
          scaleX={stage.scale}
          scaleY={stage.scale}
          x={stage.x}
          y={stage.y}
        >
          <Layer>{gridComponents}</Layer>
          <Layer>
            {annotationsToDraw.map((annotation, i) => {
              return (
                <>
                  <Annotation
                    key={uuid()}
                    shapeProps={annotation}
                    isSelected={
                      (annotation._id || annotation.id) === selectedId
                    }
                    addNodeFlag={addNodeFlag}
                    resizeRoomFlag={resizeRoomFlag}
                    handleRenderingNodeBasedOnPointerPosition={
                      handleRenderingNodeBasedOnPointerPosition
                    }
                    onSelect={(e) => {
                      selectAnnotation(
                        annotation._id !== undefined
                          ? annotation._id
                          : annotation.id
                      );
                    }}
                    onChange={(newAttrs) => {
                      const rects = annotations.slice();
                      rects[i] = newAttrs;
                      setAnnotations(rects);
                    }}
                  />

                  <Text
                    key={i}
                    fill="red"
                    x={annotation.x + annotation.width / 2 - 15}
                    y={annotation.y + annotation.height / 2}
                    text={
                      annotation.roomName === ""
                        ? `room${i + 1}`
                        : annotation.roomName
                    }
                    onClick={() => {
                      editRoomName(annotation.roomID);
                    }}
                  />
                </>
              );
            })}
          </Layer>
          <Layer>{renderNodes()}</Layer>
        </Stage>
      </div>
    </div>
  );
}

export default FloorPlanEditor;
