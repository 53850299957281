import React, { useState } from "react";
import {
  Button,
  ButtonVariants,
  Input,
  InputVariants,
} from "../../../../atoms";
import Drawer from "../../../../templates/interior/components/Drawer";
import { useDebouncer, useFetch } from "../../../../../utils/hooks/index";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import { notify } from "../../../../../utils";
import { DropDownUserResults } from "../../../../molecules";

function AddUserToUserGroup(props) {
  const { groupName, groupID, siteId, getUserGroup } = props;
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const { post } = useFetch();
  const { get: getMatchingUsers } = useFetch();
  const [matchingUsers, setMatchingUsers] = useState([]);

  const findMatchingUsernames = (usernameOrEmail) => {
    if (usernameOrEmail !== "") {
      getMatchingUsers(
        API_ENDPOINTS.searchUser(encodeURIComponent(usernameOrEmail))
      )
        .then((res) => {
          if (res.status === "success") setMatchingUsers(res.data);
          if (res.status === "error") setMatchingUsers([]);
        })
        .catch(console.error);
    }
  };

  useDebouncer(() => findMatchingUsernames(usernameOrEmail), 500, [
    usernameOrEmail,
  ]);

  const renderMatchingUsers = () => {
    if (matchingUsers.length === 0) return;

    return matchingUsers.map((user) => (
      <DropDownUserResults
        key={user.username}
        user={user}
        avtar={user.avtar}
        username={user.username}
        name={`${user.firstName} ${user.lastName}`}
        onClick={() => setUsernameOrEmail(user.username)}
      />
    ));
  };

  const handleSubmit = async () => {
    post(API_ENDPOINTS.userGroupUser(groupID), {
      inviteeEmailOrUsername: usernameOrEmail,
    })
      .then((response) => {
        if (response.status === "success") {
          notify.success(response.data);
          getUserGroup(API_ENDPOINTS.userGroupsData());
        } else if (response.status === "error") {
          notify.error(response.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };

  const handleOnChange = function (e) {
    const { value } = e.target;
    setUsernameOrEmail(value);
  };

  return (
    <>
      <Drawer.Title heading={`Add ${groupName}`} />
      <Drawer.Description>
        Add user to the {groupName} access group.
      </Drawer.Description>
      <div className="search-bar_addUserAccessManagement">
        <div className="searchBarandResults_wrapper">
          <Input
            label="Username or Email"
            value={usernameOrEmail}
            onChange={(e) => handleOnChange(e)}
            variant={InputVariants.text}
          />
          {usernameOrEmail.trim() ? (
            <div className="search-results-dropdown">
              {renderMatchingUsers()}
            </div>
          ) : (
            ""
          )}
        </div>
        <Button
          className="add-user_button_drawer"
          variant={
            usernameOrEmail.trim()
              ? ButtonVariants.success
              : ButtonVariants.disabled
          }
          onClick={handleSubmit}
        >
          Add User
        </Button>
      </div>
    </>
  );
}

export default AddUserToUserGroup;
