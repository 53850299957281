export const DATETIME_FORMAT = {
  date: "date",
  dateAndTime: "dateAndTime",
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const datePostFix = (date) => {
  if (date % 10 === 1) return `${date}`;
  if (date % 10 === 2) return `${date}`;
  return `${date}`;
};

export const composeDateUsingTimestamp = (
  timestamp,
  format = DATETIME_FORMAT.date
) => {
  const date = datePostFix(new Date(timestamp).getDate());
  const month = MONTHS[new Date(timestamp).getMonth()];
  const year = new Date(timestamp).getFullYear();
  const hours = new Date(timestamp).getHours();
  const minutes = new Date(timestamp).getMinutes();
  const seconds = new Date(timestamp).getSeconds();

  const composeTime = () => {
    const composeHours = hours >= 12 ? hours - 12 : hours;
    const amPm = hours >= 12 ? "PM" : "AM";
    return `${composeHours}:${minutes}:${seconds} ${amPm}`;
  };

  const composeDate = () => `${date} ${month} ${year}`;

  switch (format) {
    case DATETIME_FORMAT.dateAndTime:
      return `${composeDate()} ${composeTime()}`;
    case DATETIME_FORMAT.date:
    default:
      return `${composeDate()}`;
  }
};
