import React from "react";
//import { Link } from "../../atoms";
import "./SaveDeviceModal.scss";
//import classnames from "classnames";
import PropTypes from "prop-types";
import { API_ENDPOINTS } from "../../../utils/constants";
import { useFetch } from "../../../utils/hooks";
import { notify } from "../../../utils";

/**
 * This  can be used as sub menu in interior pages
 */
function SaveDeviceModal(props) {
  const { updateCheck, setShowModal, className, ...rest } = props;
  const { post } = useFetch();
  //const classes = classnames(className, "map-nav");
  // ref={modalRef} onClick={closeModal}
 // console.log("render modal")
  const renderMenu = () => {
    //console.log("render menu")
    return (
      <div className="container" >
      <div className="modal">
        <h2>This is a Modal</h2>
        {/* <button onClick={() => setShowModal(false)}>X</button> */}
      </div>
    </div>
    );
  };

  const saveModal = () => {
      console.log("permission State: fingerprint ", localStorage.getItem("deviceID"))
  post(API_ENDPOINTS.deviceCreate(), {
    fingerprint: localStorage.getItem("deviceID"),
    name: "My PC",
    type: "DESKTOP",
    metaData: {}
  })
  .then((res) => {
    if (res.success === true) {
      notify.success("Device Adds successfully", {
        toastId: "DeviceCreateSuccess",
      });
      localStorage.setItem("id", res.data.id)
      localStorage.setItem(""+localStorage.getItem("userID"), true)
      // console.log("respoince creeate device:  ", res)
      updateCheck("true")
      setShowModal(false)
      } else if (res.success === false) {
        localStorage.setItem(""+localStorage.getItem("userID"), true)
        console.log("error creeate device:  ", res)
        updateCheck("true")
        setShowModal(false)
      }
    })
  .catch((error) => notify.error(error));
    // localStorage.setItem(""+localStorage.getItem("userID"), true)
    setShowModal(false)
  }
  const dontSaveModal = () => {

    localStorage.setItem(""+localStorage.getItem("userID"), false)
    updateCheck("false")
    setShowModal(false)
  }


  // className={classes} {...rest}
  //console.log("render modal")
  return (
    <div className="modal-container" >
    <div className="modal">
      <h2>Would you like to save this device?</h2>
      <button onClick={() => setShowModal(false)}>X</button>
      <button className="button-save" onClick={() => saveModal()}>Yes</button>
      <button className="button-dont-save" onClick={() => dontSaveModal()}>No</button>
    </div>
  </div>
  );
}

export default SaveDeviceModal;

SaveDeviceModal.propTypes = {
  /**
   * This is an array of objects with two properties:
   * eg.
   * ```
   *  [{name:"name", path:"path"}]
   * ```
   */
  routeDetails: PropTypes.array.isRequired,
};
