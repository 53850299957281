import React from "react";
import { CheckBoxInput } from "./CheckBoxInput/CheckBoxInput";
import { TextInput } from "./TextInput/TextInput";

export const InputVariants = {
  text: "text",
  checkBox: "checkbox",
};

const Input = React.forwardRef(function Input(props, ref) {
  const { variant, ...rest } = props;

  switch (variant) {
    case InputVariants.checkBox: {
      return <CheckBoxInput ref={ref} {...rest} />;
    }
    default:
    case InputVariants.text: {
      return <TextInput ref={ref} {...rest} />;
    }
  }
});

export { Input };
