import React from "react";
import { Button, ButtonVariants, Input, InputVariants } from "../../../atoms";
import Joi from "@hapi/joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers";
import {
  makeRequest,
  NOTIFICATION_MESSAGES,
  notify,
  STATUS_CODE,
} from "../../../../utils";
import { API_ENDPOINTS } from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../../../../redux/user/actions";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required(),
});

function EditEmail(props) {
  const { exitEditMode } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });

  const currentEmail = useSelector((state) => state.user?.data.email);

  const handleSignIn = async (data) => {
    const { email, password } = data;

    const body = { emailOrUsername: currentEmail, password };
    try {
      const response = await makeRequest.post(API_ENDPOINTS.login, body);
      if (response.status === "success") updateEmail(email);
      if (response.code === STATUS_CODE.notFound)
        notify.error(response.error.message);
    } catch (error) {
      console.error(error);
      notify.error(NOTIFICATION_MESSAGES.internalServerError);
    }
  };
  const updateEmail = async (emailId) => {
    try {
      const res = await makeRequest.patch(API_ENDPOINTS.editEmail, {
        newEmail: emailId,
      });
      if (res) {
        if (res.status === "success") {
          dispatch(userDetails());
          notify.success(res.data);
          notify.success(NOTIFICATION_MESSAGES.emailUpdateRequestSent);
        }
        if (res.status === "error") notify.error(res.error.message);
      }
      //console.log(res);
    } catch (err) {
      console.error(err);
      notify.error(NOTIFICATION_MESSAGES.internalServerError);
    }
  };

  const onSubmit = (data) => {
    handleSignIn(data);
  };

  return (
    <div className="edit-email">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="email"
          variant={InputVariants.text}
          label="New Email"
          ref={register}
          error={errors.email?.message}
        />
        <Input
          variant={InputVariants.text}
          type="password"
          name="password"
          label="Password"
          error={errors.password?.message}
          ref={register}
        />
        <Button variant={ButtonVariants.success} type="submit">
          Save
        </Button>
        <Button
          variant={ButtonVariants.dangerOutlined}
          type="button"
          onClick={exitEditMode}
        >
          Cancel
        </Button>
      </form>
    </div>
  );
}

export default EditEmail;
