import React, {  useEffect, useState } from "react";
import "./MsgSlideActive.scss";
import House from "../../../assets/images/default-profile-icon.png";
import {  MsgSlideWindow } from "../../molecules";

function MsgSlideActive(props) {
   const { msg, c, getUserGroup, ...rest } = props;
   let msgName, recipientID, picture, name;
   const [show, setState] = useState(false);

   function updateState(props){
    setState(props);
  }
  //  console.log("participant:  ", msg?.participants, "   c: ",c )
 // console.log("participant:  ", msg?.participants?.[0].id, "   id 2: ",msg?.participants?.[1].id )
  if(c){
   for(let i = 0; i< c.length; i++){
    // console.log("participant:  ", msg?.participants?.[1], "   id: ",c?.[i] )
      if((msg?.participants?.[1].id === c?.[i].user.id || msg?.participants?.[0].id === c?.[i].user.id) 
        && c?.[i].user.id !== localStorage.getItem("userID") ){
        //console.log("inside participant:  ", c?.[i].id, "   id: ",localStorage.getItem("userID") )
        msgName = c?.[i].inviteeEmail;
        recipientID = c?.[i].user.id;
        picture = c?.[i].user.profilePicture
        name = c?.[i].user.username
      }
   }
  }
 // console.log("msg:  ", msg, "   c: ",c )
//  console.log("picture:  ", picture)
  return (
    <>
    <div
      className="msg-slide__molecule"
      onClick={() => updateState(!show)}>
      <img
        src={picture}
        className="msg-slide-active__molecule-house-image"
        alt={"House"}/>
      <div className="msg-slide-btn-text">
        {/* {name} */}
      </div>
    </div>
      <MsgSlideWindow recipientID={recipientID} msg={msg} show={show}/>
    </>
  );
}

export default MsgSlideActive;

MsgSlideActive.propTypes = {
  /**
   * The information that will be used to display
   */
  //node: PropTypes.object.isRequired,
};