import React, { useContext, useEffect } from "react";
import { Error, TextAtom, TextVariants } from "../../../../../atoms";
import SiteDeviceCard from "../../../../../molecules/site-device-card/SiteDeviceCard";
import { InteriorContext } from "../../../../../templates/interior/components/InteriorContext";
import SiteDeviceInfo from "./components/SiteDeviceInfo";
import "./SiteDevices.scss";

function SiteDevicesComponent(props) {
  const { siteDevicesData, error, get, siteDetail } = props;
  const interiorContext = useContext(InteriorContext);
  useEffect(() => {
    // Closing drawer when user leaves component
    return () => interiorContext.closeDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSiteDeviceCardClicked = (siteDeviceData) => {
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(
      <SiteDeviceInfo
        siteDeviceData={siteDeviceData}
        get={get}
        siteDetail={siteDetail}
      />
    );
  };

  const renderDevices = () => {
    return siteDevicesData.map((siteDeviceData) => {
      if(siteDeviceData?.type !== "object"){
      return (
        <SiteDeviceCard
          key={siteDeviceData?.nodeID}
          type={siteDeviceData?.type}
          name={siteDeviceData?.name}
          discoveredAt={siteDeviceData?.discoveredAt}
          lastUpdatedAt={siteDeviceData?.lastUpdatedAt}
          lastRebooted={siteDeviceData?.lastRebooted}
          onClick={() => handleSiteDeviceCardClicked(siteDeviceData)}
        />
      );
      } else {
        return (null);
      }
    });
  };

  const handleDeviceData = () => {
    if (error) return <Error />;
    if (siteDevicesData?.length > 0)
      return <div className="site-devices-cards_wrap">{renderDevices()}</div>;
    else {
      return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <TextAtom variant={TextVariants.extraLarge}>
            {" "}
            No devices found !! You can onboard one using InLo mobile App
          </TextAtom>
        </div>
      );
    }
  };

  return handleDeviceData();
}

export default SiteDevicesComponent;
