export const isDeleteSiteAllowed = (sitePermissionData) => {
  return sitePermissionData?.site.delete;
};
export const isSiteEditAllowed = (sitePermissionData) => {
  return sitePermissionData?.site.write;
};
export const isFloorPlanViewAllowed = (sitePermissionData) => {
  return sitePermissionData?.floorplan.read;
};
export const isEditFloorPlanAllowed = (sitePermissionData) => {
  return sitePermissionData?.floorplan?.write;
};
export const isDeleteFloorPlanAllowed = (sitePermissionData) => {
  return sitePermissionData?.floorplan?.delete;
};
export const isAccessManagementAllowed = (sitePermissionData) => {
  return sitePermissionData?.site.accessManagement;
};
export const isDeviceViewAllowed = (sitePermissionData) => {
  return sitePermissionData?.node?.read;
};
export const isEditDeviceAllowed = (sitePermissionData) => {
  return sitePermissionData?.node?.write;
};
export const isDeleteDeviceAllowed = (sitePermissionData) => {
  return sitePermissionData?.node?.delete;
};
