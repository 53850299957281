import React from "react";
import { ToastContainer } from "react-toastify";
import Routes from "./Routes";
import { NOTIFICATION_CONFIG } from "./utils";

function App() {
  return (
    <>
      <ToastContainer {...NOTIFICATION_CONFIG} />
      <Routes />
    </>
  );
}

export default App;
