import React, { useEffect, useState, useCallback } from "react";
import "./SiteList.scss";
import House from "../../../assets/images/house.jpeg";
import { TextAtom, TextColors, TextVariants, TextWeights } from "../../atoms";
import PropTypes from "prop-types";

function SiteListRender({ map, rest, image, name, addr, lat, lon, siteid, updateSiteID }) {
  const [position, setPosition] = useState(map.getCenter())
  const centerr = [lat, lon]
  const id = siteid
  const zoom = 18

  //console.log(image)
  const redirectToLocationDetails = useCallback(() => {
    map.setView(centerr, zoom)
    updateSiteID(id)
   // console.log("site center: ", centerr)
  }, [map])

  const onMove = useCallback(() => {
    setPosition(map.getCenter())
    //console.log("site set center: ", centerr)
  }, [map])

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  return (
    <div
      {...rest}
      className="site-list__molecule"
      onClick={redirectToLocationDetails}
    >
      <img src={image} alt={addr} className="site-list__molecule-house-image" />
      <div className="site-list__molecule-text">
        <TextAtom
          className="site-list__molecule-name"
          variant={TextVariants.regular}
          weight={TextWeights.bold}
          color={TextColors.primaryGrey}
        >
          {name}
        </TextAtom>
      </div>
    </div>
  );
}

function SiteList(props) {
  const { site, className, map, updateSiteID, ...rest} = props;
  const { name, country, zipcode, city, address, siteID, ownedBy, lat, long  } = site;

  return (
    <>
      {map ? <SiteListRender map={map} rest={rest} image={site.picture} name={name} addr={address} lat={lat} lon={long} siteid={siteID} updateSiteID={updateSiteID}/> : null}
    </>
  );
}

export default SiteList;

SiteList.propTypes = {
  /**
   * The information that will be used to display the sitedetails of the card
   */
  site: PropTypes.object.isRequired,
};
