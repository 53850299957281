/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import { useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router";
import UserDetailComponent from "./component";
import { getUser } from "../../../../../redux/user/actions";

function UserDetails() {
  const { userId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(userId));
  }, [dispatch]);

  // const sites = useSelector((state) => state.sites);
  const members = useSelector((state) => state.members);
  const user = useSelector((state) => state.user);

  // const siteData = sites.data?.sites;
  // const userDetail = siteData?.find((site) => site.id === userId);

  const userDets = user.data
  const userDeter = userDets?.[0]
  const userDetail = userDets?.[0]

  const memberData = members.data?.results
  const memberDetail = memberData?.find((member) => member.user.username === userId)


  //  console.log("UserDetails memberData:  ", memberData)
  //  console.log("UserDetails userDetail:  ", userDetail)
   console.log("UserDetails userDeter:  ", userDeter)
   if(memberDetail === undefined){
    return <UserDetailComponent members={members} 
                                userId={userId} 
                                user={userDeter}
                                memberDetail={memberDetail} 
                                userDetail={userDetail} />;
   } else {
    return <UserDetailComponent members={members} 
                                userId={userId} 
                                memberDetail={memberDetail} 
                                userDetail={userDetail} />;
   }
  // return null
}

export default UserDetails;
