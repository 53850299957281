import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./Button.scss";

export const ButtonVariants = {
  primary: "primary",
  primaryRight: "primary-right",
  primaryOutlined: "primary-outlined",
  danger: "danger",
  dangerOutlined: "danger-outlined",
  success: "success",
  successOutlined: "success-outlined",
  disabled: "disabled",
  disabledOutlined: "disabled-outlined",
};

export const ButtonSize = {
  small: "small",
  medium: "medium",
  large: "large",
};

function Button(props) {
  let {
    children,
    variant,
    size,
    className,
    withIcon,
    loading,
    onClick,
    ...rest
  } = props;

  const classes = classnames(
    `button-atom__`,
    `button-atom__${variant}`,
    `button-atom__${size}`,
    withIcon && `button-atom__with-icon`,
    className
  );

  const renderChildren = () => {
    if (loading) {
      return "Loading...";
    }
    return children;
  };

  if (
    variant === ButtonVariants.disabled ||
    variant === ButtonVariants.disabledOutlined
  ) {
    onClick = () => {};
  }

  return (
    <button className={classes} onClick={onClick} {...rest}>
      {renderChildren()}
    </button>
  );
}

export default Button;

Button.propTypes = {
  /**
   *  Text that wil go inside the button.
   *  The button will also accept react components as children
   */
  children: PropTypes.node.isRequired,
  /**
   * Pick your variant
   */
  variant: PropTypes.oneOf(Object.values(ButtonVariants)),
  /**
   * Size of the button
   */
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  /**
   * Button with and Icon. Component will merely add spacing. Icon has to be supplier by the importer
   */
  withIcon: PropTypes.bool,

  /**
   * Shows loader when set to true
   */
  loading: PropTypes.bool,
};

Button.defaultProps = {
  children: "Default button Text",
  variant: ButtonVariants.primary,
  size: ButtonSize.medium,
  loading: false,
};
