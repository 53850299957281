import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import "./Link.scss";

export const LinkVariant = {
  redirect: "redirect",
};

function Link(props) {
  const { to, children, className, variant, ...rest } = props;
  const classes = classnames(`link-atom__${variant}`, "link-atom__", className);

  return (
    <NavLink className={classes} {...rest} to={to}>
      {children}
    </NavLink>
  );
}

export default Link;

Link.propTypes = {
  to: PropTypes.string.isRequired,
  /**
   * The children are typically text but we can also render react components as chidren
   */
  children: PropTypes.node.isRequired,
  /**
   * Choose your variant.
   *
   * - the redirect variant redirects the page to somewhere.
   */
  variant: PropTypes.oneOf(Object.values(LinkVariant)),
};
