import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router";
import { useWindowWidth } from "@react-hook/window-size";
import { REQ_STATE } from "../../../../../utils";
import { ROUTES } from "../../../../../utils/constants";
import { SCREEN_SIZE } from "../../../../../utils/layout";
import {
  Button,
  ButtonVariants,
  Error,
  Link,
  Loading,
  TextAtom,
  TextVariants,
  Map,
  MapVariants,
} from "../../../../atoms";
import {
  InteriorPageHeadingWithCTA,
  LocationCard,
} from "../../../../molecules";
import "./DisplaySites.scss";
import { useSelector } from "react-redux";

function DisplaySitesComponent(props) {
  //console.log(props)
  const { sites } = props;
  const history = useHistory();
  const width = useWindowWidth();
  const userIDLoggedIn = useSelector((state) => state.user?.data?.userID);

  const handleSitesData = () => {
    // eslint-disable-next-line default-case
    switch (sites.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return renderSites();
    }
  };

  const noSites = () => {
    return (
      <TextAtom variant={TextVariants.regular}>
        Looks like you havent added sites yet! Feel free to{" "}
        <Link to={ROUTES.createSite}> add a site</Link>
      </TextAtom>
    );
  };

  const renderLocationCards = () => {
    const siteLocations = sites.data.sites;

    const ownerSiteLocationCardsData = siteLocations.filter(
      (site) => site.owner === userIDLoggedIn
    );
    const notOwnedSiteLocationCardsData = siteLocations.filter(
      (site) => site.owner !== userIDLoggedIn
    );

    const ownerSiteLocationCards = () => {
      if (ownerSiteLocationCardsData?.length !== 0)
        return ownerSiteLocationCardsData.map((site) => (
          <LocationCard key={site.id} site={site} className="card" />
        ));
      else return "";
    };
    const notOwnedSiteLocationCards = () => {
      if (notOwnedSiteLocationCardsData?.length !== 0)
        return notOwnedSiteLocationCardsData.map((site) => (
          <LocationCard key={site.id} site={site} className="card" />
        ));
      else return "";
    };

    return (
      <>
        {ownerSiteLocationCards()}
        {notOwnedSiteLocationCards()}
      </>
    );
  };

  const extractLocationData = (results) => {
    const locations = [];
    results.forEach((location) => {
      const { name, long, lat } = location;
      const locationData = {
        name,
        longLat: [lat, long],
      };
      locations.push(locationData);
    });
    return locations;
  };

  const renderSites = () => {
    // When there are no sites
    if (sites.data.count === 0) return noSites();

    // When there are sites
    return (
      <div className="containerr">
        <Map
          variant={MapVariants.withMaker}
          className="map"
          locationData={extractLocationData(sites.data.sites)}
          scrollWheelZoom={SCREEN_SIZE.desktop(width)}
        />
        <div className="location-cards">{renderLocationCards()}</div>
      </div>
    );
  };

  const handleCreateSiteCTAClicked = () => {
    history.push(ROUTES.createSite);
  };

  return (
    <div className="sites__page-main">
      <InteriorPageHeadingWithCTA heading="Sites">
        <Button
          variant={ButtonVariants.primary}
          withIcon
          onClick={handleCreateSiteCTAClicked}
        >
          <FontAwesomeIcon icon={faPlus} className="icon" />
          Create New Site
        </Button>
      </InteriorPageHeadingWithCTA>

      {handleSitesData()}
    </div>
  );
}

export default DisplaySitesComponent;
