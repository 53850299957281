import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../../redux/notifications/actions";
import NotificationsComponent from "./component";

function Notifications() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);
  // const msgProfile = useSelector((state) => state.msgProfile);
  const notifications = useSelector((state) => state.notifications);
  return <NotificationsComponent /*msgProfile={msgProfile}*/ notifications={notifications} />;
}

export default Notifications;
