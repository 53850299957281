import React from "react";
import { useSelector } from "react-redux";
import ProfileComponent from "./component";

function Profile() {
  const msgProfile = useSelector((state) => state.msgProfile);
  const msgConvo = useSelector((state) => state.msgConvo);
  const sites = useSelector((state) => state.sites);
  const user = useSelector((state) => state.user);

  // dispatch(getMsgProfile());
  //   dispatch(getMsgConvo());


  return <ProfileComponent msgProfile={msgProfile} msgConvo={msgConvo} user={user} sites={sites} />;
}

export default Profile;
