import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { Page } from "../../atoms";
import { Interior } from "../../templates";
import CreateSite from "./components/create-user/container";
import DisplaySites from "./components/display-users/container";
import UserDetails from "./components/user-details/container";

function UsersComponent(props) {
  const { msgProfile } = props
  const { path } = useRouteMatch();

  const renderChildRoutes = () => (
    <Switch>
      <Route exact path={path}>
        {/* <DisplaySites /> */}
        <UserDetails />
      </Route>
      <Route path={`${path}/:userId`}>
      <UserDetails />
      </Route>
    </Switch>
  );

  return (
    <Page title="Users" description="Users">
      <Interior > {renderChildRoutes()}</Interior>
    </Page>
  );
}

export default UsersComponent;
