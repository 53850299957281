/* eslint-disable default-case */
import React, { useState } from "react";
import { Button, ButtonVariants, Input, InputVariants } from "../../../atoms";
import Joi from "@hapi/joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers";
import { makeRequest, NOTIFICATION_MESSAGES, notify } from "../../../../utils";
import { API_ENDPOINTS } from "../../../../utils/constants";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const schema = Joi.object({
  oldPassword: Joi.string().min(3).max(15).required(),
  newPassword: Joi.string().min(3).max(15).required(),
  passwordConfirmation: Joi.string().required(),
});

function EditPassword(props) {
  const { exitEditMode } = props;
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const res = await makeRequest.patch(API_ENDPOINTS.editPassword, {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      if (res) {
        if (res.status === "success") {
          notify.success(res.data);
          // exitEditMode();
        }
        if (res.status === "error") notify.error(res.error.message);
      }
    } catch (err) {
      console.error(err);
      notify.error(NOTIFICATION_MESSAGES.internalServerError);
    }
  };

  const [isPasswordShown, setPasswordShown] = useState({
    oldPassword: false,
    newPassword: false,
    passwordConfirmation: false,
  });

  const togglePassword = (data) => {
    if (data === "oldPassword") {
      setPasswordShown(
        isPasswordShown.oldPassword
          ? { ...isPasswordShown, oldPassword: false }
          : { ...isPasswordShown, oldPassword: true }
      );
    } else if (data === "newPassword") {
      setPasswordShown(
        isPasswordShown.newPassword
          ? { ...isPasswordShown, newPassword: false }
          : { ...isPasswordShown, newPassword: true }
      );
    } else if (data === "passwordConfirmation") {
      setPasswordShown(
        isPasswordShown.passwordConfirmation
          ? { ...isPasswordShown, passwordConfirmation: false }
          : { ...isPasswordShown, passwordConfirmation: true }
      );
    }
  };

  const renderTogglePasswordIcon = (data) => {
    //console.log(data);
    if (data === "oldPassword") {
      return (
        <FontAwesomeIcon
          onClick={togglePassword}
          icon={isPasswordShown.oldPassword ? faEye : faEyeSlash}
        />
      );
    } else if (data === "newPassword") {
      return (
        <FontAwesomeIcon
          onClick={togglePassword}
          icon={isPasswordShown.newPassword ? faEye : faEyeSlash}
        />
      );
    } else if (data === "passwordConfirmation") {
      return (
        <FontAwesomeIcon
          onClick={togglePassword}
          icon={isPasswordShown.passwordConfirmation ? faEye : faEyeSlash}
        />
      );
    }
  };

  return (
    <div className="edit-email">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          variant={InputVariants.text}
          type={isPasswordShown.oldPassword ? "text" : "password"}
          name="oldPassword"
          label="Current Password"
          error={errors.oldPassword?.message}
          ref={register}
        />
        <i onClick={() => togglePassword("oldPassword")}>
          {renderTogglePasswordIcon("oldPassword")}
        </i>
        <Input
          variant={InputVariants.text}
          type={isPasswordShown.newPassword ? "text" : "password"}
          name="newPassword"
          label="New Password"
          error={errors.newPassword?.message}
          ref={register}
        />
        <i onClick={() => togglePassword("newPassword")}>
          {renderTogglePasswordIcon("newPassword")}
        </i>

        <Input
          variant={InputVariants.text}
          type={isPasswordShown.passwordConfirmation ? "text" : "password"}
          name="passwordConfirmation"
          label="Confirm Password"
          error={errors.passwordConfirmation?.message}
          ref={register}
        />
        <i onClick={() => togglePassword("passwordConfirmation")}>
          {renderTogglePasswordIcon("passwordConfirmation")}
        </i>
        <Button variant={ButtonVariants.success} type="submit">
          Update
        </Button>
        <Button
          variant={ButtonVariants.dangerOutlined}
          type="button"
          onClick={exitEditMode}
        >
          Close
        </Button>
      </form>
    </div>
  );
}

export default EditPassword;
