import React from "react";
import { useForm } from "react-hook-form";
import Joi from "@hapi/joi";
import logo from "../../../assets/icons/logo.svg";
import { joiResolver } from "@hookform/resolvers";
import {
  Button,
  ButtonVariants,
  Input,
  InputVariants,
  Link,
  Page,
  TextAtom,
  TextVariants,
} from "../../atoms";
import "./ForgotPassword.scss";
import { ROUTES } from "../../../utils/constants";
import metadata from "../../../../package.json";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});

function ForgotPasswordComponent(props) {
  const { sendPasswordResetLink } = props;
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });

  return (
    <Page title="Forgot Password" description="Get a new password">
      <div className="forgot-password__page">
        <TextAtom className="app-version_left-area">
          V{metadata.version}
        </TextAtom>
        <div className="left-area">
          <div className="forgot-password-option">
            <TextAtom variant={TextVariants.regular}>
              Want to give at another go?.
              <Link to={ROUTES.signIn}> Sign In. </Link>
            </TextAtom>
          </div>
          <div className="logo">
            <img src={logo} alt="Inlo Logo" />
          </div>
          <div className="forgot-password-form">
            <TextAtom variant={TextVariants.pageHeading} className="title">
              Create a new password
            </TextAtom>

            <form onSubmit={handleSubmit(sendPasswordResetLink)}>
              <Input
                variant={InputVariants.text}
                type="email"
                name="email"
                label="Registered Email"
                error={errors.email?.message}
                ref={register}
              />

              <Button variant={ButtonVariants.primary} type="submit">
                Get Link
              </Button>
            </form>
          </div>
        </div>
        <div className="right-area">
          <TextAtom variant={TextVariants.pageHeading}>
            Effortless organization and automation for your life.
          </TextAtom>
        </div>
      </div>
    </Page>
  );
}

export default ForgotPasswordComponent;
