import React from "react";
import { useSelector } from "react-redux";
import DisplaySiteOutdoorComponent from "./component";

function DisplaySiteOutdoor(props) {
  let { updateSiteID, getSiteID } = props;
  const sites = useSelector((state) => state.sites);
  const nodes = useSelector((state) => state.nodes);
  
  return <DisplaySiteOutdoorComponent nodes={nodes} sites={sites} getSiteID={getSiteID} updateSiteID={updateSiteID}/>;
}

export default DisplaySiteOutdoor;