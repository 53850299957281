/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import SiteDetailComponent from "./component";

function SiteDetails() {
  const { siteId } = useParams();

  console.log("useParams:  ", useParams())

  const sites = useSelector((state) => state.sites);
  const siteData = sites.data?.sites;
  const siteDetail = siteData?.find((site) => site.id === siteId);

  return <SiteDetailComponent siteId={siteId} siteDetail={siteDetail} />;
}

export default SiteDetails;
