import React from "react";
import "./SideNav.scss";
import { useWindowWidth } from "@react-hook/window-size";
import SideNavDesktop from "./components/SideNavDesktop";
import SideNavMobile from "./components/SideNavMobile";
import { SCREEN_SIZE } from "../../../utils";

function SideBar() {
  const width = useWindowWidth();
  const renderBasedOnScreenWidth = () => {
    if (SCREEN_SIZE.tablet(width)) return <SideNavMobile />;
    return <SideNavDesktop />;
  };

  return renderBasedOnScreenWidth();
}

export default SideBar;
