import React, {useEffect} from "react";
import { useDispatch, useSelector} from "react-redux";
import { getSites } from "../../../redux/sites/actions";
import {  getGeoFences } from "../../../redux/geoFences/actions";
import SitesComponent from "./component";

function Sites() {
  const dispatch = useDispatch();
  const msgProfile = useSelector((state) => state.msgProfile);
  useEffect(() => {
    dispatch(getSites());
    dispatch(getGeoFences());
  }, [dispatch]);

  
  return <SitesComponent msgProfile={msgProfile}/>;
}

export default Sites;
