import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getNotifications } from "../../../../../redux/notifications/actions";
import { notify } from "../../../../../utils";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import { useFetch } from "../../../../../utils/hooks";
import { Loading } from "../../../../atoms";
import NotificationsBarOnlyTextComponent from "./component";

function NotificationsBarOnlyText(props) {
  const { notificationData } = props;
  const dispatch = useDispatch();

  const {
    del: deleteThisNotification,
    loading: deleteThisNotification_loading,
  } = useFetch();

  const { get: getMatchingUser, loading: getMatchingUser_loading } = useFetch();

  const [userData, setUserData] = useState();

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteNotification = async () => {
    deleteThisNotification(
      API_ENDPOINTS.notification(notificationData.messageID)
    )
      .then((res) => {
        //console.log(res);
        if (res.status === "success") {
          notify.success("Notification deleted", {
            toastId: "NotificationDeleted",
          });
          dispatch(getNotifications());
        } else if (res.status === "error") {
          notify.error(res.error.message);
          //console.log(res.error.message);
          dispatch(getNotifications());
        }
      })
      .catch((error) => notify.error(error.error.message));
  };
  if (deleteThisNotification_loading) {
    return <Loading />;
  }

  const getUserData = () => {
    const extractUserEmailfromMessage = notificationData?.message.split(" ")[0];
    //console.log(extractUserEmailfromMessage);
    if (extractUserEmailfromMessage !== "You") {
      getMatchingUser(API_ENDPOINTS.searchUser(extractUserEmailfromMessage))
        .then((res) => {
          if (res.status === "success") setUserData(res.data[0]);
          else if (res.status === "error") return null;
        })
        .catch(console.error);
    } else setUserData(undefined);
  };
  if (getMatchingUser_loading) {
    return <Loading />;
  }
  return (
    <NotificationsBarOnlyTextComponent
      notificationData={notificationData}
      handleDeleteNotification={handleDeleteNotification}
      userData={userData}
    />
  );
}

export default NotificationsBarOnlyText;
