import React, { useContext, useEffect } from "react";
import "./UserManagement.scss";
import {
  Button,
  ButtonVariants,
  Error,
  Loading,
  TextAtom,
  TextVariants,
} from "../../../atoms";
import { InteriorContext } from "../../../templates/interior/components/InteriorContext";
import ModifyUserLevels from "./components/ModifyUserLevels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import UserCards from "./components/UserCards";
import AddUserToUserGroup from "./components/AddUserToUserGroup";
import UserInfo from "./components/UserInfo";
import AddNewUserGroup from "./components/AddNewUserGroup";

function ManageUserComponent(props) {
  const {
    error,
    userGroups,
    deleteUserGroup_loading,
    handleDeleteUserGroup,
    getUserGroup,
  } = props;
  const groups = userGroups?.data;
  const interiorContext = useContext(InteriorContext);

  useEffect(() => {
    // Closing drawer when user leaves component
    return () => interiorContext.closeDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditUserGroup = (name, permissions, groupID) => {
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(
      <ModifyUserLevels
        groupName={name}
        permissions={permissions}
        groupID={groupID}
       // siteId={siteId}
        getUserGroup={getUserGroup}
      />
    );
  };

  const handleAddUserToUserGroup = (name, groupID) => {
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(
      <AddUserToUserGroup
        groupName={name}
        groupID={groupID}
        getUseerGroup={getUserGroup}
      />
    );
  };

  const handleUserCardClicked = (user, groupID) => {
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(
      <UserInfo
        user={user}
        groupID={groupID}
        getUserGroup={getUserGroup}
      />
    );
  };

  const handleCreateUserGroup = () => {
    interiorContext.openDrawer();
    interiorContext.setDrawerComponent(
      <AddNewUserGroup getUserGroup={getUserGroup} />
    );
  };

  const renderGroups = () => {
    return (
      <div className="manage_access-container container">
        <div className="create-accessgroup-button-div">
          <Button
            className="create-accessgroup-button"
            onClick={() => handleCreateUserGroup()}
          >
            Create group
          </Button>
        </div>
        {groups?.map((userGroup) => {
          const { id, name, members, permissions } = userGroup;

         // console.log("Permissions: ", userGroup)

          return (
            <div key={id} className="accesslevel-container">
              <div className="accesslevel_heading-container">
                <div>
                  <TextAtom
                    variant={TextVariants.interiorPageSubHeading}
                    className="accesslevel-container__heading"
                  >
                    {name}
                  </TextAtom>
                </div>
                <div className="accessgroup-buttons">
                  <Button
                    variant={ButtonVariants.primaryOutlined}
                    className="accessgroup-button"
                    onClick={() =>
                      handleEditUserGroup(name, permissions, id)
                    }
                  >
                    View Or Modify
                  </Button>

                  <Button
                    variant={ButtonVariants.dangerOutlined}
                    onClick={() => handleDeleteUserGroup(id)}
                    loading={deleteUserGroup_loading}
                    className="accessgroup-button"
                  >
                    Delete
                  </Button>
                </div>
              </div>
              <div className="user_cards-container">
                <UserCards
                  handleUserCardClicked={(...args) =>
                    handleUserCardClicked(...args, id)
                  }
                  users={members}
                />
                <div
                  className="add-user"
                  onClick={() => handleAddUserToUserGroup(name, id)}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className="add-user-icon"
                  />
                  <TextAtom variant={TextVariants.small}>Add {name}</TextAtom>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleUserGroupData = () => {
    if (error) return <Error />;
    if (userGroups) return renderGroups();
    return <Loading />;
  };

  return handleUserGroupData();
}

export default ManageUserComponent;
