import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import React from "react";
import { UTILITY_STRINGS } from "../../../../../utils/constants";
import icon from "../../../../../assets/images/site-icon.svg";
import nodeIcon from "../../../../../assets/images/object-icon.svg";
import uIcon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";

let DefaultIcon = L.icon({
  iconUrl: icon,
  //shadowUrl: iconShadow,
});

let NodeIcon = L.icon({
  iconUrl: nodeIcon,
  //shadowUrl: iconShadow,
});
let UIcon = L.icon({
  iconUrl: uIcon,
  //shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;
function SiteMapWithMarker(props) {
  let { locationData, nodes, sites, ...rest } = props;

  const sanitizedLocationData = () => {
    if (Array.isArray(locationData)) {
      const filteredLocations = locationData.filter(
        (location) =>
          location.longLat[0] !== null || location.longLat[1] !== null
      );
      return filteredLocations.length
        ? filteredLocations
        : [{ name: UTILITY_STRINGS.incorrectLocationData, longLat: [0, 0] }];
    }

    // check for ditry location which is not an array
    if (locationData.longLat[0] === null || locationData.longLat[1] === null) {
      locationData.longLat = [0, 0];
      locationData.name = UTILITY_STRINGS.incorrectLocationData;
    }
    return locationData;
  };


  const renderMarkers = () => {
    // When multiple markers are provided.
    if (Array.isArray(locationData)) {
      return sanitizedLocationData().map((data, idx) => {
        if(data.name === "You"){
          return (
            <Marker icon={UIcon} key={`marker-${idx}`} position={data.longLat}>
              <Popup>{data.name}</Popup>
            </Marker>
          )
        } else {
           return (
              <Marker icon={DefaultIcon} key={`marker-${idx}`} position={data.longLat}>
                <Popup>{data.name}</Popup>
              </Marker>
            );
        }
      });
    }

    // // Single Marker
    // return (
    //   <Marker position={sanitizedLocationData().longLat}>
    //     <Popup>{sanitizedLocationData().name}</Popup>
    //   </Marker>
    // );
  };


  const composeCenterLocation = () => {
    // When multiple markers are provided.
    if (Array.isArray(locationData)) return sanitizedLocationData()[1].longLat;
    // Single Marker
    return sanitizedLocationData().longLat;
  };

  // if (Array.isArray(locationData))
  //   if(sanitizedLocationData()[0].longLat[0] === "" || sanitizedLocationData()[0].longLat[1] === "") return null;

  return (
    <MapContainer center={composeCenterLocation()} zoom={5} {...rest}>
      <TileLayer className="site-map"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {renderMarkers()}
    </MapContainer>
  );
}

export default SiteMapWithMarker;

SiteMapWithMarker.defaultProps = {
  locationData: { name: "White House", longLat: [38.8977, -77.0365] },
};
