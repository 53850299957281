import React from "react";
import "./LocationCard.scss";
import classnames from "classnames";
import House from "../../../assets/images/house.jpeg";
import { TextAtom, TextColors, TextVariants, TextWeights } from "../../atoms";
import { useHistory } from "react-router";
import { ROUTES } from "../../../utils/constants";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function LocationCard(props) {
  const { site, className, ...rest } = props;
  const { name, country, zipcode, city, address, id, ownedBy } = site;
  const userIDLoggedIn = useSelector((state) => state.user?.data?.userID);

  const history = useHistory();
  const classes = classnames(`location-card__molecule`, className);

  const redirectToLocationDetails = () => {
    history.push(ROUTES.siteDetails(id));
  };

  return (
    <div {...rest} className={classes} onClick={redirectToLocationDetails}>
      <img
        src={site.picture}
        alt={House}
        className="location-card__molecule-house-image"
      />
      <div className="location-card__molecule-text">
        <TextAtom
          className="location-card__molecule-name"
          variant={TextVariants.regular}
          weight={TextWeights.bold}
          color={TextColors.primaryGrey}
        >
          {name}
        </TextAtom>
        <TextAtom
          className="location-card__molecule-details"
          variant={TextVariants.small}
        >
          {address}
        </TextAtom>
        <TextAtom
          className="location-card__molecule-details"
          variant={TextVariants.small}
        >
          {city}
        </TextAtom>
        <TextAtom
          className="location-card__molecule-details"
          variant={TextVariants.small}
        >
          {country}
        </TextAtom>
        <TextAtom
          className="location-card__molecule-details"
          variant={TextVariants.small}
        >
          {zipcode}
        </TextAtom>
        {ownedBy === userIDLoggedIn ? (
          <TextAtom
            className="location-card__molecule-details"
            variant={TextVariants.small}
            style={{ fontWeight: "bold" }}
          >
            owner
          </TextAtom>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default LocationCard;

LocationCard.propTypes = {
  /**
   * The information that will be used to display the sitedetails of the card
   */
  site: PropTypes.object.isRequired,
};
