import React from "react";
import { TextAtom, TextVariants } from "../../atoms";
import "./SiteSummaryCard.scss";
import PropTypes from "prop-types";

function SiteSummaryCard(props) {
  const { siteDetail, ...rest } = props;

  return (
    <div className="summary-card" {...rest}>
      <TextAtom className="summary-element" variant={TextVariants.large}>
        {siteDetail.name}
      </TextAtom>
      <TextAtom className="summary-element" variant={TextVariants.regular}>
        {siteDetail.address}
      </TextAtom>
      {/* <TextAtom className="summary-element" variant={TextVariants.regular}>
        {siteDetail.city}
      </TextAtom>
      <TextAtom className="summary-element" variant={TextVariants.regular}>
        address: {siteDetail.address}
      </TextAtom> */}
    </div>
  );
}

export default SiteSummaryCard;

SiteSummaryCard.propTypes = {
  /**
   * The information that will be used to display the site
   */
  siteDetail: PropTypes.object.isRequired,
};
