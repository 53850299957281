import React, { useEffect, useState, useCallback } from "react";
import "./ObjectSlide.scss";
import House from "../../../assets/images/object-icon.svg";
import { TextAtom, TextVariants } from "../../atoms";
import PropTypes from "prop-types";

function ObjectSlideRender({ map, rest, name, lat, lon }) {
  const [position, setPosition] = useState(map.getCenter())
  const zoom = 18
  const centerr = [lat, lon]

  const redirectToLocationDetails = useCallback(() => {
    map.setView(centerr, zoom);
  }, [map]);

  const onMove = useCallback(() => {
    setPosition(map.getCenter());
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  return (
    <div
      {...rest}
      className="object-slide__molecule"
      onClick={redirectToLocationDetails}
    >
      <img
        src={House}
        className="object-slide__molecule-house-image"
        alt={"House"}
      />
      <div className="object-slide__molecule-text">
        <TextAtom
          className="object-slide__molecule-name"
          variant={TextVariants.regular}
        >
          {name}
        </TextAtom>
      </div>
    </div>
  );
}

function ObjectSlide(props) {
  const { node, className, map, ...rest } = props;
  const { name, latitude, longitude, latestLocation } = node;

  // if(node.type !== "object")
  //   return null

  return (
    <div>
      {map ? (
        <ObjectSlideRender
          map={map}
          rest={rest}
          name={node.device.name}
          lat={latestLocation.lat}
          lon={latestLocation.long}
        />
      ) : null}
    </div>
  );
}

export default ObjectSlide;

ObjectSlide.propTypes = {
  /**
   * The information that will be used to display
   */
  node: PropTypes.object.isRequired,
};