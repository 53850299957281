import { useSelector } from "react-redux";
import DisplayIndoorComponent from "./component";
import React from "react";

function DisplayIndoor(props) {
  let { updateSiteID, getSiteID } = props;
  const sites = useSelector((state) => state.sites);
  const nodes = useSelector((state) => state.nodes);
  
  return <DisplayIndoorComponent nodes={nodes} sites={sites} getSiteID={getSiteID} updateSiteID={updateSiteID}/>;
}

export default DisplayIndoor;
