import React, { useEffect, useState } from "react";
import SignInComponent from "./component";
import { signIn } from "../../../redux/user/actions";
import "./SignIn.scss";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { ROUTES } from "../../../utils/constants";

function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [alexaParams, setAlexaParams] = useState({});
  const [alexaRedirectFlag, setAlexaRedirectFlag] = useState(false);
  const [alexaRedirectUri, setAlexaRedirectUri] = useState("");

  let urlParams = useLocation().search;

  useEffect(() => {
    if (urlParams !== ("" || undefined)) {
      const client_id = new URLSearchParams(window.location.search).get(
        "client_id"
      );
      const response_type = new URLSearchParams(window.location.search).get(
        "response_type"
      );
      const state = new URLSearchParams(window.location.search).get("state");
      const scope = new URLSearchParams(window.location.search).get("scope");
      const redirect_uri = new URLSearchParams(window.location.search).get(
        "redirect_uri"
      );
      //console.log(client_id);
      setAlexaParams({
        client_id,
        response_type,
        state,
        scope,
        redirect_uri,
      });
    }
    if (alexaRedirectFlag) window.location.href = alexaRedirectUri;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alexaRedirectFlag]);

  //console.log(alexaParams);

  const handleSignIn = (data) => {
    const { username, password } = data;
    dispatch(
      signIn(username, password, alexaParams, (data) => {
        if (data.redirect_uri == null || undefined){
          // localStorage.setItem('devices', JSON.stringify(data.devices));
          history.push({ pathname: ROUTES.mapWithCurrentLocation});
        }else {
          setAlexaRedirectUri(data.redirect_uri);
          setAlexaRedirectFlag(true);
        }
      })
    );
  };

  return <SignInComponent handleSignIn={handleSignIn} />;
}

export default SignIn;
