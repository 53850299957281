import React, { useContext, useEffect, useState } from "react";
import {
  composeDateUsingTimestamp,
  DATETIME_FORMAT,
  notify,
} from "../../../../../../../utils";
import { API_ENDPOINTS } from "../../../../../../../utils/constants";
import { useFetch } from "../../../../../../../utils/hooks";
import {
  Button,
  ButtonVariants,
  Input,
  InputVariants,
  Loading,
} from "../../../../../../atoms";
import { Detail } from "../../../../../../molecules";
import Drawer from "../../../../../../templates/interior/components/Drawer";
import { InteriorContext } from "../../../../../../templates/interior/components/InteriorContext";
import { isDeleteDeviceAllowed, isEditDeviceAllowed } from "../../utils";

function SiteDeviceInfo(props) {
  const { siteDeviceData, get, siteDetail } = props;
  //console.log("sitedeviceData", siteDeviceData)
  const {
    type,
    name,
    discoveredAt,
    lastUpdatedAt,
    lastRebooted,
    macAddress,
    ownedBy,
    longitude,
    latitude,
    bleFirmwareVersion,
    nearestNodeName,
    nearestNodeID,
    roomName,
    roomID,
    floorName,
    floorID,
    distance,
    claimed,
    nodeID,
    currentSiteID,
  } = siteDeviceData;
  const interiorContext = useContext(InteriorContext);
  const [editSiteDeviceNameflag, setEditSiteDeviceNameflag] = useState(false);
  const [siteDeviceName, setSiteDeviceName] = useState(name);
  const [ownerInfo, setOwnerInfo] = useState({});

  const { patch, loading } = useFetch();
  const { get: getUserInfo } = useFetch();
  const {
    del: deleteSiteDevice,
    loading: deleteSiteDevice_loading,
  } = useFetch();

  useEffect(() => {
    setSiteDeviceName(name);
    setEditSiteDeviceNameflag(false);
  }, [name]);
  useEffect(() => {
    getOwnerInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getOwnerInfo = () => {
    getUserInfo(API_ENDPOINTS.userInfo(ownedBy))
      .then((response) => {
        if (response.status === "success") setOwnerInfo(response.data[0]);
        //console.log(response?.data);
      })
      .catch((error) => console.log(error));
  };
  const updateSiteDeviceName = () => {
    patch(API_ENDPOINTS.siteDeviceName(currentSiteID, nodeID), {
      name: siteDeviceName,
    })
      .then((res) => {
        if (res.status === "success") {
          notify.success("Device name updated successfully", {
            toastId: "DeviceNameUpdateSuccess",
          });
          get(API_ENDPOINTS.siteDevices(currentSiteID));
          setEditSiteDeviceNameflag(false);
        } else if (res.status === "error") {
          notify.error(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };
  const handleOnChangeSiteDeviceNameedit = (e) => {
    setSiteDeviceName(e.target.value);
  };

  const handleCancelSiteDeviceNameedit = () => {
    setEditSiteDeviceNameflag(false);
    setSiteDeviceName(name);
  };
  const handleEditSiteDeviceNameButtonClicked = () => {
    return (
      <div className="edit-site-device-name-container">
        <Button
          className="edit-site-device-name-button"
          variant={
            siteDeviceName.trim()
              ? ButtonVariants.success
              : ButtonVariants.disabled
          }
          onClick={() => updateSiteDeviceName()}
          loading={loading}
        >
          Update
        </Button>
        <Button
          variant={ButtonVariants.dangerOutlined}
          type="button"
          onClick={() => handleCancelSiteDeviceNameedit()}
        >
          Cancel
        </Button>
        <Input
          name="Device name"
          variant={InputVariants.text}
          value={siteDeviceName}
          onChange={handleOnChangeSiteDeviceNameedit}
          label="edit Device name"
        />
      </div>
    );
  };

  const handleDeleteSiteDevice = () => {
    deleteSiteDevice(API_ENDPOINTS.siteDevice(currentSiteID, nodeID))
      .then((res) => {
        if (res.status === "success") {
          notify.success("Device deleted successfully", {
            toastId: "DeviceDeleteSuccess",
          });
          get(API_ENDPOINTS.siteDevices(currentSiteID));
          interiorContext.closeDrawer();
        } else if (res.status === "error") {
          notify.error(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };
  if (deleteSiteDevice_loading) {
    return <Loading />;
  }
  return (
    <>
      <Drawer.Title heading={siteDeviceName?.toUpperCase()}>
        {isEditDeviceAllowed(siteDetail?.permissions) ? (
          <Button
            variant={ButtonVariants.success}
            onClick={() => setEditSiteDeviceNameflag(true)}
          >
            Edit Device name
          </Button>
        ) : (
          ""
        )}
      </Drawer.Title>

      <Drawer.Description>
        Information about - {siteDeviceName}
      </Drawer.Description>
      {editSiteDeviceNameflag && handleEditSiteDeviceNameButtonClicked()}
      {type && <Detail label="Type:" value={type} />}

      {discoveredAt && (
        <Detail
          label="Discovered at:"
          value={composeDateUsingTimestamp(
            discoveredAt,
            DATETIME_FORMAT.dateAndTime
          )}
        />
      )}

      {lastUpdatedAt && (
        <Detail
          label="Last Updated:"
          value={composeDateUsingTimestamp(
            lastUpdatedAt,
            DATETIME_FORMAT.dateAndTime
          )}
        />
      )}
      {lastRebooted && (
        <Detail
          label="Last Rebooted:"
          value={composeDateUsingTimestamp(
            lastRebooted,
            DATETIME_FORMAT.dateAndTime
          )}
        />
      )}
      {macAddress && <Detail label="Mac Address:" value={macAddress} />}
      {ownedBy && (
        <Detail
          label="Owned By:"
          value={`${ownerInfo.firstName} ${ownerInfo.lastName} (@${ownerInfo.username})`}
        />
      )}
      {longitude && latitude !== null ? (
        <Detail label="Long/Lat:" value={`${latitude}/${longitude}`} />
      ) : null}
      <Detail label="Claimed:" value={claimed === true ? "true" : "false"} />
      {bleFirmwareVersion && (
        <Detail label="BLE Firmware Version:" value={bleFirmwareVersion} />
      )}
      {nearestNodeName && (
        <Detail label="Nearest node:" value={nearestNodeName} />
      )}
      {nearestNodeID && (
        <Detail label="Nearest node ID:" value={nearestNodeID} />
      )}
      {roomName && <Detail label="Room name:" value={roomName} />}
      {roomID && <Detail label="Room ID:" value={roomID} />}
      {floorName && <Detail label="Floor name:" value={floorName} />}
      {floorID && <Detail label="Floor ID:" value={floorID} />}
      {distance ? <Detail label="Distance:" value={distance} /> : null}
      {isDeleteDeviceAllowed(siteDetail?.permissions) ? (
        <Detail
          label="Delete"
          value={
            <Button
              variant={ButtonVariants.dangerOutlined}
              onClick={() => handleDeleteSiteDevice()}
            >
              Delete
            </Button>
          }
        />
      ) : (
        ""
      )}
    </>
  );
}

export default SiteDeviceInfo;
