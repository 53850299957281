import React from "react";
import { ROUTES } from "../../../utils/constants";
import { Link, Page, TextAtom, TextVariants } from "../../atoms";
import logo from "../../../assets/icons/logo.svg";
import "./OnRegisterSuccessMessageBlock.scss";

function OnRegisterSuccessMessageBlock() {
  return (
    <Page title="Sign Up" description="Register for Inlo">
      <div className="sign-up__page">
        <div className="left-area">
          <div className="sign-up-option">
            <TextAtom variant={TextVariants.regular}>
              Already have an account?.
              <Link to={ROUTES.signIn}> Sign In. </Link>
            </TextAtom>
          </div>
          <div className="logo">
            <img src={logo} alt="Inlo Logo" />
          </div>
          <div className="register-success">
            <TextAtom
              variant={TextVariants.pageHeading}
              className="title message-text-register-success"
            >
              The account has been created successfully.
            </TextAtom>
            <TextAtom
              variant={TextVariants.pageHeading}
              className="title message-text-register-success"
            >
              Please verify the email
            </TextAtom>
          </div>
        </div>
        <div className="right-area">
          <TextAtom variant={TextVariants.pageHeading}>
            Effortless organization and automation for your life.
          </TextAtom>
        </div>
      </div>
    </Page>
  );
}

export default OnRegisterSuccessMessageBlock;
