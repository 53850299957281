import React from "react";
import { TextAtom, TextVariants } from "../../atoms";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./InteriorPageHeadingWithCTA.scss";

function InteriorPageHeadingWithCTA(props) {
  const { heading, className, children, ...rest } = props;
  const classes = classnames(
    `interior-page-heading-with-cta__molecule`,
    className
  );
  return (
    <div className={classes} {...rest}>
      <TextAtom variant={TextVariants.headingOne}>{heading}</TextAtom>
      <span>{children}</span>
    </div>
  );
}

export default InteriorPageHeadingWithCTA;

InteriorPageHeadingWithCTA.propTypes = {
  /**
   * The Page heading
   */
  heading: PropTypes.string,
};

InteriorPageHeadingWithCTA.defaultProps = {
  heading: "Page Heading",
};
