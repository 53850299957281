import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  TextAtom,
  TextColors,
  TextSizes,
  TextVariants,
  TextWeights,
} from "../../../atoms";
import { InteriorPageHeadingWithCTA } from "../../../molecules";
import { InteriorContext } from "./InteriorContext";

function Drawer() {
  const interiorContext = useContext(InteriorContext);
  const { closeDrawer, drawerOpen, drawerComponent } = interiorContext;
  return (
    drawerOpen && (
      <div className="interior-template__page-drawer ">
        <FontAwesomeIcon
          icon={faTimes}
          className="close-icon"
          onClick={() => closeDrawer()}
        />
        {drawerComponent}
      </div>
    )
  );
}

Drawer.Title = ({ heading, children }) => (
  <InteriorPageHeadingWithCTA
    variant={TextVariants.interiorPageHeading}
    size={TextSizes.l}
    weight={TextWeights.regular}
    color={TextColors.primaryGrey}
    className="interior-template__page-drawer-title"
    heading={heading}
  >
    {children}
  </InteriorPageHeadingWithCTA>
);
Drawer.Description = ({ children }) => (
  <TextAtom
    className="interior-template__page-drawer-description"
    weight={TextWeights.light}
  >
    {children}
  </TextAtom>
);

export default Drawer;
