import React, { useEffect, useState } from "react";
import { notify } from "../../../../../utils";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import { useFetch } from "../../../../../utils/hooks";
import {
  Button,
  ButtonVariants,
  TextAtom,
  TextSizes,
} from "../../../../atoms";
import { Input, InputVariants } from "../../../../atoms/input/Input";
import Drawer from "../../../../templates/interior/components/Drawer";

const TITLES = {
  devices: "Devices",
  location: "location",
  message: "Message",
  user: "User"
};

var permissionss = {
  user:{
    devices: false,
    location: false,
    message: false
  }
};

function ModifyUserLevels(props) {
  const { permissions, groupName, groupID, getUserGroup } = props;
  const [permissionState, setPermissionState] = useState(permissions);
  const [permissionsModified, setPermissionsModified] = useState(false);
  const [editGroupNameflag, setEditGroupNameflag] = useState(false);
  const [userGroupName, setUserGroupName] = useState(groupName);

  //console.log("groupID:", groupID)
  permissionss.user.devices = permissions.devices;
  permissionss.user.location = permissions.location;
  permissionss.user.message = permissions.message;

 // console.log("permissionsssssss:  ", permissionss);

  useEffect(() => {
    setPermissionState(permissionss);
    setPermissionsModified(false);
    setUserGroupName(groupName);
    setEditGroupNameflag(false);
  }, [permissionss, groupName]);

  const { put, loading } = useFetch();

  // const handleSaveButtonClicked = () => {
  //   put(API_ENDPOINTS.userGroup(groupID), {
  //     permissions: permissionState,
  //   })
  //     .then((res) => {
  //       if (res.status === "success") {
  //         notify.success("User Permissions saved successfully", {
  //           toastId: "UserPermissionsSuccess",
  //         });
  //         getUserGroup(API_ENDPOINTS.userGroupsData());
  //       } else if (res.status === "error") {
  //         notify.error(res.error.message);
  //       }
  //     })
  //     .catch((error) => notify.error(error.error.message));
  // };

  // const handlePermissionChange = (e, permissionOf, permissionLevel) => {
  //   console.log("changed:",permissionOf, "  ",permissionLevel )
  //   // setPermissionState({
  //   //   ...permissionState,
  //   //   [permissionOf]: {
  //   //     ...permissionState[permissionOf],
  //   //     [permissionLevel]: e.target.checked,
  //   //   },
  //   // });
  //   // if (permissionsModified === false) setPermissionsModified(true);
  // };

  const handleSaveButtonClicked = () => {
    console.log("permission State:", permissionState["user"])
    //console.log("permission State:", groupID)
    put(API_ENDPOINTS.userGroup(groupID), {
      name: userGroupName,
      permissions: permissionState["user"],
    })
      .then((res) => {
        if (res.success) {
          notify.success("User Group created successfully", {
            toastId: "UserGroupCreationSuccess",
          });
          getUserGroup(API_ENDPOINTS.userGroupsData());
        } else if (!res.success) {
          notify.error(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };


  const handlePermissionChange = (e, permissionOf, permissionLevel) => {
    setPermissionState({
      ...permissionState,
      [permissionOf]: {
        ...permissionState[permissionOf],
        [permissionLevel]: e.target.checked,
      },
    });
  };



  const permissionLevels = (permissionOf, permissionOfName) => {
    const keyValue = [];
    //console.log("permission of:", permissionOf)
    for (let permissionLevel in permissionOf) {
      //console.log("permission level:", permissionState["user"][permissionLevel])
      keyValue.push(
        <div
          key={`${TITLES[permissionLevel]}${permissionOf}`}
          className="permission-levels"
        >
          <Input
            variant={InputVariants.checkBox}
            label={TITLES[permissionLevel]}
            onChange={(e) =>
              handlePermissionChange(e, permissionOfName, permissionLevel)
            }
            checked={permissionState["user"][permissionLevel]}
          />
        </div>
      );
    }
    return keyValue;
  };
  // const permissionLevels = (permissionOf, permissionOfName) => {
  //   const keyValue = [];
  //   console.log("permission of:", permissionOf)
  //   for (let permissionLevel in permissionOf) {
  //     console.log("permission level:", permissionLevel)
  //     keyValue.push(
  //       <div
  //         key={`${TITLES[permissionLevel]}${permissionOf}`}
  //         className="permission-levels"
  //       >
  //         <Input
  //           variant={InputVariants.checkBox}
  //           label={TITLES[permissionLevel]}
  //           onChange={(e) =>
  //             handlePermissionChange(e, permissionOfName, permissionLevel)
  //           }
  //           checked={permissionState[permissionLevel]}
  //         />
  //       </div>
  //     );
  //   }
  //   return keyValue;
  // };

  // const permissionsPrint = () => {
  //   const permissionsCollection = [];
  //   console.log("permission state:", permissionState)
  //  // for (let permissionOf in permissionState) {
  //     permissionsCollection.push(
  //       <div key={"User"} className="permission-of">
  //         <TextAtom size={TextSizes.l} className="permission-of-name">
  //           {TITLES["user"]}
  //         </TextAtom>
  //         <div className="permission-levels-group">
  //           {permissionLevels(permissionState, "user")}
  //         </div>
  //       </div>
  //     );
  //  // }
  //   return permissionsCollection;
  // };

  const permissionsPrint = () => {
    const permissionsCollection = [];
    for (let permissionOf in permissionState) {
      permissionsCollection.push(
        <div key={permissionOf} className="permission-of">
          <TextAtom size={TextSizes.l} className="permission-of-name">
            {TITLES[permissionOf]}
          </TextAtom>
          <div className="permission-levels-group">
            {permissionLevels(permissionState[permissionOf], permissionOf)}
          </div>
        </div>
      );
    }
    return permissionsCollection;
  };

  const updateUserGroupName = () => {
    put(API_ENDPOINTS.userGroup(groupID), {
      name: userGroupName,
    })
      .then((res) => {
        if (res.success) {
          notify.success("Group name updated successfully", {
            toastId: "AccessGroupNameUpdateSuccess",
          });
          getUserGroup(API_ENDPOINTS.userGroupsData());
          setEditGroupNameflag(false);
        } else if (!res.success) {
          notify.error(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };

  const handleOnChangeUserGroupNameedit = (e) => {
    setUserGroupName(e.target.value);
  };
  const handleCancelButtonClickedUserGroupNameedit = () => {
    setUserGroupName(groupName);
    setEditGroupNameflag(false);
  };

  const handleEditGroupNameButtonClicked = () => {
    return (
      <div className="edit-group-name-container">
        <div>
          <Button
            className="edit_group-update-button"
            variant={
              userGroupName.trim()
                ? ButtonVariants.success
                : ButtonVariants.disabled
            }
            onClick={() => updateUserGroupName()}
          >
            Update
          </Button>
          <Button
            variant={ButtonVariants.dangerOutlined}
            type="button"
            onClick={() => handleCancelButtonClickedUserGroupNameedit()}
          >
            Cancel
          </Button>
          <Input
            name="group name"
            variant={InputVariants.text}
            value={userGroupName}
            onChange={handleOnChangeUserGroupNameedit}
            label="edit group name"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Drawer.Title heading={`${userGroupName} - Permissions`}>
        <Button onClick={() => setEditGroupNameflag(true)}>
          Edit group name
        </Button>
      </Drawer.Title>

      <Drawer.Description>
        View and manage permissions you have shared with {userGroupName}
      </Drawer.Description>
      {editGroupNameflag && handleEditGroupNameButtonClicked()}
      {permissionsPrint()}
      {<Button
          variant={ButtonVariants.success}
          onClick={handleSaveButtonClicked}
          loading={loading}
        >
          Save Changes
        </Button>}
    </>
  );
}

export default ModifyUserLevels;
