import React from "react";
import { Button, ButtonVariants, Input, InputVariants } from "../../../../atoms";
import Joi from "@hapi/joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers";
import { NOTIFICATION_MESSAGES, notify } from "../../../../../utils";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import { useDispatch } from "react-redux";
import { userDetails } from "../../../../../redux/user/actions";
import { useFetch } from "../../../../../utils/hooks/useFetch";

const schema = Joi.object({
  firstName: Joi.string().allow("").optional(),
  lastName: Joi.string().allow("").optional(),
}).min(1);

function EditName(props) {
  const { exitEditMode } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });

  const { put, patch, response, error, loading } = useFetch();

  if (response?.success) {
    dispatch(userDetails());
    notify.success(NOTIFICATION_MESSAGES.nameUpdate);
  }else
  if (!(error?.success)) {
    notify.error(error);
  }

  const updateName = async (firstName, lastName) => {
    const reqBody = {};
    if (firstName) reqBody.firstName = firstName;
    if (lastName) reqBody.lastName = lastName;
    put(API_ENDPOINTS.userDetails, reqBody);
  };

  const onSubmit = (data) => {
    updateName(data.firstName, data.lastName);
  };

  return (
    <div className="edit-email">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          variant={InputVariants.text}
          label="First Name"
          name="firstName"
          error={errors.firstName?.message}
          ref={register}
        />
        <Input
          variant={InputVariants.text}
          label="Last Name"
          name="lastName"
          error={errors.lastName?.message}
          ref={register}
        />
        <Button
          variant={ButtonVariants.success}
          type="submit"
          loading={loading}
        >
          Save
        </Button>
        <Button
          variant={ButtonVariants.dangerOutlined}
          type="button"
          onClick={exitEditMode}
        >
          Cancel
        </Button>
      </form>
    </div>
  );
}

export default EditName;
