import React from "react";
import {  MsgSlide, MsgSlideActive } from "../../../molecules";
import {
  Error,
  Loading,
} from "../../../atoms";
import "../../../molecules/msg-slide/MsgSlide.scss";

function SideMsgDesktop(props ) {
  const { msgProfile, msgConvo, error, loading,  getUserGroup, userGroup, ...rest } = props;


  const handleOutdoorData = () => {
    if (error) return <Error />;
    if (userGroup) return RenderMsgData();
    return <Loading />;
  };


  function RenderMsgData() {
    const msgProfiles = msgProfile?.data?.results;
    const msgConvos = userGroup?.data;


    // msgProfiles?.map((msg) => (

    // ));
    // if(msgProfiles?.length > 0){
    //   if(msgProfiles?.length === 1){
    //   } else {
    //     var c = [...msgProfiles?.[0].users, ...msgProfiles?.[1].users]
      
    //     for(let i = 2; i<msgProfiles?.length; i++ ){
    //       if(msgProfiles?.[i].permissions.message === true)
    //         c = [...c, ...msgProfiles?.[i].users]
    //     }
    //   }
    // }

    //  console.log("render msg:  ", msgProfiles)
  
    const MsgProfileCards = () => {
      if (msgProfiles?.length !== 0){
           return msgProfiles?.map((msg) => (
            <MsgSlide userID={msg.id} getUserGroup={getUserGroup} avatar={msg.user.profilePicture} msgProfile={msgProfile} msgName={msg.user.username} className="side-msg-desktop__organism-mag-menu" />
          ));
      }else return;

    };

    const MsgConvoCards = () => {
      // console.log("msgConvos:  ", msgConvos)
        if (msgConvos?.length !== 0){
            return msgConvos?.map((msg) => (
               <MsgSlideActive msg={msg} c={msgProfiles} className="side-msg-desktop__organism-mag-menu" />
            ));
        }else return;

    };

    return (
      <div className="msg-slide">
          {MsgProfileCards()}
          <div className="msg-slide-border"></div>
          {MsgConvoCards()}
      </div>
      );
  };
  
  return (
    <div className="side-msg-desktop__organism">
      {/*RenderMsgData()*/}
      {handleOutdoorData()}
    </div>
  );
}



export default SideMsgDesktop;
