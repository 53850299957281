import React from "react";
import classnames from "classnames";
import { TextAtom, TextColors, TextVariants, TextWeights } from "../../atoms";
import "./SiteDeviceCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBroadcastTower,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { DATETIME_FORMAT, composeDateUsingTimestamp } from "../../../utils";

function SiteDeviceCard(props) {
  const { type, name, discoveredAt, lastUpdatedAt, ...rest } = props;

  const classes = classnames("site_device_card");

  const renderIconBasedOnDeviceType = () => {

    switch (type) {
      case "virtual_satellite":
        return (
          <FontAwesomeIcon
            icon={faBroadcastTower}
            className="site-device-card_icon station"
          />
        );
      case "base_station":
        return (
          <FontAwesomeIcon
            icon={faBroadcastTower}
            className="site-device-card_icon station"
          />
        );
      case "object":
        return (
          <FontAwesomeIcon icon={faMobileAlt} className="site-device-card_icon object" />
        );
      default: 
      return (
        <FontAwesomeIcon
          icon={faBroadcastTower}
          className="site-device-card_icon"
        />
      );
    } 
  };

  return (
    <div className={classes} {...rest}>
      {renderIconBasedOnDeviceType()}
      <div className="site-device-card_display-texts">
        <TextAtom
          className="site-device-card__molecule-text-element_name"
          variant={TextVariants.small}
          weight={TextWeights.bold}
          color={TextColors.primaryGrey}
        >
          {type?.toUpperCase()}
        </TextAtom>
        <TextAtom
          className="site-device-card__molecule-text-element_name"
          variant={TextVariants.small}
          weight={TextWeights.bold}
          color={TextColors.primaryGrey}
        >
          Name: {name?.toUpperCase()}
        </TextAtom>
        <TextAtom
          className="site-device-card__molecule-text-element"
          variant={TextVariants.small}
          weight={TextWeights.semiBold}
          color={TextColors.primaryGrey}
        >
          Discovered at :{" "}
          {composeDateUsingTimestamp(discoveredAt, DATETIME_FORMAT.dateAndTime)}
        </TextAtom>
        <TextAtom
          className="site-device-card__molecule-text-element"
          variant={TextVariants.small}
          weight={TextWeights.semiBold}
          color={TextColors.primaryGrey}
        >
          Last Updated :{" "}
          {composeDateUsingTimestamp(
            lastUpdatedAt,
            DATETIME_FORMAT.dateAndTime
          )}
        </TextAtom>
      </div>
    </div>
  );
}

export default SiteDeviceCard;
