import { useWindowWidth } from "@react-hook/window-size";
import { REQ_STATE } from "../../../../../../../../utils";
import { ROUTES } from "../../../../../../../../utils/constants";
import { SCREEN_SIZE } from "../../../../../../../../utils/layout";
import {
  Error,
  Link,
  Loading,
  SiteMap,
  TextAtom,
  TextVariants,
  MapVariants,
} from "../../../../../../../atoms";
import { ObjectSlide, SiteList } from "../../../../../../../molecules";
import "./DisplaySiteOutdoor.scss";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useGeoLocation } from "../../../../../../../../utils/hooks";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEvents } from "../../../../../../../../utils/hooks/useEvents";
import { useEventChat } from "../../../../../../../../utils/hooks/useEventChat";

const zoom = 18

const markerIcon = new L.Icon({
  iconUrl: require("../../../../../../../../assets/images/marker.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

function RenderOutdoorData({ map, nodes }) {
  const nodeLocations = nodes.data.results;
  const userIDLoggedIn = "666";

    const NodeLocationCardsData = nodeLocations.filter(
      (node) => node.ownedBy !== userIDLoggedIn
    );

    const NodeLocationCards = () => {
      if (NodeLocationCardsData?.length !== 0){
        return NodeLocationCardsData.map((node) => (
          <ObjectSlide key={node.nodeID} node={node} map={map} className="card" />
        ));
      }else return;
    };

    return (
      <>
        {NodeLocationCards()}
      </>
    );
  };

  const noSites = () => {
    return (
      <TextAtom variant={TextVariants.regular}>
        Looks like you havent added sites yet! Feel free to{" "}
        <Link to={ROUTES.createSite}> add a site</Link>
      </TextAtom>
    );
  };

  function RenderLocationCards(props) {
    const { map, sites, userid, updateSiteID} = props

    if (sites.data.count === 0 && sites.data.count === 0) return noSites();
    const siteLocations = sites.data.results;

    const ownerSiteLocationCardsData = siteLocations.filter(
      (site) => site.ownedBy === userid
    );
    const notOwnedSiteLocationCardsData = siteLocations.filter(
      (site) => site.ownedBy !== userid
    );
    
    const ownerSiteLocationCards = () => {
      if (ownerSiteLocationCardsData?.length !== 0)
        return ownerSiteLocationCardsData.map((site) => (
          <SiteList key={site.siteID} site={site} image={site.image} map={map} updateSiteID={updateSiteID} className="card" />
        ));
      else return;
    };
    const notOwnedSiteLocationCards = () => {
      if (notOwnedSiteLocationCardsData?.length !== 0)
        return notOwnedSiteLocationCardsData.map((site) => (
          <SiteList key={site.siteID} site={site} image={site.image} map={map} updateSiteID={updateSiteID} className="card" />
        ));
      else return;
    };


  return (
    <>
      {ownerSiteLocationCards()}
      {notOwnedSiteLocationCards()}
    </>
  );
}

function DisplaySiteOutdoorComponent(props) {
  const { sites, nodes, updateSiteID, getSiteID } = props;
  const width = useWindowWidth();
  const userIDLoggedIn = useSelector((state) => state.user?.data?.userID);
  const location = useGeoLocation();
  const [position, setPosition] = useState([85, 85]);
  const [map, setMap] = useState(null);
  const locations = [];


  // Websocket Stuff!!
  useEventChat();

  const handleOutdoorData = () => {
    // eslint-disable-next-line default-case
    switch (sites.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return renderData();
    }
  };

  const extractSiteData = (results) => {
    results.forEach((location) => {
      const { name, longitude, latitude, siteID } = location;
      const locationData = {
        name,
        longLat: [latitude, longitude],
      };
      locations.push(locationData);
      if(getSiteID() === "")
        updateSiteID(siteID)
    });
    return locations;
  };

   // instance of websocket connection as a class property
  //  const ws = new WebSocket('ws://localhost:3000/ws')

  //  const componentDidMount = () => {
  //      this.ws.onopen = () => {
  //      // on connecting, do nothing but log it to the console
  //      console.log('connected')
  //      }
  //      this.ws.onmessage = evt => {
  //      // listen to data sent from the websocket server
  //      const message = JSON.parse(evt.data)
  //      this.setState({dataFromServer: message})
  //      console.log(message)
  //      }
  //      this.ws.onclose = () => {
  //      console.log('disconnected')
  //      // automatically try to reconnect on connection loss
  //      }
  //  }
  //  render(){
  //      <ChildComponent websocket={this.ws} />
  //  }

  const renderData = () => {

    if (sites.data.count === 0 && sites.data.count === 0) return noSites();

    const viewPosition = {
      name: "You",
      longLat: [location.coordinates.lat, location.coordinates.lng],
    };
      locations.push(viewPosition);

    if(sites.state === "loaded")
      extractSiteData(sites.data.results)
    else
      return null

    if(nodes.state !== "loaded")
      return null

    return (
      <>
        {/* <div className="object-slide-container">
          <div className="object-slide">
            {map ? <RenderOutdoorData 
                    map={map} 
                    nodes = {nodes}/> : null}
          </div>
        </div> */}
        {/* <div className="site-list-container">
          <div className="site-list" >
            {map ? <RenderLocationCards 
                    map={map} 
                    sites = {sites} 
                    userid = {userIDLoggedIn}
                    updateSiteID={updateSiteID}/> : null}
            </div>
        </div> */}
        <SiteMap
          zoom={zoom}
          whenCreated={setMap}
          variant={MapVariants.withMaker}
          className="map"
          locations
          locationData={locations}
          nodes={nodes}
          sites={sites}
          scrollWheelZoom={SCREEN_SIZE.desktop(width)}>
        </SiteMap>
      </>
    );
  }; 
  
  useEffect(() => {
    if (location.coordinates === undefined) 
      return null;
    else if (location.coordinates.lat !== "" && location.coordinates.lng !== "" ) 
      setPosition([location.coordinates.lat, location.coordinates.lng]);
    else 
      return null;
  }, [location]);

  return handleOutdoorData();
}

export default DisplaySiteOutdoorComponent;
