import React, { useEffect } from "react";
import { useParams } from "react-router";
import { notify } from "../../../../../../utils";
import { API_ENDPOINTS } from "../../../../../../utils/constants";
import { useFetch } from "../../../../../../utils/hooks/useFetch";
import ManageAccessComponent from "./component";
import { getMembers } from "../../../../../../redux/myGroup/actions";
import { useDispatch, useSelector } from "react-redux";

function ManageAccess(props) {
  const { siteId } = useParams();
  const {
    siteDetail
  } = props;
  const { loading, error, response, get: getAccessGroup } = useFetch();
  const {
    loading: deleteAccessGroup_loading,
    del: deleteAccessGroup,
  } = useFetch();
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(getMembers());
    // getAccessGroup(API_ENDPOINTS.accessGroupsData(siteId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const members = useSelector((state) => state.members);
  const geoFences = useSelector((state) => state.geoFences);
  const geoFenceData = geoFences.data?.find((data) => data.site.id === siteId);

  console.log("siteDetail:  ", siteDetail)

  const handleDeleteAccessGroup = async (groupId) => {
    // try {
    //   const res = await deleteAccessGroup(
    //     API_ENDPOINTS.accessGroup(siteId, groupId)
    //   );
    //   if (res.status === "success") {
    //     notify.success("Group deleted successfully", {
    //       toastId: "groupDeleted",
    //     });
    //     getAccessGroup(API_ENDPOINTS.accessGroupsData(siteId));
    //   }
    //   if (res.status === "error")
    //     notify.error(res.error.message, {
    //       toastId: "groupDeletedFail",
    //     });
    // } catch (error) {
    //   notify.error(error.error.message, {
    //     toastId: "groupDeletedFail",
    //   });
    // }
  };

 

  return (
    <ManageAccessComponent
    siteDetail={siteDetail}
    geoFenceData={geoFenceData}
      members={members}
      siteId={siteId}
      accessGroups={response}
      loading={loading}
      error={error}
      deleteAccessGroup_loading={deleteAccessGroup_loading}
      handleDeleteAccessGroup={handleDeleteAccessGroup}
      getAccessGroup={getAccessGroup}
    />
  );
}

export default ManageAccess;
