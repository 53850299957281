import { useState } from "react";
import { makeRequest } from "../rest";

export const useFetch = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const callAPI = async (reqType, url, body, options) => {
    setLoading(true);
    setError(null);
    setResponse(null);
    try {
      const res = await makeRequest[reqType](url, body, options);
      if (res.success) {
        //console.log("ressss: setResponse  ", res)
        setResponse(res);
      } else {
        setError(res);
      }
      //console.log("ressss:   ", res)
      return res;
    } catch (err) {
      //console.log("errrr:   ", err)
      setError(err);
      return err;
    } finally {
      setLoading(false);
    }
  };

  const patch = (...args) => callAPI("patch", ...args);
  const get = (...args) => callAPI("get", ...args);
  const post = (...args) => callAPI("post", ...args);
  const del = (...args) => callAPI("delete", ...args);
  const put = (...args) => callAPI("put", ...args);

  return { patch, get, put, post, del, response, error, loading };
};
