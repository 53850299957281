import { STATUS_CODE } from "./statusCodes.js";

const requestForm = async (method, url, formData, options) => {
  const token = localStorage.getItem("token");

  const reqOptions = {
    headers: {
     //  'Content-Type': 'multipart/form-data'
    },
    json: true,
    method,
    ...options,
  };
  
  if (formData) reqOptions.body = formData;
  if (token) reqOptions.headers.Authorization = `Bearer ${token}`;

  try {
    const response = await fetch(url, reqOptions);
    const data = await response.json();

    // if you notice a 401. check for refresh token and make a call. 

    
   // if(data.code === STATUS_CODE.unauthorized){
      console.log("rest data output  :", data )
   // }

    return data;
  } catch (err) {

    console.log("rest error output  :", err )
    throw new Error(err.json);
  }
};

export const makeRequestForm = {
  post: (...args) => requestForm("POST", ...args),
  get: (...args) => requestForm("GET", ...args),
  put: (...args) => requestForm("PUT", ...args),
  delete: (...args) => requestForm("DELETE", ...args),
  patch: (...args) => requestForm("PATCH", ...args),
};

export const REQUEST_TYPE = {
  post: "POST",
  get: "GET",
  put: "PUT",
  patch: "PATCH",
  delete: "DELETE",
};
