import React, { createContext, useState } from "react";

export const InteriorContext = createContext();

function InteriorContextProvider(props) {
  const { children, setIsDrawerOpen, value } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState(null);

  const updatedValue = {
    drawerOpen,
    openDrawer: function () {
      setDrawerOpen(true);
      setIsDrawerOpen(true);
    },
    closeDrawer: function () {
      setDrawerOpen(false);
      setIsDrawerOpen(false);
    },
    drawerComponent,
    setDrawerComponent,
    ...value,
  };

  return (
    <InteriorContext.Provider value={updatedValue}>
      {children}
    </InteriorContext.Provider>
  );
}

export default InteriorContextProvider;
