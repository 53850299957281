import React, {  useEffect, useState } from "react";
import "./MsgSlideWindow.scss";
import { MsgSlideChat } from "../../molecules";
import {
  Input,
  InputVariants
} from "../../atoms";
import {  useFetch } from "../../../utils/hooks";
import { API_ENDPOINTS } from "../../../utils/constants";

function MsgSlideWindow(props) {
  const { show, msgName, msgProfile, msg, recipientID, part, ...rest } = props;
  const [text, textState] = useState(0);
  const { get, post, loading, response } = useFetch();
  const [msgs, setMsgs] = useState({});
  const [flip, setState] = useState(0);

  function updateTextState(props){
    textState(props);
  }
  function updateState(props){
    setState(props);
  }

  useEffect(() => {
    getUserConvorrr();
  }, []);

  const handleOnChangeText = (e) => {
    if (e.key === 'Enter'){
      const sortedDigits = e.target.value
       updateUserConvo(sortedDigits)
       updateTextState()
        e.target.value = "";
    }
  }
  //console.log("msg convo:   ",  msg.id);
  const getUserConvorrr = () => {
    get(API_ENDPOINTS.chatGet(msg.id))
      .then((response) => {
        if (response.success === true) {
          //console.log("response convo:   ", response?.data);
          setMsgs(response.data);
          updateState(0);
        }
      })
      .catch((error) => console.log(error));
  };

  const updateUserConvo = (txt) => {
    post(API_ENDPOINTS.chatMsgCreate(msg.id), {
      recipientID: recipientID,
      message: txt
    }).then((response) => {
        if (response.success === true){ 
          //console.log("response:   ", response?.data);
          getUserConvorrr();
        }
      })
      .catch((error) => console.log(error));
  };

  const MsgConvoMsgs = () => {
    return (
      <MsgSlideChat flip={flip} setState={setState} msgs={msgs} setMsgs={setMsgs} getUserConvorrr={getUserConvorrr} participants={msg?.participants?.[1]} conversationID={msg.conversationID} text={text} textState={textState} recipientID={recipientID} updateTextState={updateTextState}/>
    )
  }

  if(part === msg.sender){
    return (
      <>
        { show ?
          <div
            className="msg-slide__window">
            <div className="msg-slide__window-msgs " >
              {MsgConvoMsgs()}
            </div>
            { <textarea 
              className="msg-slide__window-textbar"
              variant={InputVariants.text}
              onKeyDown={(e) => handleOnChangeText(e)}
            /> }
          </div>
        : null }
      </>
    );
  }

}

export default MsgSlideWindow;

MsgSlideWindow.propTypes = {
  /**
   * The information that will be used to display
   */
  //node: PropTypes.object.isRequired,
};