import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Loading } from "../../../atoms";
import { LoaderSize } from "../../../atoms/loading/Loading";
import { NavMenu } from "../../../molecules";
import Logo from "../../../../assets/icons/logo.svg";
import { useHistory } from "react-router";
import { ROUTES } from "../../../../utils/constants";

const NAV_MENU_STATE = {
  closed: false,
  open: true,
};

function SideNavMobile() {
  const [navOpen, setNavOpen] = useState(NAV_MENU_STATE.closed);
  const history = useHistory();
  const userDetails = useSelector((state) => state.user);

  const composeUsername = () => {
    if (userDetails.data) {
      const { firstName, lastName } = userDetails.data;
      return `${firstName} ${lastName}`;
    }
    return <Loading size={LoaderSize.small} />;
  };

  const handleNavOpenToggle = () => setNavOpen(!navOpen);

  const onMenuItemClicked = () => handleNavOpenToggle();

  const handleLogoClicked = () => history.push(ROUTES.sites);

  const renderNavMenu = () => {
    if (navOpen === NAV_MENU_STATE.closed) return null;
    return (
      <>
        <div
          className="side-bar-mobile__organism-nav-menu-wrapper"
          onClick={handleNavOpenToggle}
        />
        <NavMenu
          onMenuItemClicked={onMenuItemClicked}
          className="side-bar-mobile__organism-nav-menu"
        />
      </>
    );
  };

  return (
    <>
      <nav className="side-bar-mobile__organism">
        <img alt="Inlo" src={Logo} onClick={handleLogoClicked} />
        <div>
          <p className="side-bar-mobile__organism-username">
            {composeUsername()}
          </p>
          <FontAwesomeIcon
            icon={faBars}
            className="icon"
            onClick={handleNavOpenToggle}
          />
        </div>
      </nav>
      {renderNavMenu()}
    </>
  );
}

export default SideNavMobile;
