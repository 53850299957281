import {useEffect, useRef, useState} from "react";

export const useEvents = () => {
    const ws = useRef(null);
    const [readyState, setReadyState] = useState(null);
    const [isPaused, setPause] = useState(false);
    // const headers: {
    //     "access_token" : `${localStorage.getItem("token")}`
    //   }
    // headers: {
    //     "Content-Type": "multipart/form-data",
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    const headers = {
        access_token: `${localStorage.getItem("token")}`
      }
    //Authorization: `Bearer ${localStorage.getItem("token")}`,
    //["access_token :", localStorage.getItem("token")]
    //"access_token":"mF_9.B5f-4.1JqM",

    //new WebSocket("ws://www.example.com/socketserver", ["access_token", "3gn11Ft0Me8lkqqW2/5uFQ="]);
    useEffect(() => {
        console.log("out :  ",  ws.current = new WebSocket("wss://api-staging.theinlo.com/v3/event", [headers.stringify] ));
        ws.current.onopen = () => console.log("ws opened");
        ws.current.onclose = () => console.log("ws closed");
        ws.current.onMessage = (data) => console.log(`Recieved Info ${JSON.stringify(data)}`);
        const wsCurrent = ws.current;

        return () => {
            wsCurrent.close();
        };
    }, []);

    useEffect(() => {
        if (!ws.current) return;
    
        ws.current.onmessage = e => {
            if (isPaused) return;
            const message = JSON.parse(e.data);
            console.log("e", message);
        };
    }, [isPaused]);

    // useEffect(() => {
    //     setReadyState(ws.current.readyState);
    // },[ws.current.readyState])

return
    // return {onMessage, onConnection, sendMessage, onConnect}
}
    