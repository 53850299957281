import React from "react";
import Joi from "@hapi/joi";
import { Button, ButtonVariants } from "../../../atoms";
import { joiResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { makeRequest, NOTIFICATION_MESSAGES, notify } from "../../../../utils";
import { API_ENDPOINTS } from "../../../../utils/constants";
import { useState } from "react";
import { userDetails } from "../../../../redux/user/actions";
import { useDispatch } from "react-redux";

const schema = Joi.object({
  profilePic: Joi.any().allow("").optional(),
});
function EditProfilePic(props) {
  const { exitEditMode } = props;
  const [state, setstate] = useState({ selectedFile: null });
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });

  const updateProfilePic = async (e) => {
    e.preventDefault();
    //("fileee: ", e.target.files);
    setstate({ selectedFile: e.target.files });
    fetch(API_ENDPOINTS.uploadProfilePicture, {
      method: "POST",
      mode: "cors",
      crossDomain: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: e.target.files,
    })
      .then((res) => {
        if (res) {
          dispatch(userDetails());
          if (res.status === "success")
            notify.success("Profile pic updated successfully");
          if (res.status === "error") notify.error(res.error.message);
        }
        return res.json();
      })
      .then(console.log)
      .catch(console.error);
  };

  
  return (
    <>
      <form>
        <input
          type="file"
          id="myFile"
          label="Profile pic"
          name="profilePic"
          //onChange={updateProfilePic}
          ref={register}
        />
        {/* <Input
          variant={InputVariants.text}
          type="file"
          label="Profile pic"
          name="profilePic"
          // error={errors.firstName?.message}
          ref={register}
        /> */}
        <Button
          onClick={updateProfilePic}
          variant={ButtonVariants.success}
          type="submit"
        >
          Upload
        </Button>
        <Button
          variant={ButtonVariants.dangerOutlined}
          type="button"
          onClick={exitEditMode}
        >
          Cancel
        </Button>
      </form>
    </>
  );
}

export default EditProfilePic;
