import Joi from "@hapi/joi";
import { joiResolver } from "@hookform/resolvers";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { ROUTES } from "../../../../../utils/constants";
import {
  Button,
  ButtonVariants,
  Input,
  InputVariants,
  Map,
  MapVariants,
  TextAtom,
  TextVariants,
} from "../../../../atoms";
import { InteriorPageHeadingWithCTA } from "../../../../molecules";
import "./CreateSites.scss";

const schema = Joi.object({
  name: Joi.string().required(),
  longitude: Joi.number().required(),
  latitude: Joi.number().required(),
  address: Joi.string().required(),
  city: Joi.string().required(),
  country: Joi.string().required(),
  zipcode: Joi.number().required(),
});

function CreateSiteComponent(props) {
  const { handleOnSubmit } = props;
  const history = useHistory();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const handleAllSitesClicked = () => {
    history.push(ROUTES.sites);
  };

  const { register, handleSubmit, errors } = useForm({
    resolver: joiResolver(schema),
  });

  const onSubmit = (data) => {
    handleOnSubmit(data);
  };

  const onLocationClick = (location) => {
    //console.log(location);
    setLatitude(location[0]);
    setLongitude(location[1]);
  };

  return (
    <div className="create-site__page">
      <InteriorPageHeadingWithCTA heading="Create a Site">
        <Button
          variant={ButtonVariants.primary}
          onClick={handleAllSitesClicked}
        >
          All Sites
        </Button>
      </InteriorPageHeadingWithCTA>
      <div className="container">
        <div className="site-entry-area">
          <div className="left-area">
            <form
              className="create-site__page-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input
                variant={InputVariants.text}
                label="Name"
                name="name"
                error={errors.name?.message}
                ref={register}
              />

              <Input
                variant={InputVariants.text}
                label="Address"
                name="address"
                error={errors.address?.message}
                ref={register}
              />
              <Input
                variant={InputVariants.text}
                label="City"
                name="city"
                error={errors.city?.message}
                ref={register}
              />
              <Input
                variant={InputVariants.text}
                label="Country"
                name="country"
                error={errors.country?.message}
                ref={register}
              />
              <Input
                variant={InputVariants.text}
                label="Zipcode"
                name="zipcode"
                error={errors.zipcode?.message}
                ref={register}
              />
              <Input
                variant={InputVariants.text}
                label="Latitude"
                name="latitude"
                error={errors.latitude?.message}
                ref={register}
                value={latitude}
              />
              <Input
                variant={InputVariants.text}
                label="Longitude"
                name="longitude"
                error={errors.longitude?.message}
                ref={register}
                value={longitude}
              />
              <Button variant={ButtonVariants.success} type="submit">
                Create Site
              </Button>
            </form>
          </div>
          <div className="right-area">
            <Map
              className="map"
              variant={MapVariants.withSearch}
              zoom={13}
              onLocationClick={onLocationClick}
            />
            <div className="user-instruction-mapwithsearch">
              <TextAtom variant={TextVariants.large}>
                Click on desired location on the Map to change Marker position
              </TextAtom>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSiteComponent;
