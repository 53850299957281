import React from "react";
import { ProfileCard, NavMenu } from "../../../molecules";
import Logo from "../../../../assets/images/icon.png";
import { useHistory } from "react-router";
import { ROUTES } from "../../../../utils/constants";

function SideNavDesktop() {
  const history = useHistory();
  const handleLogoClicked = () => history.push(ROUTES.profile);

  return (
    <nav className="side-bar-desktop__organism">
      <div className="logo">
        <img alt="Inlo" src={Logo} onClick={handleLogoClicked} />
      </div>
      
      <NavMenu className="side-bar-desktop__organism-nav-menu" />
    </nav>
  );
}

//<ProfileCard className="side-bar-desktop__organism-profile-card" />

export default SideNavDesktop;
