import React from "react";
import { TextAtom, TextColors, TextWeights } from "../../atoms";
import "./DropDownUserResults.scss";

function DropDownUserResults(props) {
  const { profilePicture, username, name, ...rest } = props;
  return (
    <div {...rest} className="dropdown-result">
      {profilePicture && (
        <div className="image-area">
          <img src={profilePicture} alt={username} />
        </div>
      )}
      <div className="text-area">
        <TextAtom weight={TextWeights.semiBold}>{name}</TextAtom>
        <TextAtom color={TextColors.primaryGrey}>@{username}</TextAtom>
      </div>
    </div>
  );
}

export default DropDownUserResults;
