// get site detials using site ID
// Will filter site details and return site.

/**
 * Get site details using siteID
 */
export const getSiteDetailsUsingSiteId = (allSites, siteId) => {
  const siteData = allSites.data?.sites;
  const siteDetail = siteData.find((site) => site.id === siteId);
  return siteDetail;
};

export const composeLocationData = (siteDetail) => {
  const { name, long, lat } = siteDetail;
  return {
    name,
    longLat: [lat, long],
  };
};
