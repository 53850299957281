import React from "react";
import {
  Button,
  ButtonVariants,
  TextAtom,
  TextVariants,
} from "../../../../atoms";
import ProfileIcon from "../../../../../assets/images/default-profile-icon.png";
import {
  composeDateUsingTimestamp,
  DATETIME_FORMAT,
} from "../../../../../utils";
import "./NotificationBarWithResendAndCancel.scss";

function NotificationBarWithResendAndCancelComponent(props) {
  const {
    notificationData,
    handleCancelInvite,
    handleResendInvite,
    handleDeleteNotification,
  } = props;
  //console.log(notificationData);

  const constructNotification = () => {
    return (
      <div className="bullet_container">
        <img className="profile_image" src={ProfileIcon} alt="userImage" />
        <div className="bullet-text_container">
          <div className="bullet_text">
            <TextAtom variant={TextVariants.large}>
              {notificationData.message} &nbsp; at &nbsp;{" "}
            </TextAtom>
            <TextAtom
              variant={TextVariants.regular}
              className="timestamp_bullet-text"
            >
              {composeDateUsingTimestamp(
                notificationData.createdAt,
                DATETIME_FORMAT.dateAndTime
              )}
            </TextAtom>
          </div>
          <div className="buttons-and-server-text">
            {/* <Text className="bullet-server-text">
              {notificationData.ops.text.content}
            </Text> */}
            <div className="action-buttons_container">
              <Button
                className="action-button"
                variant={ButtonVariants.primaryOutlined}
                onClick={handleResendInvite}
              >
                Resend
              </Button>
              <Button
                className="action-button"
                variant={ButtonVariants.dangerOutlined}
                onClick={handleCancelInvite}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <div className="bullet_container-delete-container">
          <div className="X-container" onClick={handleDeleteNotification}>
            <div className="bullet_container-delete_button-container">X</div>
          </div>
          <div className="tooltip">Click to delete notification</div>
        </div>
      </div>
    );
  };
  return constructNotification();
}

export default NotificationBarWithResendAndCancelComponent;
