import React from "react";
import ProfileIcon from "../../../assets/images/default-profile-icon.png";
import { TextAtom, TextColors, TextVariants, TextWeights } from "../../atoms";
import "./UserCard.scss";
import classnames from "classnames";

export const USER_VARIANTS = {
  self: "Owner",
  pending: "Pending",
  accepted: "Accepted",
};

function UserCard(props) {
  const { email, variant, name, avtar, ...rest } = props;

  const classes = classnames("user_cards", `user-cards__${variant}`);

  const composeStatusText = () => {
    switch (variant) {
      case USER_VARIANTS.self:
        return "Owner";
      case USER_VARIANTS.pending:
        return "Invited";
      default:
        return;
    }
  };

  return (
    <div className={classes} {...rest}>
      <img
        src={avtar}
        alt={name}
        className="user-card__molecule-profile-image"
      />
      <div className="user-card__molecule-text">
        <TextAtom
          className="user-card__molecule-element"
          variant={TextVariants.regular}
          weight={TextWeights.bold}
          color={TextColors.primaryGrey}
        >
          {name}
        </TextAtom>
        <TextAtom
          className="user-card__molecule-element"
          variant={TextVariants.small}
          weight={TextWeights.semiBold}
          color={TextColors.primaryGrey}
        >
          {email}
        </TextAtom>
        <TextAtom variant={TextVariants.small} weight={TextWeights.bold}>
          {composeStatusText()}
        </TextAtom>
      </div>
    </div>
  );
}

export default UserCard;

UserCard.defaultProps = {
  avtar: ProfileIcon,
};
