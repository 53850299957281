import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "./components/atoms";
import {
  SignIn,
  SignUp,
  Devices,
  ForgotPassword,
  Notifications,
  Sites,
  Users,
  Account,
  Profile,
  ResetPassword,
  MapWithCurrentLocation,
  MyGroup,
} from "./components/pages/";
import { ROUTES } from "./utils/constants";

function Routes() {
  return (
    <Router>
      <Switch>
        <Route path={ROUTES.signIn}>
          <SignIn />
        </Route>
        <Route path={ROUTES.signUp}>
          <SignUp />
        </Route>
        <Route path={ROUTES.forgotPassword}>
          <ForgotPassword />
        </Route>
        <Route path={ROUTES.resetPassword}>
          <ResetPassword />
        </Route>
        <ProtectedRoute path={ROUTES.notifications}>
          <Notifications />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.sites}>
          <Sites />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.users}>
          <Users />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.account}>
          <Account />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.mapWithCurrentLocation}>
          <MapWithCurrentLocation />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.profile}>
          <Profile />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.devices}>
          <Devices />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.myGroup}>
          <MyGroup />
        </ProtectedRoute>
        <Route path="/">
        <SignIn />
        </Route>
      </Switch>
    </Router>
  );
}

export default Routes;
