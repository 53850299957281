import { Slide, toast } from "react-toastify";

export const notify = {
  success: toast.success,
  info: toast.info,
  warn: toast.warn,
  error: toast.error,
};

export const NOTIFICATION_MESSAGES = {
  internalServerError: "Oops something went wrong. We're working on it.",
  emailUpdateRequestSent: "Please log into your email account to verify.",
  nameUpdate: "Name has been successfully updated.",
  defaultError: "Something is not right. Please try again!!",
  successDeleteSite: "Site deleted successfully.",
  successCreateSite: "Site created successfully.",
  failedSignIn: "Incorrect email or password. Please try again.",
  passwordUpdateRequestSent:
    "Email sent, Please log into your email account to reset your password",
  profilePictureUpdate: "Avatar has been successfully updated.",
};

export const NOTIFICATION_CONFIG = {
  autoClose: 2000,
  hideProgressBar: true,
  transition: Slide,
};
