import React from "react";
import UserSummaryComponent from "./component";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

function UserSummary(props) {
  const { userDetail} = props;
  const { userId } = useParams();

  const sites = useSelector((state) => state.sites);
  const user = useSelector((state) => state.user);
  const members = useSelector((state) => state.members);

  const siteData = sites.data?.sites;

  console.log("siteData: ", props, "  userId: ", userId)
  const siteDetail = userDetail //siteData?.find((site) => site.id === userId);

  
  const memberData = members.data?.results;
  const memberDetail = memberData?.find((member) => member.user.id === userId);

  console.log("UserSummary user:  ", userDetail)

  if(memberDetail === undefined){
    return <UserSummaryComponent user={userDetail}
                                members={members} 
                                memberDetail={userDetail} 
                                siteDetail={siteDetail} 
                                sites={sites} />;
   } else {
    return <UserSummaryComponent permissions={members.permissions}
                                members={members} 
                                memberDetail={memberDetail} 
                                siteDetail={siteDetail} 
                                sites={sites} />;
   }
}

export default UserSummary;
