import React from "react";
import SiteSummaryComponent from "./component";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

function SiteSummary() {
  const { siteId } = useParams();

  const sites = useSelector((state) => state.sites);

  const siteData = sites.data?.sites;

  console.log("siteData: ", siteData, "  siteID: ", siteId)
  const siteDetail = siteData?.find((site) => site.id === siteId);

  return <SiteSummaryComponent siteDetail={siteDetail} sites={sites} />;
}

export default SiteSummary;
