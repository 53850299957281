import React from "react";
import { TextAtom, TextVariants } from "../../atoms";
import "./UserSummaryCard.scss";
import PropTypes from "prop-types";

function UserSummaryCard(props) {
  const { userDetail, ...rest } = props;

  return (
    <div className="summary-card" {...rest}>
      <TextAtom className="summary-element" variant={TextVariants.large}>
        {userDetail.firstname}
      </TextAtom>
      <TextAtom className="summary-element" variant={TextVariants.regular}>
        {userDetail.username}
      </TextAtom>
      {/* <TextAtom className="summary-element" variant={TextVariants.regular}>
        {siteDetail.city}
      </TextAtom>
      <TextAtom className="summary-element" variant={TextVariants.regular}>
        address: {siteDetail.address}
      </TextAtom> */}
    </div>
  );
}

export default UserSummaryCard;

UserSummaryCard.propTypes = {
  /**
   * The information that will be used to display the site
   */
  userDetail: PropTypes.object.isRequired,
};
