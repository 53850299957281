import React, {  useEffect, useState } from "react";
import "./MsgSlideChat.scss";
import House from "../../../assets/images/default-profile-icon.png";
import {  MsgSlideMsgs } from "..";
import {
  Button,
  ButtonVariants,
  Input,
  InputVariants,
  TextAtom,
  TextSizes,
} from "../../atoms";
import { API_ENDPOINTS } from "../../../utils/constants";
import { useDebouncer, useFetch } from "../../../utils/hooks";

function MsgSlideChat(props) {
  const { flip, setState, msgs, getUserConvorrr, updateTextState, textState, text, participants, conversationID, recipientID, ...rest } = props;
  const { get, post, loading, response } = useFetch();

  function updateState(props){
    setState(props);
  }

  useEffect(() => {
    if(text !== 0){
      updateUserConvo();
      textState(0)
    }
  }, [text]);

  function updateState(props){
    setState(props);
  }

   const MsgConvoMsgs = () => {
    if (msgs?.length !== undefined && msgs?.length !== 0){
      if(flip === 0){
        updateState(1);
       // console.log("flip 1:  ", flip)
        return msgs?.reverse().map((msger) => (
           <MsgSlideMsgs msg={msger} part={participants} />
        ));
        } else {
        //  console.log("flip 2:  ", flip)
        return msgs?.map((msger) => (
          <MsgSlideMsgs msg={msger} part={participants} />
       ));
      }  
    }else return;

  };

  const updateUserConvo = () => {
    getUserConvorrr();
  };
  
  if (loading) {
    return <></>;
  }
 // console.log("show messages:  ", msgs)
    return (
      <>
        {MsgConvoMsgs()}
      </>
    );

}

export default MsgSlideChat;

MsgSlideChat.propTypes = {
  /**
   * The information that will be used to display
   */
  //node: PropTypes.object.isRequired,
};