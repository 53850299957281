import React from "react";
import classnames from "classnames";

import "./TextInput.scss";
import TextAtom, {
  TextColors,
  TextSizes,
  TextVariants,
} from "../../text/TextAtom";

const TextInput = React.forwardRef(function TextInput(props, ref) {
  const { className, label, error, ...rest } = props;
  const classes = classnames("text-input__atom", className);

  const renderLabel = () => {
    if (label)
      return (
        <TextAtom
          variant={TextVariants.regular}
          size={TextSizes.s}
          className="label"
        >
          {/* {label} */}
        </TextAtom>
      );
  };

  const renderError = () => {
    if (error)
      return (
        <TextAtom
          variant={TextVariants.regular}
          size={TextSizes.s}
          className="error"
          color={TextColors.primaryRed}
        >
          {error}
        </TextAtom>
      );
  };

  return (
    <div className={classes}>
      {renderLabel()}
      <input type="text" ref={ref} {...rest} placeholder={label}/>
      {renderError()}
    </div>
  );
});

export { TextInput };
