import { makeRequest, StateActions } from "../../utils";
import { ADD_NODES } from "./types";
import { API_ENDPOINTS } from "../../utils/constants";

export const getNodes = () => async (dispatch) => {
  const body = { claimed: true };
  const action = new StateActions(ADD_NODES);
  dispatch(action.loading);
  try {
    const response = await makeRequest.get(API_ENDPOINTS.nodes);
    if (response.status === "error") dispatch(action.error(response));
    if (response.status === "success"){ 
     // console.log("response", response)
      dispatch(action.loaded(response))
    };
  } catch (error) {
    console.error(error);
    dispatch(action.error(error));
  }
};

