import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getNotifications } from "../../../../../redux/notifications/actions";
import { notify } from "../../../../../utils";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import { useFetch } from "../../../../../utils/hooks";
import { Loading } from "../../../../atoms";
import NotificationsBarAcceptRejectComponent from "./component";

function NotificationsBarAcceptReject(props) {
  const { notificationData } = props;
  //const { siteID, accessGroupID, inviterMessageID, _id } = notificationData?.metadata?.userGroup[0];
  const { post, loading: acceptInvite_loading } = useFetch();
  const dispatch = useDispatch();
  const {
    del: deleteThisNotification,
    loading: deleteThisNotification_loading,
  } = useFetch();
  const { get: getMatchingUser, loading: getMatchingUser_loading } = useFetch();
  const [userData, setUserData] = useState();
  
  console.log("yoooo:    ",notificationData)

  // console.log("yoooo:    ",notificationData?.metadata?.userGroup[0])

  // useEffect(() => {
  //   getUserData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const handleAcceptInvite = async () => {
  //   post(API_ENDPOINTS.acceptInviteToSite, {
  //     siteID,
  //     accessGroupID,
  //     inviterMessageID,
  //   })
  //     .then((res) => {
  //       //console.log(res);
  //       if (res.status === "success") {
  //         notify.success("Access Group created successfully", {
  //           toastId: "AccessGroupCreationSuccess",
  //         });
  //         dispatch(getNotifications());
  //       } else if (res.status === "error") {
  //         notify.error(res.error.message);
  //         //console.log(res.error.message);
  //         dispatch(getNotifications());
  //       }
  //     })
  //     .catch((error) => notify.error(error.error.message));
  // };

  // const handleAcceptUserInvite = async () => {
  //   post(API_ENDPOINTS.acceptInviteToProfile(_id), {})
  //     .then((res) => {
  //       //console.log(res);
  //       if (res.success) {
  //         notify.success("Access Group created successfully", {
  //           toastId: "AccessGroupCreationSuccess",
  //         });
  //         dispatch(getNotifications());
  //       } else if (res.success) {
  //         notify.error(res.error.message);
  //         //console.log(res.error.message);
  //         dispatch(getNotifications());
  //       }
  //     })
  //     .catch((error) => notify.error(error.error.message));
  // };
  // if (acceptInvite_loading) {
  //   return <Loading />;
  // }

  // const handleDeleteNotification = async () => {
  //   deleteThisNotification(
  //     API_ENDPOINTS.notification(notificationData.messageID)
  //   )
  //     .then((res) => {
  //       //console.log(res);
  //       if (res.success) {
  //         notify.success("Notification deleted", {
  //           toastId: "NotificationDeleted",
  //         });
  //         dispatch(getNotifications());
  //       } else if (!res.success) {
  //         notify.error(res.error.message);
  //         //console.log(res.error.message);
  //         dispatch(getNotifications());
  //       }
  //     })
  //     .catch((error) => notify.error(error.error.message));
  // };
  // if (deleteThisNotification_loading) {
  //   return <Loading />;
  // }

  // const getUserData = () => {
  //   const extractUserEmailfromMessage = notificationData?.message.split(" ")[0];
  //   //console.log(extractUserEmailfromMessage);
  //   if (extractUserEmailfromMessage !== "You") {
  //     getMatchingUser(API_ENDPOINTS.searchUser(extractUserEmailfromMessage))
  //       .then((res) => {
  //         if (res.success) setUserData(res.data[0]);
  //         else if (!res.success) return null;
  //       })
  //       .catch(console.error);
  //   } else setUserData(undefined);
  // };
  // if (getMatchingUser_loading) {
  //   return <Loading />;
  // }

  // if(_id !== null){
  //   return (
  //     <NotificationsBarAcceptRejectComponent
  //       notificationData={notificationData}
  //       handleAcceptInvite={handleAcceptUserInvite}
  //       handleDeleteNotification={handleDeleteNotification}
  //       userData={userData}
  //     />
  //   );
  // }else if(siteID !== null){
  //   return (
  //     <NotificationsBarAcceptRejectComponent
  //       notificationData={notificationData}
  //       handleAcceptInvite={handleAcceptInvite}
  //       handleDeleteNotification={handleDeleteNotification}
  //       userData={userData}
  //     />
  //   );
  // }
  return(
    <></>
  )
}

export default NotificationsBarAcceptReject;
