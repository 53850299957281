import React, { useState } from "react";
import { Layer, Rect, Stage, Text, Image } from "react-konva";
import uuid from "uuid/v1";
import {
  Button,
  TextAtom,
  TextVariants,
  ButtonVariants,
} from "../../../atoms";
//import { isDeleteFloorPlanAllowed, isEditFloorPlanAllowed } from "../../utils";
import "./DisplaySiteFloorPlan.scss";
//import { InteriorPageHeadingWithCTA } from "../../../../../../molecules";
//import "./ObjectSlide.scss";
//import "../../../../../../molecules/object-slide/ObjectSlide"
import Object from "../../../../assets/images/object-icon.svg";
import useImage from 'use-image';


const WIDTH = 50;
const HEIGHT = 50;

const grid = [
  ["white", "white"],
  ["white", "white"],
];
function DisplaySiteFloorPlan(props) {
  const {
    allFloorPlansData,
    handleDeleteFloorPlan,
    // deleteFloorplan_loading,
    handleEditFloorplanButtonClicked,
    handleAddFloorplanButtonClicked,
    siteDetail,
    devices,
    flag
  } = props;

  const [roomDataForselectedFloor, setRoomDataForselectedFloor] = useState(
    allFloorPlansData[allFloorPlansData.length - 1].rooms
  );
 // console.log("selected room data", roomDataForselectedFloor);
 // console.log("Devices ", devices);
  const roomsCollectionSvg = roomDataForselectedFloor.map((room) => {
    return (
      <>
        <Rect
          key={room.roomID}
          x={room.x / 2}
          y={room.y / 2}
          width={room.width / 2}
          height={room.height / 2}
          stroke="#666"
          strokeWidth={8}
          fill="#f6f6f8"
        />
        <Text
          key={i}
          fill="red"
          x={(room.x /2) + (room.width /2) / 2 - 15}
          y={(room.y /2) + (room.height / 2) / 2}
          text={room.roomName === "" ? `room${i + 1}` : room.roomName}
        />
      </>
    );
  });

  const renderfloorPlanNames = () => {
    return (
      <div className="names-wrapper_sidepanel_display-site-floorplan">
        {allFloorPlansData.map((floor) => {
          return (
            <>
              <TextAtom
                onClick={() => setRoomDataForselectedFloor(floor.rooms)}
                className="names_sidepanel_display-site-floorplan"
              >
                {floor.floorName}
              </TextAtom>
              {/* {isDeleteFloorPlanAllowed(flag ? siteDetail?.permissions : false) ? (
                <Button
                  variant={ButtonVariants.dangerOutlined}
                  onClick={() => handleDeleteFloorPlan(floor.floorID)}
                  style={({ marginBottom: 7 }, { marginTop: 5 })}
                >
                  Delete
                </Button>
              ) : (
                ""
              )} */}

              {/* {isEditFloorPlanAllowed(flag ? siteDetail?.permissions : false) ? (
                <Button
                  variant={ButtonVariants.successOutlined}
                  onClick={() =>
                    handleEditFloorplanButtonClicked(floor.floorID, floor.rooms)
                  }
                  style={
                    ({ marginBottom: 7 }, { marginTop: 5 }, { marginLeft: 5 })
                  }
                >
                  Edit
                </Button>
              ) : (
                ""
              )} */}
            </>
          );
        })}
      </div>
    );
  };

  // Want to render image
const RenderObjects = () => {
  const [image] = useImage(Object)
  return <Image image={image} 
          key={666}
          x={204}
          y={102}
        />
}

  // <TextAtom
  //               onClick={() => setRoomDataForselectedFloor(floor.rooms)}
  //               className="names_sidepanel_display-floorplan"
  //             >
  //               {floor.floorName}
  //             </TextAtom>

  // Code for background Grid **start
  const stagePos = { x: 0, y: 0 };
  const startX = Math.floor((-stagePos.x - window.innerWidth) / WIDTH) * WIDTH;
  const endX =
    Math.floor((-stagePos.x + window.innerWidth * 2) / WIDTH) * WIDTH;

  const startY =
    Math.floor((-stagePos.y - window.innerHeight) / HEIGHT) * HEIGHT;
  const endY =
    Math.floor((-stagePos.y + window.innerHeight * 2) / HEIGHT) * HEIGHT;

  const gridComponents = [];
  var i = 0;

  for (var x = startX; x < endX; x += WIDTH) {
    for (var y = startY; y < endY; y += HEIGHT) {
      if (i === 4) {
        i = 0;
      }
      const indexX = Math.abs(x / WIDTH) % grid.length;
      const indexY = Math.abs(y / HEIGHT) % grid[0].length;
      gridComponents.push(
        <Rect
          key={uuid()}
          x={x}
          y={y}
          width={WIDTH}
          height={HEIGHT}
          fill={grid[indexX][indexY]}
          stroke="#d3d3d3"
        />
      );
    }
  }
  // Code for background Grid **end

  // Code for ZoomIn/ZoomOut **start
  const [stage, setStage] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStage({
      scale: newScale,
      x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
    });
  };
  // Code for ZoomIn/ZoomOut **start

  return (
    <>
    <div className="container_display-site-floorplan">
      <div className="sidepanel_display-site-floorplan">
        {/* <TextAtom variant={TextVariants.large}>     FLOORS </TextAtom> */}
        {renderfloorPlanNames()}
        {/* {isEditFloorPlanAllowed(flag ? siteDetail?.permissions : false) ? (
          <Button
            variant={ButtonVariants.primary}
            style={({ width: 100 }, { marginTop: 20 })}
            onClick={() => handleAddFloorplanButtonClicked()}
          >
            Add Floor
          </Button>
        ) : (
          ""
        )} */}
      </div>
      <div className="display-area_display-site-floorplan">
        <Stage
          width={window.innerWidth * 1}
          height={window.innerHeight * 1}
          style={{
            borderRadius: '20px',
            border: '2px solid lightgrey',
            overflow: 'hidden',
            width:  '100%',
            height:   '100%',
            position:   'absolute'
          }}
          onWheel={(event) => handleWheel(event)}
          scaleX={stage.scale}
          scaleY={stage.scale}
          x={stage.x}
          y={stage.y}
        >
          <Layer>
            {gridComponents}
          </Layer>
          <Layer draggable={true}>
              {roomsCollectionSvg}
              {/* {RenderObjects()} */}
            </Layer>

        </Stage>
      </div>
    </div>
    </>
  );
}

export default DisplaySiteFloorPlan;