import { INITIAL_STORE_STATE } from "../../utils";
import { ADD_NODES } from "./types";

export const nodesReducer = (state = INITIAL_STORE_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NODES: {
      return { ...payload };
    }
    default:
      return state;
  }
};
