import React from "react";
import { Error, Loading, Page, TextAtom } from "../../atoms";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { ROUTES } from "../../../utils/constants"; //../../../../../utils/constants
import ProfileIcon from "../../../assets/images/default-profile-icon.png";
import { InteriorPageHeadingWithMenu } from "../../molecules";
import { ProfileHeaderSection, ProfileRelatedDisplay } from "../../organisms";
import { Interior } from "../../templates";
import { SubMenu } from "../../organisms";
import ManageUser from "../profile/user-management/container";
import AccountComponent from "../profile/account/container";
import ProfileDetails from "../profile/profile-details/container";
import { REQ_STATE } from "../../../utils";
import "./ProfileComponent.scss";

function ProfileComponent(props) {
  const { msgProfile, msgConvo, sites, user } = props;
  const { path } = useRouteMatch();
  const history = useHistory();
  const childRoutesMenu = [{ name: "Home", path: ROUTES.feed }];

  // const handleUserData = () => {
  //   // eslint-disable-next-line default-case
  //   switch (user.state) {
  //     case REQ_STATE.initialized:
  //     case REQ_STATE.loading:
  //       return <Loading />;
  //     case REQ_STATE.error:
  //       return <Error />;
  //     case REQ_STATE.loaded:
  //       return <ProfileHeaderSection user={user} />;
  //   }
  // };

  const updateChildRoutesMenuAccordingToPermissions = () => {
    // if (isAccessManagementAllowed(siteDetail?.permissions))
    childRoutesMenu.push({
      name: "People",
      // path: ROUTES.userAccess,
      path: ROUTES.feed,
    });
    childRoutesMenu.push({
      name: "Sites",
      // path: ROUTES.userAccess,
      path: ROUTES.feed,
    });
    childRoutesMenu.push({
      name: "Devices",
      // path: ROUTES.userAccess,
      path: ROUTES.feed,
    });
    childRoutesMenu.push({
      name: "Account",
      path: ROUTES.userAccount,
    });

    return childRoutesMenu;
  };

  const renderChildRoutes = () => (
    <Switch>
      <Route exact path={path}>
        <ProfileDetails />
      </Route>
      <Route path={`${path}/profile-details`}>
        <ProfileDetails />
      </Route>
      <Route path={`${path}/user-management`}>
        <ManageUser />
      </Route>
      <Route path={`${path}/account`}>
        <AccountComponent />
      </Route>
    </Switch>
  );

  return (
    <Page title="Profile" description="User Profile">
      <Interior msgConvo={msgConvo} msgProfile={msgProfile} >
        <InteriorPageHeadingWithMenu heading="">
          {
            <SubMenu
              className="container"
              routeDetails={updateChildRoutesMenuAccordingToPermissions()}
            />
          }
        </InteriorPageHeadingWithMenu>
        {/* <div className="profile-components">
            {handleUserData()}
        </div> */}
        {renderChildRoutes()}
      </Interior>
    </Page>
  );
}

export default ProfileComponent;
