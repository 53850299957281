import React from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { ROUTES } from "../../../../../utils/constants";
import { Button, ButtonVariants } from "../../../../atoms";
import { InteriorPageHeadingWithCTA, InteriorPageHeadingWithMenu } from "../../../../molecules";
import { SubMenu } from "../../../../organisms";
import EditDevice from "./edit/container";
import DeviceSummary from "./summary/container";

function DeviceDetailComponent(props) {
  const { siteId, nodeId, deviceDetail } = props;
  const { path } = useRouteMatch();
  const history = useHistory();
  const childRoutesMenu = [
    { name: "Edit", path: ROUTES.editNode(nodeId) },
  ];

  const handleAllDeviceClicked = () => {
    history.push(ROUTES.devices);
  };


  const renderChildRoutes = () => (
    <Switch>
      <Route exact path={path}>
        <DeviceSummary />
      </Route>
      <Route path={`${path}/edit`}>
        <EditDevice siteId={siteId} nodeId={nodeId} />
      </Route>
      <Route path={`${path}/:siteId`}></Route>
    </Switch>
  );

  return (
    <div>
      <InteriorPageHeadingWithMenu heading="">
          { <SubMenu
          className="container"
          routeDetails={childRoutesMenu}/> }
          <Button
          variant={ButtonVariants.primaryRight}
          withIcon
          onClick={handleAllDeviceClicked}
        >
          All Devices
        </Button>
        </InteriorPageHeadingWithMenu>
      {renderChildRoutes()}
    </div>
  );
}

export default DeviceDetailComponent;
