import React from "react";
import "./MemberCard.scss";
import classnames from "classnames";
import {
  Input,
  InputVariants,
  TextAtom,
  TextSizes,
  TextVariants,
  TextWeights,
} from "../../atoms";
import { useFetch } from "../../../utils/hooks";
import { API_ENDPOINTS } from "../../../utils/constants";
import { notify } from "../../../utils";
import { getMembers } from "../../../redux/myGroup/actions";
import { useDispatch } from "react-redux";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MemberCard(props) {
  const { member, handleSelectMember, className, ...rest } = props;
  const { user, permissions, id: memberId, status } = member;
  const classes = classnames(
    `member-card__molecule`,
    status === "INACTIVE" ? "inactive-member" : "",
    className
  );

  const dispatch = useDispatch();

  const { put: updateUserPermissions, del: deleteMember } = useFetch();

  const handleDeleteMember = () => {
    deleteMember(API_ENDPOINTS.updatebyMemberID(memberId))
      .then((res) => {
        if (res.success === true) {
          notify.success("Member Deleted successfully", {
            toastId: "PermissionUpdateSuccess",
          });
          dispatch(getMembers());
        }
        if (res.success === false) {
          notify.error(res.message);
        }
      })
      .catch((error) => {
        notify.error(error.message);
      });
  };

  const handleChangePermissions = (permission, value) => {
    updateUserPermissions(API_ENDPOINTS.updatebyMemberID(memberId), {
      permissions: {
        [permission]: value,
      },
    })
      .then((res) => {
        if (res.success === true) {
          notify.success("Permission updated successfully", {
            toastId: "PermissionUpdateSuccess",
          });
          dispatch(getMembers());
        }
        if (res.success === false) {
          notify.error(res.message);
        }
      })
      .catch((error) => {
        notify.error(error.message);
      });
  };

  const renderPermissions = () => {
    return ["devices", "message", "location"].map((permission) => {
      return (
        <Input
          key={permission}
          variant={InputVariants.checkBox}
          label={permission}
          checked={permissions[permission]}
          onChange={() =>
            handleChangePermissions(permission, !permissions[permission])
          }
        />
      );
    });
  };

  
  const handleSelect = () => {
    // setSelectedUser(member);

    handleSelectMember(member)

    // viewMember(member);
  };



  return (
    <>
      <div className={classes} 
            {...rest}>
        <img
          className="member-profile-picture"
          src={user.profilePicture}
          alt={`${user.firstName}'s image`}
          onClick={handleSelect} 
        />
        {/* <FontAwesomeIcon
          icon={faTimes}
          className="icon"
          onClick={handleDeleteMember}
        /> */}

        <TextAtom
          variant={TextVariants.regular}
          weight={TextWeights.semiBold}
          size={TextSizes.l}
        >
          {user.firstName} {user.lastName}
        </TextAtom>
        <TextAtom variant={TextVariants.regular} size={TextSizes.m}>
          {status}
        </TextAtom>
        <div className="permissions-list">{renderPermissions()}</div>
      </div>
    </>
  );
}

export default MemberCard;
