import { STATUS_CODE } from "./statusCodes.js";

const request = async (method, url, body, options) => {
  const token = localStorage.getItem("token");

  const reqOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    crossDomain: true,
    method,
    ...options,
  };

  if (body) reqOptions.body = JSON.stringify(body);
  if (token) reqOptions.headers.Authorization = `Bearer ${token}`;

  try {
    const response = await fetch(url, reqOptions);
    const data = await response.json();

    // if you notice a 401. check for refresh token and make a call. 

    
    if(data.code === STATUS_CODE.unauthorized){
      //console.log("rest data output code  :", data.code )
    }

    return data;
  } catch (err) {

    //console.log("rest error output  :", err.code )
    throw new Error(err);
  }
};

export const makeRequest = {
  post: (...args) => request("POST", ...args),
  get: (...args) => request("GET", ...args),
  put: (...args) => request("PUT", ...args),
  delete: (...args) => request("DELETE", ...args),
  patch: (...args) => request("PATCH", ...args),
};

export const REQUEST_TYPE = {
  post: "POST",
  get: "GET",
  put: "PUT",
  patch: "PATCH",
  delete: "DELETE",
};
