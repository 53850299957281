import React from "react";
import { useSelector } from "react-redux";
import { UserCard, USER_VARIANTS } from "../../../../molecules";

function UserCards(props) {
  const { users, handleUserCardClicked } = props;
  const self = useSelector((state) => state.user);

  const detectVariant = (inviteeEmail, invitationStatus) => {
    if (!inviteeEmail) return USER_VARIANTS.self;
    if (invitationStatus === USER_VARIANTS.pending)
      return USER_VARIANTS.pending;

    return USER_VARIANTS.accepted;
  };

  const getAvtar = (avtar, inviteeEmail, invitationStatus) => {
    if (
      detectVariant(inviteeEmail, invitationStatus) === USER_VARIANTS.self &&
      self.data &&
      self.data.avtar
    )
      return self.data.avtar;
    if (avtar) return avtar;
  };

  const getName = (name, inviteeEmail, invitationStatus) => {
    if (name) return name;
    if (
      detectVariant(inviteeEmail, invitationStatus) === USER_VARIANTS.self &&
      self.data &&
      self.data.firstName
    )
      return `${self.data.firstName} ${self.data.lastName}`;
    return "";
  };

  const getEmail = (inviteeEmail) => {
    if (inviteeEmail) return inviteeEmail;
    if (self.data && self.data.email) return self.data.email;
    return "";
  };

  const renderUserCards = () => {

    //console.log("users:   ", users)
    return users.map((user) => {
      const { inviteeEmail, invitationStatus, _id } = user;
      let name, avtar;

      return (
        <UserCard
          onClick={() => handleUserCardClicked(user)}
          key={_id}
          email={getEmail(inviteeEmail)}
          name={getName(name, inviteeEmail, invitationStatus)}
          avtar={getAvtar(avtar, inviteeEmail, invitationStatus)}
          variant={detectVariant(inviteeEmail, invitationStatus)}
        />
      );
    });
  };
  return renderUserCards();
}

export default UserCards;
