import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getMembers } from "../../../../../redux/myGroup/actions";
import { notify } from "../../../../../utils";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import { useDebouncer, useFetch } from "../../../../../utils/hooks";
import {
  Button,
  ButtonVariants,
  Input,
  InputVariants,
} from "../../../../atoms";
import { DropDownUserResults } from "../../../../molecules";
import Drawer from "../../../../templates/interior/components/Drawer";
import SelectedUser from "../selectedUser/SelectedUser";
import "./AddMember.scss";

function AddMember() {
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const [permissions, setPermissions] = useState({
    message: false,
    location: false,
    devices: false,
  });
  const { post } = useFetch();
  const { get: getMatchingUsers } = useFetch();
  const [matchingUsers, setMatchingUsers] = useState([]);
  const dispatch = useDispatch();

  const findMatchingUsernames = (usernameOrEmail) => {
    if (usernameOrEmail !== "") {
      getMatchingUsers(API_ENDPOINTS.searchUser(usernameOrEmail))
        .then((res) => {
          if (res.success === true) setMatchingUsers(res.data);
          if (res.success === false) setMatchingUsers([]);
        })
        .catch((error) => notify.error(error.message));
    }
  };

  useDebouncer(() => findMatchingUsernames(usernameOrEmail), 500, [
    usernameOrEmail,
  ]);

  const handleSelectUser = (user) => {
    setUsernameOrEmail("");
    setSelectedUser(user);
  };

  const renderMatchingUsers = () => {
    if (matchingUsers.length === 0) return;

    return matchingUsers.map((user) => (
      <DropDownUserResults
        key={user.username}
        user={user}
        profilePicture={user.profilePicture}
        username={user.username}
        name={`${user.firstName} ${user.lastName}`}
        onClick={() => handleSelectUser(user)}
      />
    ));
  };

  const handleSubmit = async () => {
    post(API_ENDPOINTS.inviteMember, {
      user: selectedUser.id,
      permissions,
    })
      .then((res) => {
        if (res.success === true) {
          notify.success("Member added successfully", {
            toastId: "MemberAddedSuccess",
          });
          dispatch(getMembers());
          setSelectedUser(null);
        }
        if (res.success === false) {
          notify.error(res.message);
        }
      })
      .catch((error) => {
        notify.error(error.message);
      });
  };

  const handleOnChange = function (e) {
    const { value } = e.target;
    setUsernameOrEmail(value);
  };

  const handlePermissionsChange = (e, permission) => {
    setPermissions({ ...permissions, [permission]: e.target.checked });
  };

  const renderPermissions = () => {
    return (
      <div className="show-permissions">
        <Input
          variant={InputVariants.checkBox}
          label={"Message"}
          onChange={(e) => handlePermissionsChange(e, "message")}
          checked={permissions["message"]}
        />
        <Input
          variant={InputVariants.checkBox}
          label={"Location"}
          onChange={(e) => handlePermissionsChange(e, "location")}
          checked={permissions["location"]}
        />
        <Input
          variant={InputVariants.checkBox}
          label={"Devices"}
          onChange={(e) => handlePermissionsChange(e, "devices")}
          checked={permissions["devices"]}
        />
      </div>
    );
  };

  return (
    <>
      <Drawer.Title heading="Invite a Member" />
      <Drawer.Description>
        Invite any member within the inlo universe
      </Drawer.Description>
      <div className="search-bar_AddMember">
        <div className="searchBarandResults_wrapper">
          <Input
            label="Username or Email"
            value={usernameOrEmail}
            onChange={(e) => handleOnChange(e)}
            variant={InputVariants.text}
          />
          {usernameOrEmail.trim() && (
            <div className="search-results-dropdown">
              {renderMatchingUsers()}
            </div>
          )}
        </div>
        {selectedUser && <SelectedUser {...selectedUser} />}
        {selectedUser && renderPermissions()}
        {selectedUser && (
          <Button variant={ButtonVariants.primary} onClick={handleSubmit}>
            Invite Member
          </Button>
        )}
      </div>
    </>
  );
}

export default AddMember;
