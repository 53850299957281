import React from "react";
import "./Loading.scss";
import classnames from "classnames";
import PropTypes from "prop-types";

export const LoaderSize = {
  small: "small",
  medium: "medium",
  large: "large",
};

function Loading(props) {
  const { size } = props;
  const classes = classnames(`lds-ring`, size);
  return (
    <span className="loading__atom ">
      <span className={classes}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </span>
    </span>
  );
}

export default Loading;

Loading.propTypes = {
  /**
   * Size of the loader
   */
  size: PropTypes.oneOf(Object.values(LoaderSize)),
};

Loading.defaultProps = {
  size: LoaderSize.medium,
};
