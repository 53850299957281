import React from "react";
import { Error, Loading, Page, TextAtom } from "../../../atoms";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { ROUTES } from "../../../../utils/constants"; //../../../../../utils/constants
import ProfileIcon from "../../../../assets/images/default-profile-icon.png";
import { InteriorPageHeadingWithCTA } from "../../../molecules";
import { ProfileHeaderSection, ProfileRelatedDisplay } from "../../../organisms";
import { Interior } from "../../../templates";
import { REQ_STATE } from "../../../../utils";
import "./ProfileDetailsComponent.scss";

function ProfileDetailsComponent(props) {
  const { sites, user } = props;

  const handleUserData = () => {
    // eslint-disable-next-line default-case
    switch (user.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return <ProfileHeaderSection user={user} />;
    }
  };
 
  return (
   <div className="profile-components">
        {handleUserData()}
    </div>
  );
}

export default ProfileDetailsComponent;
