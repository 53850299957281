import React from "react";
import TextAtom, { TextColors, TextVariants } from "../text/TextAtom";
import PropTypes from "prop-types";

function Error(props) {
  const { text } = props;
  return (
    <TextAtom variant={TextVariants.regular} color={TextColors.primaryRed}>
      {text}
    </TextAtom>
  );
}

export default Error;

Error.propTypes = {
  /**
   * Error message that will be displayed to the user
   */
  text: PropTypes.string,
};

Error.defaultProps = {
  text: "Oops! Looks like something has gone terribly wrong.",
};
