import React from "react";
import NotificationBarWithResendAndCancelComponent from "./component";
import { useDispatch } from "react-redux";
import { getNotifications } from "../../../../../redux/notifications/actions";
import { notify } from "../../../../../utils";
import { API_ENDPOINTS } from "../../../../../utils/constants";
import { useFetch } from "../../../../../utils/hooks";
import { Loading } from "../../../../atoms";

function NotificationBarWithResendAndCancel(props) {
  const { notificationData } = props;
  const { siteID, accessGroupID, inviteeEmail } = notificationData.metadata;
  const { post, loading: resendInvite_loading } = useFetch();
  const { del, loading: deleteInvite_loading } = useFetch();
  const {
    del: deleteThisNotification,
    loading: deleteThisNotification_loading,
  } = useFetch();
  const dispatch = useDispatch();

  const handleResendInvite = async () => {
    const siteId = siteID;
    const groupID = accessGroupID;
    post(API_ENDPOINTS.accessGroupUser(siteId, groupID), {
      inviteeEmailOrUsername: inviteeEmail,
    })
      .then((res) => {
        //console.log(res);
        if (res.status === "success") {
          notify.success("Invite resent successfully", {
            toastId: "InviteResentSuccess",
          });
          dispatch(getNotifications());
        } else if (res.status === "error") {
          notify.error(res.error.message);
          //console.log(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };
  if (resendInvite_loading) {
    return <Loading />;
  }
  const handleCancelInvite = async () => {
    const siteId = siteID;
    const groupID = accessGroupID;
    del(API_ENDPOINTS.accessGroupUser(siteId, groupID), {
      inviteeEmail,
    })
      .then((res) => {
        //console.log(res);
        if (res.status === "success") {
          notify.success("Invite deleted successfully", {
            toastId: "InviteDeleteSuccess",
          });
          dispatch(getNotifications());
        } else if (res.status === "error") {
          notify.error(res.error.message);
          //console.log(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };
  if (deleteThisNotification_loading) {
    return <Loading />;
  }

  const handleDeleteNotification = async () => {
    deleteThisNotification(
      API_ENDPOINTS.notification(notificationData.messageID)
    )
      .then((res) => {
        //console.log(res);
        if (res.status === "success") {
          notify.success("Notification deleted", {
            toastId: "NotificationDeleted",
          });
          dispatch(getNotifications());
        } else if (res.status === "error") {
          dispatch(getNotifications());
          notify.error(res.error.message);
          //console.log(res.error.message);
        }
      })
      .catch((error) => notify.error(error.error.message));
  };
  if (deleteInvite_loading) {
    return <Loading />;
  }

  return (
    <NotificationBarWithResendAndCancelComponent
      notificationData={notificationData}
      handleResendInvite={handleResendInvite}
      handleCancelInvite={handleCancelInvite}
      handleDeleteNotification={handleDeleteNotification}
    />
  );
}

export default NotificationBarWithResendAndCancel;
