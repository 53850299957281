import React from "react";
import ProfileIcon from "../../../assets/images/default-profile-icon.png";
import {
  Loading,
  TextAtom,
  TextSizes,
  TextVariants,
  TextWeights,
} from "../../atoms";
import classnames from "classnames";
import "./ProfileCard.scss";
import { useSelector } from "react-redux";
import { LoaderSize } from "../../atoms/loading/Loading";

function ProfileCard(props) {
  const { className, ...rest } = props;
  const classes = classnames(`profile-card__molecule-wrapper`, className);
  const userDetails = useSelector((state) => state.user);

  const composeUsername = () => {
    if (userDetails.data) {
      const { firstName, lastName } = userDetails.data;
      return `${firstName} ${lastName}`;
    }
    return <Loading size={LoaderSize.small} />;
  };

  const renderUserAvatar = () => {
    if (userDetails.data && userDetails.data.avtar) {
      return userDetails.data.avtar;
    }
    return ProfileIcon;
  };

  return (
    <div {...rest} className={classes}>
      <img
        src={renderUserAvatar()}
        alt="Profile Icon"
        className="profile-card__molecule-image"
      />
      <TextAtom
        variant={TextVariants.regular}
        className="profile-card__molecule-name"
        weight={TextWeights.semiBold}
        size={TextSizes.l}
      >
        {composeUsername()}
      </TextAtom>
    </div>
  );
}

export default ProfileCard;
