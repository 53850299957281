import React from "react";
import {
  composeDateUsingTimestamp,
  DATETIME_FORMAT,
  REQ_STATE,
} from "../../../../utils";
import { Error, Loading, Page } from "../../../atoms";
import { Detail, InteriorPageHeadingWithCTA } from "../../../molecules";
import { Interior } from "../../../templates";
import "./Account.scss";
import EditEmail from "./components/EditEmail";
import EditName from "./components/EditName";
import EditPassword from "./components/EditPassword";
import EditAvatar from "./components/EditAvatar"
 import EditProfilePic from "./components/EditProfilePic";

function AccountComponent(props) {
  const { user } = props;

  const handleAccountData = () => {
    // eslint-disable-next-line default-case
    switch (user.state) {
      case REQ_STATE.initialized:
      case REQ_STATE.loading:
        return <Loading />;
      case REQ_STATE.error:
        return <Error />;
      case REQ_STATE.loaded:
        return renderAccountDetails();
    }
  };

  const renderDetails = () => {
    const details = [];
    const {
      firstName,
      lastName,
      email,
      joined,
      lastSeen,
      isActive,
      username,
      titleText,
      titleEmoji,
      profilePicture,
    } = user;

    console.log("user: ", user)
    const composeStatus = isActive ? "Active" : "Inactive";
    const composeTitleText = titleText ? titleText : "Add your title text.";
    const composeTitleEmoji = titleEmoji ? titleEmoji : "Add your title text.";

    details.push({
      label: "Name",
      value: `${firstName} ${lastName}`,
      editComponent: EditName,
    });
    details.push({ label: "User Name", value: "@"+username });
    details.push({
      label: "Email",
      value: email,
      editComponent: EditEmail,
    });
    details.push({
      label: "Change password",
      value: "******",
      editComponent: EditPassword,
    });
    details.push({ label: "Status", value: composeStatus });
    details.push({
      label: "Joined",
      value: composeDateUsingTimestamp(joined),
    });
    // details.push({
    //   label: "Last Seen",
    //   value: composeDateUsingTimestamp(lastSeen, DATETIME_FORMAT.dateAndTime),
    // });
    // details.push({ label: "Title Text", value: composeTitleText });
    // details.push({ label: "Title Emoji", value: composeTitleEmoji });
    
    details.push({
      label: "img",
      value: (
        <img
          className="account_page-profile_pic"
          src={profilePicture}
          alt="user profile pic"
        />
      ),
      editComponent: EditAvatar,
       //editComponent: EditProfilePic,
    });

    return details.map((detail) => (
      <Detail
        key={detail.label}
        label={detail.label}
        value={detail.value}
        EditComponent={detail?.editComponent}
      />
    ));
  };

  const renderAccountDetails = () => {
    return (
      <div className="account__page container">
        <div className="account-details">{renderDetails()}</div>
      </div>
    );
  };
  return (
    <Page title="Account" description="Users Account">
        {handleAccountData()}
    </Page>
  );
}

export default AccountComponent;
